import React, { Component } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/';
import TableComponent from '../components/TableComponent';
import Header from '../components/Header';
import { classComponentStyles } from '../styles/styles';
import { calculateVacationTimeUsed } from '../functions/CalculateVacationTimeUsed';

const columns = [
  { id: 'id', label: 'ID', minWidth: 150 },
  { id: 'email', label: "Employee's Email Address", minWidth: 150},
  { id: 'time_used', label: 'Vacation Time Used(in days)', format: (value) => value.toLocaleString('en-US'), minWidth: 150 },
  { id: 'time_remaining', label: 'Vacation Time Remaining(in days)', format: (value) => value.toLocaleString('en-US'), minWidth: 150 },
];

class VacationTimeUsed extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      rows: [],
     };
  }

  async componentDidMount() {
    try{
      const employees = await axios.get('/get-users', {
        params: {
          roleId: 3
        }
      });
      const employeesData = employees.data;
      if(Array.isArray(employeesData) && employeesData !== "no users found"){
        let data = null;
        employeesData.forEach(async(employee, index)=>{
          const email = employee.email;
          const response = await axios.get('/vacation-request', {
            params: {
              email,
            }
          });
          if(response.data === "no request found"){
            data = { id: index+1, email, time_used: 0,  time_remaining: 10 };
          } else {
            const daysUsed = calculateVacationTimeUsed(response.data);
            data = { id: index+1, email, time_used: daysUsed,  time_remaining: 10 - daysUsed };
          }
          this.setState({ rows: [ ...this.state.rows, data ]})
        })
        this.setState({ isLoading: false });
      }
    }
    catch(e){
      console.log(e.toString());
    }
  }

  render() {
    const { isLoading, rows } = this.state;
    const { classes } = this.props;
    const sortedRows = rows ? rows.sort((a, b) => (a.id > b.id) ? 1 : -1) : null;
    return (
      (!isLoading)
      ?
      (
        <div class={classes.mainContainer}>
          <Header showLogoutButton title={"Employees' Time Off Information"}/>
          <TableComponent
            classes={classes}
            columns={columns}
            rows={sortedRows}
          />
        </div>
      )
      :
      <div>Loading...</div>
    );
  }
}

export default withStyles(classComponentStyles)(VacationTimeUsed);