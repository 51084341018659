import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, makeStyles, withStyles } from '@material-ui/core';

const styles = {
    table:{
        minWidth: 650
    }
}

class SheetTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const classes = this.props.classes
        return (
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Epic Name</TableCell>
                            <TableCell align="right">Hours</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.data.Epics.map((row) => {
                            if (row.Name !== '') {
                                return (
                                    <TableRow key={row.Name}>
                                        <TableCell component="th" scope="row">
                                            {row.Name}
                                        </TableCell>
                                        <TableCell align="right">{row.Hours}</TableCell>
                                    </TableRow>
                                )
                            }
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
}


export default withStyles(styles)(SheetTable)