import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { withRouter } from 'react-router';
import {Button, TextField, withStyles} from '@material-ui/core';

import { convertTimeZone } from '../functions/CalculateVacationTimeUsed';
import { classComponentStyles } from '../styles/styles';

class ResetPasswordPage extends Component {
  constructor(){
    super();
    this.state = {
      confirmUserPassword: '',
      resetPasswordToken: '',
      passwordErrorStatus: false,
      isLoading: true,
      passwordConfirmationErrorStatus: false,
      userPassword: '',
    }
  }

  updatePassword = async () => {
    const { confirmUserPassword, resetPasswordToken, userPassword } = this.state;
    const { history } = this.props;

    try {
      if (userPassword.trim().length > 0 && confirmUserPassword.trim().length > 0 && userPassword === confirmUserPassword) {
        let passwordUpdateResponse = await axios.post('/update-password', {
          email: '',
          resetPasswordToken,
          password: userPassword,
        });

        if (passwordUpdateResponse.data === 'updated') {
          alert('Password has been reset successfully');
          history.push({
            pathname: '/register'
          });
        }
      }
    } catch (e) {
      console.log(e.toString());
    }
  }

  keyPressed = async (event) => {
    const { passwordErrorStatus, passwordConfirmationErrorStatus } = this.state;
    if (!passwordErrorStatus && !passwordConfirmationErrorStatus && event.key === "Enter") {
       event.preventDefault();
       await this.updatePassword();
     }
  }

  async componentDidMount() {
    try {
      const url = new URL(window.location.href);
      const resetPasswordToken = url.searchParams.get("e");
      if (resetPasswordToken){
        let response = await axios.get('/validate-user', {
          params: {
            email: '',
            resetPasswordToken,
          }
        });
        if(Array.isArray(response.data) && response.data !== "user does not exist"){
          const resetPasswordExpires = convertTimeZone(response.data[0].reset_password_expires);
          if( moment().isSameOrBefore(resetPasswordExpires)){
            this.setState({ resetPasswordToken, isLoading: false })
          }
          else{
            this.props.history.push({
              pathname: '/process-outcome',
              message: "Password Reset Link has expired. Please try requesting for a new reset link",
              link: '/register'
            })
          }
        } else{
          this.props.history.push({
            pathname: '/process-outcome',
            message: "Oops, something went wrong. Please try again",
            link: '/register'
          })
        }
      }
    } catch(e){
      console.log(e.toString());
    }
  }

  handlePasswordFieldChange = (event) => {
    const value = event.target.value
    const { confirmUserPassword } = this.state;

    if(value.trim().length > 0){
      this.setState({ passwordErrorStatus: false, userPassword: value })
    }
    else {
      this.setState({ passwordErrorStatus: true });
    }
    if(confirmUserPassword.length > 0 && value !== confirmUserPassword){
      this.setState({ passwordConfirmationErrorStatus: true });
    } else {
      this.setState({ passwordConfirmationErrorStatus: false });
    }
    this.forceUpdate();
  }

  handlePasswordConfirmationFieldChange = (event) => {
    const value = event.target.value;
    const { userPassword } = this.state;

    if(value.trim().length > 0){
      this.setState({ passwordConfirmationErrorStatus: false, confirmUserPassword: value })
    }
    if((userPassword.length > 0 && value !== userPassword) || (value.trim().length <= 0)){
      this.setState({ passwordConfirmationErrorStatus: true });
    }
    this.forceUpdate();
  }

  render(){
    const { passwordErrorStatus, isLoading, passwordConfirmationErrorStatus } = this.state;
    const { classes } = this.props;

    return (
      (!isLoading) ?
        <div class={classes.resetPasswordContainer}>
          <h1>To proceed, kindly enter your new password</h1>
          <div class={classes.resetPasswordFormControl}>
            <TextField
              id="outlined-error-helper-text"
              required={passwordErrorStatus || passwordConfirmationErrorStatus}
              error={passwordErrorStatus || passwordConfirmationErrorStatus}
              label="New password"
              helperText={passwordErrorStatus ? "Password cannot be empty." : passwordConfirmationErrorStatus ? "Password must be equal" : ""}
              onChange={this.handlePasswordFieldChange}
              variant="outlined"
              type="password"
              className={classes.resetPasswordTextField}
              onKeyPress={this.keyPressed}
            />
             <TextField
              id="outlined-error-helper-text"
              required={passwordErrorStatus || passwordConfirmationErrorStatus}
              error={passwordErrorStatus || passwordConfirmationErrorStatus}
              label="Confirm new password"
              helperText={passwordConfirmationErrorStatus ? "Password must be equal" : ""}
              onChange={this.handlePasswordConfirmationFieldChange}
              variant="outlined"
              type="password"
              className={classes.resetPasswordTextField}
              onKeyPress={this.keyPressed}
            />
          </div>
          <div>
            <Button
              disabled={passwordErrorStatus || passwordConfirmationErrorStatus}
              onClick={this.updatePassword}
            >Update Password</Button>
          </div>
        </div>
        :
        <div>Loading...</div>
    );
  }
}

export default withRouter(withStyles(classComponentStyles)(ResetPasswordPage));