import React, { Component } from 'react';
import { renderEmail } from 'react-html-email';
import axios from 'axios';
import { withStyles } from '@material-ui/core/';
import TableComponent from '../components/TableComponent';
import RegistrationEmail from '../components/EmailTemplate';
import Header from '../components/Header';
import { classComponentStyles } from '../styles/styles';

const columns = [
  { id: 'email', label: "Client's Email Address", minWidth: 150},
  { id: 'start_date', label: 'Vacation Starts', minWidth: 150 },
  { id: 'end_date', label: 'Vacation Ends', minWidth: 150 },
  { id: 'status', label: 'Request status', minWidth: 150, align: 'right' },
  { id: 'processed_by', label: 'Processed by', minWidth: 100, align: 'right' },
  { id: 'processed_at', label: 'Processed At', minWidth: 150, align: 'right' },
];

const dateColumns = ["start_date", "end_date", "processed_at"];

class VacationRequestsPage extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      requests: [],
      rows: [],
      page: 0,
      rowsPerPage: 10,
     };
  }

  async componentDidMount() {
    try {
      const response = await axios.get('/vacation-requests');
      if(response.data === "no request found"){
        return;
      }
      response.data.forEach(item => {
        const newRequest = { id: item.id, status: item.status,  email: item.email};
        this.setState({ requests: [...this.state.requests, newRequest], rows: [...this.state.rows, item] });
      });
      this.setState({ isLoading: false });
    }
    catch (e){
      console.log(e.toString());
    }
  }

  onSubmit = async (id, requests) => {
    try {
        const request = requests.find(request => request.id === id);
        let response = await axios.post('/process-vacation-request', {
          processedBy: localStorage.getItem('adminEmail'),
          request,
        });
        if (response.data === 'request processed successfully'){
          if (request.status === 'APPROVED' || request.status === 'DENIED'){
            const message = `Hi, <br>Your request to take time off has been ${request.status.toLowerCase()}`
            const messageHtml = renderEmail(<RegistrationEmail message={message}/>);
            axios({
              method: "POST",
              url: "/send-email",
              data: {
                receiverEmail: request.email,
                messageHtml: messageHtml,
                senderEmail: 'noreply@wlabel.co',
                subject: `VACATION REQUEST ${request.status}`
              }
            })
          }
          alert("Request was processed successfully");
          window.location.reload();
        }
    } catch(e) {
      console.log(e.toString());
    }
  }

  render() {
    const { isLoading, requests, rows } = this.state;
    const { classes } = this.props;

    return (
      (!isLoading)
      ?
      (
        <div class={classes.mainContainer}>
          <Header showLogoutButton title={"Employees' Requests for Vacation"}/>
          <TableComponent
            classes={classes}
            columns={columns}
            dateColumns={dateColumns}
            requests={requests}
            rows={rows}
            onSubmit={this.onSubmit}
          />
        </div>
      )
      :
      <div>Loading...</div>
    );
  }
}

export default withStyles(classComponentStyles)(VacationRequestsPage);