import React, { Component, Fragment } from 'react';
import { Button, Grid } from '@material-ui/core';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, Label, Bar } from 'recharts';
import domtoimage from 'dom-to-image';
import fileDownload from "js-file-download";

class BurndownBar extends Component {
    constructor(props){
        super(props);
    }

    handleSaveClick = () => {
        domtoimage.toBlob(document.getElementById('burndown-bar'), { bgcolor: '#FFFFFF' })
            .then(function (blob) {
                fileDownload(blob, `Burndown-Barchart.png`);
            });
    }

    render() {
        return (
            (this.props.data.length > 0) ? (
                <Fragment>
                    <Grid item xs={12}>
                        <div id="burndown-bar" style={{ paddingLeft: '20px', paddingBottom: '20px', paddingTop: '20px', paddingRight: '20px'}}>
                            <Fragment>
                                <h3 style={{ textAlign: "center" }}>{this.props.projectName}</h3>
                            </Fragment>
                            <BarChart width={850} height={350} data={this.props.data}
                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis>
                                    <Label value="Hours" position="left" angle={-90} />
                                </YAxis>
                                <Tooltip />
                                <Legend verticalAlign="bottom" />
                                <Bar dataKey="estimated" fill="#0000FF" />
                                <Bar dataKey="actual" fill="#FF0041" />
                            </BarChart>
                        </div>
                    </Grid>
                    <Grid item>
                        <Button onClick={this.handleSaveClick}>
                            Save
                    </Button>
                    </Grid>
                </Fragment>
            ) : (
                    <Fragment />
                )
        )
    }
}

export default BurndownBar;