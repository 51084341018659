import React, { Component, Fragment } from 'react';
import moment from 'moment';
import axios from 'axios';
import { TextField, Grid, Button } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import BurndownChart from '../components/burndownChart';
import BurndownBar from '../components/BurndownBar';
import { AppCtxConsumer } from '../context'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';


const filter = createFilterOptions();

export const createBurndownData = (totalHours, endDate, devHourDay, timeEntries, taskData, startDate) => {
    let remainingHours = totalHours;
    let actualHoursRemaining = totalHours;
    let startDay = moment(startDate).format('MM/DD/YYYY') || moment().format('MM/DD/YYYY');
    let days = moment(endDate).diff(moment(startDay), 'days');
    days = days - ((Math.floor(days/7)))
    let devs = ((totalHours/days)/devHourDay);
    let averageHours = devHourDay * devs;
    if (timeEntries.length > 0) {
        startDay = moment(timeEntries[timeEntries.length - 1].spent_date).subtract(1, 'day').format('MM/DD/YYYY');
    }

    if (taskData.task.name !== '' && taskData.task.name !== 'Total') {
        timeEntries = timeEntries.filter(T => T.task.id === taskData.task.id)
    }

    let newStateData = []
    newStateData.push({ 'time': startDay, 'estimate': totalHours, 'actual': actualHoursRemaining });
    let dayCount = 0
    for (let i = 1; dayCount < days; i++) {
        let date = moment(startDay).add(i, 'day').format('MM/DD/YYYY');
        if (moment(date).day() === 0 || moment(date).day() === 7) {
            remainingHours -= 0
        } else {
            remainingHours -= averageHours
            dayCount += 1
        }

        for (let j = timeEntries.length - 1; j >= 0; j--) {
            if (moment(timeEntries[j].spent_date).format('MM/DD/YYYY') === date && timeEntries[j].budgeted === true) {
                actualHoursRemaining -= timeEntries[j].hours
            }
        }

        if (remainingHours < 0) remainingHours = 0;
        if (actualHoursRemaining < 0) actualHoursRemaining = 0;
        newStateData.push( { 'time': date, 'estimate': remainingHours, 'actual': actualHoursRemaining} )
    }

    return {devs: devs, timeData: newStateData}
}

export const createBurndownBarData = (tasks, timeData) => {
    let data = [];
    for (let i = 0; i < tasks.length - 1; i++){
        let taskName = tasks[i].task.name;
        let estimated = tasks[i].budget;
        let actual = 0;
        for (let j = 0; j < timeData.length; j++) {
            if (timeData[j].task.name === taskName) {
                actual += timeData[j].hours;
            }
        }
        data.push({ name: taskName, estimated, actual });
    }
    return data;
}

class BurnDown extends Component {
    constructor() {
        super();
        this.state = {
            burndownData: {
                totalHours: 1,
                date: moment(moment().add(4, 'days')).format('MM/DD/YYYY'),
                devHoursDay: 1,
                devs: 0
            },
            projectData: {
                inputValue: '',
                project: { name: '' }
            },
            taskData: {
                inputValue: '',
                task: { task: { id: null, name: ''}}
            },
            tasks: [],
            timeData: [{ spent_date: moment().format('MM/DD/YYYY') }]
        }
    }


    handleDate = (e) => {
        let formatDate = moment(e).format('MM/DD/YYYY');
        this.setState({
            ...this.state,
            burndownData: {
                ...this.state.burndownData,
                date: formatDate
            }
        })
    }

    handleDevHours = (e) => {
        this.setState({
            ...this.state,
            burndownData: {
                ...this.state.burndownData,
                devHoursDay: parseInt(e.target.value)
            }
        })
    }

    handleProjectChange = async (e, nv) => {
        let data = await axios({
            url: '/api/harvest/tasks',
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                id: nv.id
            }
        })
        let timedata = await axios({
            url: '/api/harvest/project_time_entries',
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                projectId: nv.id
            }
        })
        this.setState({
            ...this.state,
            projectData: {
                ...this.state.projectData,
                inputValue: nv.name,
                project: nv
            },
            burndownData: {
                ...this.state.burndownData,
                totalHours: nv.budget
            },
            tasks: [...data.data.task_assignments, { billable: true, budget: nv.budget, task: { name: "Total" } }],
            timeData: timedata.data
        })
    }

    handleTaskChange = (e, nv) => {
        this.setState({
            ...this.state,
            taskData: {
                ...this.state.taskData,
                task: nv
            },
            burndownData: {
                ...this.state.burndownData,
                totalHours: nv.budget
            }
        })
    }

    handleSave = async () => {
        if (this.state.projectData.project.id && this.state.projectData.project.id !== 0) {
            let name = prompt('Enter a name for the configuration.');
            try {
                await axios({
                    method: 'post',
                    url: '/burndown-config',
                    data: {
                        name,
                        projectID: this.state.projectData.project.id,
                        endDate: this.state.burndownData.date,
                        devHourDay: this.state.burndownData.devHoursDay,
                        taskID: this.state.taskData.task.task.id
                    }
                });
                alert('Burndown Configuration Save Successful');
            } catch (e) {
                alert(`Error: Configuration was NOT saved. \n${e.toString()}`);
            }
        }
    }

    conditonalAutocomplete = () => {
        if (this.state.projectData.project.id && this.state.projectData.project.id !== 0) {
            return (
                <Autocomplete id="Search Select"
                    clearOnEscape={true}
                    options={this.state.tasks}
                    value={this.state.taskData.task}
                    onChange={this.handleTaskChange}
                    getOptionLabel={(option) => option.task.name ? option.task.name : ""}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        return filtered;
                    }}
                    inputValue={this.state.taskData.inputValue}
                    onInputChange={(e, niv) => {
                        this.setState({
                            ...this.state,
                            taskData: {
                                ...this.state.taskData,
                                inputValue: niv
                            }
                        })
                    }}
                    style={{ width: 200 }}
                    renderInput={(params) => <TextField {...params} label="Select A Task" variant="outlined" />}
                />
            )
        } else {
            return (
                <Fragment />
            )
        }
    }

    chartTitle = (projectName, taskName) => {
        if (projectName && taskName) {
            return (
                <Fragment>
                    <h3>{taskName}</h3>
                </Fragment>
            )
        } else if (projectName && !taskName) {
            return (
                <Fragment>
                    <h3>{projectName}</h3>
                </Fragment>
            )
        } else {
            return (
                <Fragment />
            )
        }
    }


    render() {
        return (
            <AppCtxConsumer>
                {(context) => {
                    return (
                        <Grid container align="center" justify="center" spacing={1} xs={12}>
                            <Grid container align="center" justify="center" spacing={1} xs={8}>
                                <Grid item xs={12}>
                                    <h1>Burndown Charts</h1>
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete id="Search Select"
                                        options={context.harvestProjects}
                                        value={this.state.projectData.project}
                                        onChange={this.handleProjectChange}
                                        getOptionLabel={(option) => (option.client) ? (`${option.client.name}: ${option.name}`) : `${option.name}`}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            return filtered;
                                        }}
                                        inputValue={this.state.projectData.inputValue}
                                        onInputChange={(e, niv) => {
                                            this.setState({
                                                ...this.state,
                                                projectData: {
                                                    ...this.state.projectData,
                                                    inputValue: niv
                                                }
                                            })
                                        }}
                                        style={{ width: 200 }}
                                        renderInput={(params) => <TextField {...params} label="Select A Project" variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {this.conditonalAutocomplete()}
                                </Grid>
                                <Grid item xs={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            id="date-picker-end"
                                            label="End Date (MM/DD/YYYY)"
                                            format="MM/dd/yyyy"
                                            value={this.state.burndownData.date}
                                            onChange={this.handleDate}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField id="total-hours" label="Hours/Dev/Day" onChange={this.handleDevHours}>{this.state.burndownData.devHoursDay}</TextField>
                                </Grid>
                                <Grid item>
                                    <Button onClick={this.handleSave}>Save Configuration</Button>
                                </Grid>
                                <BurndownChart data={createBurndownData(this.state.burndownData.totalHours, this.state.burndownData.date, this.state.burndownData.devHoursDay, this.state.timeData, this.state.taskData.task, this.state.projectData.project.starts_on)} projectName={this.state.projectData.project.name} taskName={this.state.taskData.task.task.name} />
                                <BurndownBar data ={createBurndownBarData(this.state.tasks, this.state.timeData)} projectName={this.state.projectData.project.name} />
                            </Grid>
                        </Grid>
                    )
                }}
            </AppCtxConsumer>
        )
    }
}

export default BurnDown;