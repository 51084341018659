import React, { Component } from 'react';
import GradientButton from './GradientButton';
import '../styles/styles.css';

class EDashPolls extends Component {
    
    render(){
        return(
            <div class="module2">
                <p class="moduleText moduleText2">Polls</p>
                <div class="moduleContainer2 PollsContainer">
                    <p class="PollsName">Caroline Jobe</p>
                    <p class="PollsQuestion">Do you think we should have more watercoolers?</p>
                    <div class="PollsButtons">
                        <GradientButton 
                            primary={true}
                            margin="0 3.1% 0 6.1%"
                        >
                            Absolutely
                        </GradientButton>
                        <GradientButton>
                            Uhh, no
                        </GradientButton>
                    </div>
                </div>
            </div>
        )
    }
}

export default EDashPolls;