import React, { useState } from 'react';
import { renderEmail } from 'react-html-email';
import { useHistory } from "react-router-dom";
import axios from 'axios';

import { Link } from '@material-ui/core';
import GradientButton from '../components/GradientButton';

import RegistrationEmail from '../components/EmailTemplate';

import { statelessComponentStyles } from '../styles/styles';

import '../styles/styles.css';
import flowers from '../assets/Flowers.png';
import EmailIcon from '../assets/Icons/Email.svg';

const UserRegistrationPage = () => {
  const [errorStatus, setErrorStatus] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');

  const classes =  statelessComponentStyles();
  const history = useHistory();

  const validateUser = async () => {
    let emailAddressLC = emailAddress.toLowerCase();
    if (emailAddressLC.trim().length > 0 && !errorStatus) {
      try {
        let response = await axios.get('/validate-user', {
          params: {
            email: emailAddressLC,
            resetPasswordToken: '',
          }
        });
        if(response.data === 'user does not exist'){
          let statusRes = await axios.get('/user-registration-status', {
            params: {
              email: emailAddressLC
            }
          });
          if (statusRes.data === "not found"){
            let newRes = await axios.post('/new-user-registration', {
              email: emailAddressLC,
            });
            if (newRes.data === 'registration successful') {
              const registrationMessage = `Hello. A new registration was submitted by ${emailAddressLC}. Kindly process it using`
              const link = `${process.env.REACT_APP_ENV_URL}/process-request?email=${emailAddressLC}`
              const messageHtml = renderEmail(<RegistrationEmail message={registrationMessage} link={link} />);
              let emailRes = await axios({
                method: "POST",
                url: "/send-email",
                data: {
                  receiverEmail: 'noreply@wlabel.co',
                  messageHtml: messageHtml,
                  senderEmail: emailAddressLC,
                  subject: 'New Bi-tools registration'
                }
              });
              if (emailRes.data.msg === 'success') {
                history.push({
                  pathname: '/process-outcome',
                  message: "Your registration was successful. We'll get back to you when your application is processed",
                  link: '/register'
                })

              } else if (emailRes.data.msg === 'fail') {
                history.push({
                  pathname: '/process-outcome',
                  message: "Oops, something went wrong. Please try again",
                  link: '/register'
                })
              }
            }
          } else {
            const status = statusRes.data[0].request_status;

            if (status === 'pending') {
              history.push({
                pathname: '/process-outcome',
                message: "Your application is still pending and you won't be able to login at this time"
              })
            } else if (status === 'denied') {
              history.push({
                pathname: '/process-outcome',
                message: "Your application was denied and as a result, you don't have access to login"
              })
            }
          }
        } else {
          const roleId = response.data[0].role_id;
          delete response.data[0].password;

          // eslint-disable-next-line default-case
          switch (roleId) {
            case 1:
              history.push({
                pathname: '/login',
                response: response
              })
              break;
            case 2:
              history.push({
                pathname: '/login',
                response: response
              })
              break;
            case 3:
              history.push({
                pathname: '/login',
                response: response
              })
              break;
          }
        }
      } catch (e) {
        console.log(e.toString());
      }
    }
  }

  const validateEmail = (event) => {
    //const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const email = event.target.value;
    //console.log(email);
    
    setEmailAddress(email);
    // if(email.match(EMAIL_REGEX)){
    //   console.log('im here');
    //   setErrorStatus(false);
    // } else{
    //   setErrorStatus(true);
    // }
  }

  const keyPressed = async (event) => {
    if (event.key === "Enter") {
      if (!errorStatus){
        event.preventDefault();
        await validateUser();
      } else {
        event.preventDefault();
      }
    }
  }

  return (
    <div class="signInMain">
      <div class="signInLeft">
        <img src={flowers} alt="flowers" />
      </div>
      <div class="signInRight">
        <div class="signInText">
          Sign In
        </div>
        <div class="signInUsername">
          <label class="emailLabel">
            <img class="emailIcon" src={EmailIcon} alt="emailIcon" />
            <input
              class="emailInput"
              name="email"              
              type="text"
              value={emailAddress}
              onChange={validateEmail}
              placeholder="Email"
              pattern="/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/"
              onKeyPress={keyPressed}
            >
        
            </input>
          </label>
        </div>
        <div class="signInForgotP">
          <Link href="/forgot-password">Forgot password?</Link>
        </div> 
        <div class="signInButton" onClick={validateUser}>
          <GradientButton primary arrow>
            Sign in
          </GradientButton>
        </div>
      </div>
      {/* <h1>Welcome to the Bi-Tools Page</h1>
      <form className={classes.formControl} noValidate autoComplete="off">
        <div>
          <div>
            <p>Enter your email address</p>
            <TextField
              required={!errorStatus}
              error={errorStatus}
              id="outlined-error-helper-text"
              helperText={errorStatus ? "Incorrect email address." : ""}
              label="Email address"
              onChange={validateEmail}
              onKeyPress={keyPressed}
              variant="outlined"
            />
          </div>
          <div>
            <Button onClick={validateUser}>
              Continue
            </Button>
          </div>
          <Link href="/forgot-password">Forgot password?</Link>
        </div>
      </form> */}
    </div>
  );
}

export default UserRegistrationPage;