import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppCtxProvider } from './context';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import dotenv from 'dotenv'
dotenv.config()

Sentry.init({
  dsn: "https://5acb45a942c84a61a2e4e9fef3f24ab8@o1131776.ingest.sentry.io/6176873",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.hydrate(
  <React.StrictMode>
    <AppCtxProvider>
      <App />
    </AppCtxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
