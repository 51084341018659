import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import queryString from 'query-string';
import './App.css';
import { Home } from './containers/HomePage';
import Authentication from './containers/AuthenticationPage';
import LoginPage from './containers/LoginPage';
import UserRegistrationPage from './containers/UserRegistrationPage';
import ProcessAccessRequestPage from './containers/ProcessAccessRequestPage';
import ProcessOutcomePage from './containers/ProcessOutcomePage';
import ForgotPasswordPage from './containers/ForgotPasswordPage';
import ResetPasswordPage from './containers/ResetPasswordPage';
import AddNewUrlPage from './containers/AddNewUrlPage';
import UrlStatusPage from './containers/UrlStatusPage';
import GithubIssuesPage from './containers/GithubIssuesPage';
import VacationRequestsPage from './containers/VacationRequestsPage';
import VacationTimeUsedPage from './containers/VacationTimeUsedPage';
import ProjectDomainKnowledgePage from './containers/ProjectDomainKnowledgePage';
import BillableHoursTargetPage from './containers/BillableHoursTargetPage';
import AddHolidayPage from './containers/AddHolidayPage';
import SetYearlyTargetPage from './containers/SetYearlyTargetPage';
import EmployeeDashboard from './containers/EmployeeDashboard';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

class App extends Component {
  constructor() {
    super();
    this.state = {
      gapiReady: false,
      harvestAuth: {
        access_token: null
      },
      clientData: {},
      isSignedIn: false
    }
  }

  componentDidMount = () => {
    this.loadGoogleApi();
    const harvestAuth = queryString.parse(window.location.search);
    this.setState({ ...this.state, harvestAuth })
  }

  updateSigninStatus = (isSignedIn) => {
    if (isSignedIn) {
      this.setState({ ...this.state, isSignedIn: isSignedIn })
    }
  }

  initClient = async () => {
    window.gapi.client.init({
      apiKey: GOOGLE_API_KEY,
      clientId: GOOGLE_CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.readonly',
      discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4', 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
    });
    this.setState({ ...this.state, gapiReady: true });
    await window.gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus);
    this.updateSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get());
  }



  loadGoogleApi = () => {
    const script = document.createElement("script");
    script.src = 'https://apis.google.com/js/client.js'

    script.onload = () => {
      window.gapi.load('client:auth2', async () => {
        await this.initClient();
      });
    };

    document.body.appendChild(script);
  }


  render() {
    if (this.state.gapiReady) {
      return(
        <Router>
          <div>
            {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
            <Switch>
              <Route path="/dashboard" component={Home} />
              <Route path="/add-new-url" component={AddNewUrlPage} />
              <Route path="/url-status" component={UrlStatusPage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/register" component={UserRegistrationPage} />
              <Route path="/process-request" component={ProcessAccessRequestPage} />
              <Route path="/process-outcome" component={ProcessOutcomePage} />
              <Route path="/forgot-password" component={ForgotPasswordPage} />
              <Route path="/reset-password" component={ResetPasswordPage} />
              <Route path="/process-github-issues" component={GithubIssuesPage} />
              <Route path="/process-vacation-requests" component={VacationRequestsPage} />
              <Route path="/time-off-information" component={VacationTimeUsedPage} />
              <Route path="/domain-knowledge" component={ProjectDomainKnowledgePage} />
              <Route path="/billable-hours-target" component={BillableHoursTargetPage} />
              <Route path="/add-holiday" component={AddHolidayPage} />
              <Route path="/set-yearly-target" component={SetYearlyTargetPage} />
              <Route path="/" component={Authentication} />
            </Switch>
          </div>
        </Router>
      )
    } else {
      return(
        <div>
          Loading...
        </div>
      )
    }
  }
}





export default App;