import React from 'react';
import clsx from 'clsx';
import {
  AppBar,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  useTheme
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { statelessComponentStyles } from '../styles/styles';


export default function PersistentDrawerLeft(props) {
  const classes = statelessComponentStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const createHandleClick = (route) => (e) => {
    // eslint-disable-next-line default-case
    switch (route){
        case 'Dashboard':
            setOpen(false);
            props.history.push('/dashboard');
            break;
        case 'Harvest Project Importer':
            setOpen(false);
            props.history.push('/dashboard/harvest-project');
            break;
        case 'Github Project Importer':
            setOpen(false);
            props.history.push('/dashboard/github-project');
            break;
        case 'Create Burndown Chart':
            setOpen(false);
            props.history.push('/dashboard/create-burndown');
            break;
        case 'Reload Burndown Chart':
            setOpen(false);
            props.history.push('/dashboard/reload-burndown');
            break;
        case 'Relate Projects':
          setOpen(false);
          props.history.push('/dashboard/project-mapper');
          break;
        case 'Lighthouse Audit':
          setOpen(false);
          props.history.push('/dashboard/lighthouse-audit');
          break;
          case 'Gantt Chart':
            setOpen(false);
            props.history.push('/dashboard/gantt-chart');
            break;
        case 'Billable Hours Target':
          setOpen(false);
          props.history.push('/dashboard/billable-hours-target');
          break;
    }
}

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.drawerRoot}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            WhiteLabel BI-Tools
          </Typography>
          <Button className={classes.logoutButton} color="inherit" href={'/logout'}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {['Dashboard', 'Harvest Project Importer', 'Github Project Importer', 'Create Burndown Chart', 'Reload Burndown Chart', 'Relate Projects', 'Lighthouse Audit', 'Gantt Chart', 'Billable Hours Target'].map((text, index) => (
            <ListItem button onClick={createHandleClick(text)} key={text}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {props.children}
      </div>
    </div>
  );
}