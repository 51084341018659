import React, { Component, Fragment } from 'react';
import axios from 'axios';
import {
    MenuItem,
    Grid,
    Paper,
    Select,
    FormControl,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core/';
import lodash from 'lodash';
import moment from 'moment';

const years = lodash.range(2020, 2100);

class HolidayTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            year: moment().year()
        }
    }

    async componentDidMount() {
        let data = await axios({
            url: `/company-holidays?year=${this.state.year}`,
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        this.setState({
            ...this.state,
            holidays: data.data.data
        })
    }

    handleChange = async (e) => {
        let data = await axios({
            url: `/company-holidays?year=${e.target.value}`,
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        this.setState({
            year: e.target.value,
            holidays: data.data.data
        })
    }

    render() {
        return (
            <Fragment>
                <Grid container direction="column" justify="center" alignItems="center" spacing={3} xs={12}>
                    <Grid item>
                        <h3>Select a year to view its scheduled holidays</h3>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <InputLabel>Year</InputLabel>
                            <Select
                                labelId="holiday-year-select"
                                id="year-select"
                                value={this.state.year}
                                onChange={this.handleChange}
                            >
                                {years.map(item => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {(this.state.holidays && this.state.holidays.length > 0) ? (
                        <Grid item>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Holiday Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.holidays.map(item => (
                                            <TableRow>
                                                <TableCell>{item.date}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    ) : (
                            <Fragment />
                        )}
                </Grid>
            </Fragment>
        )
    }
}

export default HolidayTable