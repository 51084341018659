import React, { Component } from 'react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import { withRouter, Link } from "react-router-dom";
import { renderEmail } from 'react-html-email';
import  {
  Button,
  Card,
  CardContent,
  Collapse,
  createMuiTheme,
  FormControl,
  InputLabel,
  List,
  ListItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
  withStyles
} from '@material-ui/core';
import '../index.css';
import axios from 'axios';
import moment from 'moment';
import RegistrationEmail from '../components/EmailTemplate';
import { calculateVacationTimeUsed } from '../functions/CalculateVacationTimeUsed';
import EmployeeDashboard from './EmployeeDashboard';


const ORGANIZATION_PATH_NAME = "whitelabelco";

const getIssuesOfRepositoryQuery = (organization, repository) => `
    {
      organization(login: "${organization}") {
        name
        url
        repository(name: "${repository}"){
          id
          name
          url
          issues(last: 20 states:OPEN){
            edges{
              node {
                id
                title
                url
                labels(first:5) {
                  edges {
                    node {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

const axiosGitHubGraphQL = axios.create({
  baseURL: 'https://api.github.com/graphql',
  headers: {
    Authorization: `bearer ${process.env.REACT_APP_GITHUB_ACCESS_TOKEN}`
  }
});

const useStyles = () => ({
  adminContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '25rem',
    margin: '0 auto',
  },
  adminContainerButtons: {
    margin: '0.5rem',
  },
  body: {
    width: '40rem'
  },
  dashboard:{
    textAlign: 'center',
  },
  body: {
    width: '40rem'
  },
  issueList: {
    width: '50%',
    margin: 'auto',
  },
  mainClientContainer: {
    marginTop: '3rem',
    textAlign: 'center',
  },
  mainContainer: {
    marginTop: '3rem',
    textAlign: 'center',
  },
  form: {
    minWidth: "10rem",
  },
  submitButton:{
    marginTop: '1rem',
  },
  title: {
    marginTop: '1rem ',
    marginBottom: '1rem',
  },
  spanText: {
    fontSize: '1rem',
  },
  vacationContainer:{
    maxWidth: '20rem',
  },
  vacationRoot: {
    minWidth: 275,
  },
  vacationTitle: {
    fontSize: 14,
  },
});

const theme =  createMuiTheme({
  overrides: {
    MuiCollapse: {
      container: {
        display: 'flex',
        justifyContent: 'center',
      },
    }
  }
});

class DashboardPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      daysUsed: 0,
      endDate: null,
      errors: null,
      expandCreateNewIssue: false,
      expandViewOpenIssues: false,
      expandViewWebsiteStatus: false,
      expandRequestTimeOff: false,
      expandViewVacationTime: false,
      focusedInput: null,
      isAdmin: false,
      isClient: false,
      isEmployee: false,
      issueTitle: '',
      issueBody: '',
      managementEmails: [],
      organization: null,
      repo: '',
      repoList: [],
      repositoryId: '',
      startDate: null,
      repoList: [],
      organization: null,
      repo: '',
      repositoryId: '',
      websiteStatus: [],
    }
  }

  async componentDidMount(){
    if(localStorage.getItem('isAdmin')){
        this.setState({ isAdmin: true });
    } else if(localStorage.getItem('isEmployee')){
      try{
        this.setState({ isEmployee: true });
        let management = await axios.get('/get-users', {
          params: {
            roleId: 1
          }
        });
        if(Array.isArray(management.data) && management.data !== "no users found"){
          management = management.data.filter(item => item.email !== 'michael@wlabel.co' && item.email !== 'jake@wlabel.co' && item.email !== 'olu@wlabel.co')
          this.setState({managementEmails: management})
        }

        const response = await axios.get('/vacation-request?status=APPROVED', {
          params: {
            email: localStorage.getItem('employeeEmail'),
          }
        });
        if(response.data === "no request found"){
          return;
        }
        const daysUsed = calculateVacationTimeUsed(response.data);
        this.setState({ daysUsed });
      }
      catch(e){
        console.log(e.toString())
      }

    } else if(localStorage.getItem('isClient')){
      this.setState({ isClient: true })
      try {
        let response = await axios.get('/client-repos', {
          params: {
            email: localStorage.getItem('clientEmail'),
          }
        });
        const data = response.data;
        if (data === 'no repo') {
          return;
        } else if (Array.isArray(data)){
          this.setState({repoList: response.data[0].repos});
        }
      }
      catch (error) {
        console.log(error.toString());
      }
    }
  }

  handleChange = (event) => {
    const value = event.target.value;
    if (!value || value.trim().length === 0){
      this.setState({repo: '', repositoryId: ''});
    } else {
      this.setState({repo: value}, this.onFetchFromGitHub(ORGANIZATION_PATH_NAME, value));
    }
  };

  handleExpandNewIssueContent = () => {
    this.setState({
      expandCreateNewIssue : !this.state.expandCreateNewIssue,
      expandViewOpenIssues: false,
      expandViewWebsiteStatus: false,
    });
  };

  handleExpandOpenIssuesContent = () => {
    this.setState({
      expandViewOpenIssues: !this.state.expandViewOpenIssues,
      expandCreateNewIssue: false,
      expandViewWebsiteStatus: false,
    });
  };

  handleExpandRequestTimeOff = () => {
    this.setState({
      expandRequestTimeOff: !this.state.expandRequestTimeOff,
      expandViewVacationTime: false,
    });
  };

  handleExpandViewVacationTime = () => {
    this.setState({
      expandViewVacationTime: !this.state.expandViewVacationTime,
      expandRequestTimeOff: false,
    });
  };

  handleExpandWebsiteStatusContent = async () => {
    try {
      let response = await axios.get('/url-state', {
        params: {
          repoName: this.state.repo,
        }
      });
      if (Array.isArray(response.data)){
        this.setState({ websiteStatus: response.data[0] })
      }
    }
    catch(e){
      console.log(e.toString());
    }
    this.setState({
      expandViewWebsiteStatus : !this.state.expandViewWebsiteStatus,
      expandCreateNewIssue: false,
      expandViewOpenIssues: false,
    });
  };

  setIssueTitle = (event) => {
    const value = event.target.value;
    this.setState({issueTitle: value});
  }

  setIssueBody = (event) => {
    const value = event.target.value;
    this.setState({issueBody: value});
  }

  onFetchFromGitHub = (organization, repository) => {
    axiosGitHubGraphQL
      .post('', {query: getIssuesOfRepositoryQuery(organization, repository) })
      .then(result => {
        this.setState(() => ({
          organization: result.data.data.organization,
          repositoryId: result.data.data.organization.repository.id,
          errors: result.data.errors,
        }))
      }
      );
  }

  handleSubmitIssue = async () => {
    try{
      const { repo, repositoryId, issueTitle, issueBody } = this.state
      let response = await axios.post('/submit-github-ticket', {
        email: localStorage.getItem('clientEmail'),
        issueTitle,
        issueBody,
        repositoryId,
        repoName: repo,
      });
      if (response.data === 'ticket created successfully'){
        alert("Ticket was created successfully");
        document.getElementById("issue-title").value = '';
        document.getElementById("issue-body").value = '';
        this.setState({issueBody: '', issueTitle: ''})
        this.forceUpdate();
      }
    }
    catch(error){
      console.log(error.toString());
    }
  }

  handleSubmitTimeOffRequest = async () => {
    try{
      const { startDate, endDate, managementEmails } = this.state;
      const email = localStorage.getItem('employeeEmail');
      let response = await axios.post('/submit-vacation-request', {
        email: email,
        startDate: moment(startDate).startOf('day'),
        endDate: endDate === null ? moment(startDate).startOf('day') : moment(endDate).endOf('day'),
      });
      if (response.data === 'request submitted successfully'){
        const message = `Hi, <br>A new request for time off has been submitted by ${email}`
        const messageHtml = renderEmail(<RegistrationEmail message={message}/>);
        managementEmails.forEach(async (management)=> {
           await axios({
            method: "POST",
            url: "/send-email",
            data: {
              receiverEmail: management.email,
              messageHtml: messageHtml,
              senderEmail: email,
              subject: `NEW REQUEST FOR TIME OFF`
            }
          })
        })
        alert("Request was submitted successfully");
        document.getElementById("start-date").value = '';
        document.getElementById("end-date").value = '';
        this.setState({startDate: null, endDate: null})
        this.forceUpdate();
      }
    }
    catch(error){
      console.log(error.toString());
    }
  }

  render() {
    const {
      daysUsed,
      endDate,
      errors,
      expandCreateNewIssue,
      expandViewOpenIssues,
      expandViewWebsiteStatus,
      expandRequestTimeOff,
      expandViewVacationTime,
      focusedInput,
      repoList,
      repo,
      organization,
      repositoryId,
      isAdmin,
      isClient,
      isEmployee,
      issueBody,
      issueTitle,
      startDate,
      websiteStatus
    } = this.state;
    const repository = organization ? organization.repository : null;
    const { classes } = this.props;
    const daysUsedPercent = daysUsed > 10 ? Math.floor(daysUsed/10 * 100) : daysUsed/10 * 100;
    const timeOffRemainingPercent = daysUsedPercent <= 100 ? 100 - daysUsedPercent : 0;

    if (isClient){
      return (
        (<div class={classes.mainClientContainer}>
          <FormControl className={classes.form}>
            <InputLabel htmlFor="repo-selector">Select a repository</InputLabel>
            <Select
              native
              value={repo}
              onChange={this.handleChange}
              inputProps={{
                id: 'repo-selector',
              }}
            >
              <option aria-label="None" value="" />
              {repoList.map(item => (
                <option value={item}>{item}</option>
              ))}
            </Select>
          </FormControl>
          <hr />
          {!errors && repositoryId.length > 0 ?
            (
              <div>
                <Button onClick={this.handleExpandNewIssueContent}>Create a new ticket</Button>
                <Button onClick={this.handleExpandOpenIssuesContent}>View open issues</Button>
                <Button onClick={this.handleExpandWebsiteStatusContent}>View uptime status of your website</Button>
                <Collapse in={expandCreateNewIssue} timeout="auto" unmountOnExit>
                  <div>
                    <FormControl noValidate autoComplete="off">
                      <div>
                        <TextField
                          required
                          id="issue-title"
                          label="Title"
                          onChange={this.setIssueTitle}
                          className={classes.title}
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          required
                          className={classes.body}
                          id="issue-body"
                          label="Body"
                          multiline
                          rows={8}
                          variant="outlined"
                          onChange={this.setIssueBody}
                        />
                      </div>
                      <div>
                        <Button
                          className={classes.submitButton}
                          disabled={issueBody.length === 0 || issueTitle.length === 0}
                          onClick={this.handleSubmitIssue}
                        >
                          Submit new ticket
                        </Button>
                      </div>
                    </FormControl>
                  </div>
                </Collapse>
                <Collapse in={expandViewOpenIssues} timeout="auto" unmountOnExit>
                  {repository.issues.edges.length > 0 ?
                    <div>
                      <p>
                        <strong>Open Issues for {repository.name} repo</strong>
                      </p>
                      <List className={classes.issueList}>
                        {repository.issues.edges.map((issue, index) => (
                          <ListItem key={issue.node.id}>
                            {index+1}. {issue.node.title}
                          </ListItem>
                        ))}
                      </List>
                    </div>
                    :
                    <div><p>No open issues available</p></div>
                  }
                </Collapse>
                <Collapse in={expandViewWebsiteStatus} timeout="auto" unmountOnExit>
                 <div>
                  {
                    websiteStatus.length === 0 || websiteStatus.status === 'Pending' ?
                    <p>No information available at this time. Kindly check back later.</p>
                    :
                    <p>As at {(new Date(Date.parse(websiteStatus.updated_at))).toLocaleString()}, your website is {websiteStatus.status}.</p>
                  }
                  </div>
                </Collapse>
              </div>
            )
            : (
              <p>No information available...</p>
            )
          }
        </div>)
      )
    }
    else if(isAdmin){
      return(
        <div class={classes.dashboard}>
          <h1>Welcome to the Admin Dashboard</h1>
          <div class={classes.adminContainer}>
            <Button className={classes.adminContainerButtons} component={Link} to="/process-request">
              Process Access request
            </Button>
            <Button className={classes.adminContainerButtons} component={Link} to="/add-new-url">
              Add New Url
            </Button>
            <Button className={classes.adminContainerButtons} component={Link} to="/url-status">
              Check Clients' Website Status
            </Button>
            <Button className={classes.adminContainerButtons} component={Link} to="/process-github-issues">
              Process Github Issues Created by Clients
            </Button>
            <Button className={classes.adminContainerButtons} component={Link} to="/process-vacation-requests">
              Process Vacation Requests
            </Button>
            <Button className={classes.adminContainerButtons} component={Link} to="/time-off-information">
              View Employees' Time Off Information
            </Button>
            <Button className={classes.adminContainerButtons} component={Link} to="/add-holiday">
              Add Company Holiday
            </Button>
            <Button className={classes.adminContainerButtons} component={Link} to="/set-yearly-target">
              Set Employee Yearly Targets
            </Button>
          </div>
        </div>
      )
    }
    else if(isEmployee){
      return(
        <EmployeeDashboard />

        // <div class={classes.dashboard}>
        //   <h1>Welcome to the Employee Dashboard</h1>
        //   <Button onClick={this.handleExpandRequestTimeOff}>Request time off</Button>
        //   <Button onClick={this.handleExpandViewVacationTime}>View vacation time used/remaining</Button>
        //   <Collapse in={expandRequestTimeOff} timeout="auto" unmountOnExit>
        //     <div>
        //       <DateRangePicker
        //         startDate={startDate}
        //         startDateId="start-date"
        //         endDate={endDate}
        //         endDateId="end-date"
        //         onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
        //         focusedInput={focusedInput}
        //         onFocusChange={focusedInput => this.setState({ focusedInput })}
        //       />
        //     </div>
        //     <Button
        //       disabled={startDate === null}
        //       onClick={this.handleSubmitTimeOffRequest}
        //     >
        //       Submit Request
        //     </Button>
        //   </Collapse>
        //   <ThemeProvider theme={theme}>
        //     <Collapse className={classes} in={expandViewVacationTime} timeout="auto" unmountOnExit>
        //       <div class={classes.vacationContainer}>
        //         <Card className={classes.vacationRoot}>
        //           <CardContent>
        //             <Typography className={classes.vacationTitle} color="textSecondary" gutterBottom>
        //               Vacation Days Used
        //             </Typography>
        //             <Typography variant="h5" component="h1">
        //               {daysUsed}<span class={classes.spanText}> out of </span>10
        //             </Typography>
        //           </CardContent>
        //         </Card>
        //         <Card className={classes.vacationRoot}>
        //           <CardContent>
        //             <Typography className={classes.vacationTitle} color="textSecondary" gutterBottom>
        //               Percentage of Vacation Days Used
        //             </Typography>
        //             <Typography variant="h5" component="h1">
        //               {daysUsedPercent}%
        //             </Typography>
        //           </CardContent>
        //         </Card>
        //         <Card className={classes.vacationRoot}>
        //           <CardContent>
        //             <Typography className={classes.vacationTitle} color="textSecondary" gutterBottom>
        //               Percentage of Vacation Days Remaining
        //             </Typography>
        //             <Typography variant="h5" component="h1">
        //               {timeOffRemainingPercent}%
        //             </Typography>
        //           </CardContent>
        //         </Card>
        //       </div>
        //     </Collapse>
        //   </ThemeProvider>
        // </div>
      )
    }
    else{
      return(
        <div><h1>Loading...</h1></div>
      )
    }
  }
}

export default withStyles(useStyles)(withRouter(DashboardPage));