import React, { useState, Fragment } from 'react';
import {
  Button,
  Input,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core/';

const TableComponent = ({classes, columns, dateColumns, requests, rows, onSubmit}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [allRequests, setAllRequest] = useState(requests)
  const dateFormatOptions = { month: '2-digit', year: 'numeric', day: '2-digit' };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleStatusChange = (value, id) => {
    let newRequests = [...allRequests];
    // eslint-disable-next-line array-callback-return
    newRequests.find((eachRequest, index) => {
      if (eachRequest.id === id) {
          newRequests[index] = {...eachRequest, status: value};
          return true;
      }
    });
    setAllRequest(newRequests);
  };

  const handleOnSubmit = async (id, requests) => {
    await onSubmit(id, requests)
  }

  return (
    <Paper className={classes.tableRoot}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.tableCell}
                  style={{ minWidth: column.minWidth }}
                >
                  <strong>{column.label}</strong>
                </TableCell>
              ))}
                <TableCell
                  key={'status'}
                  className={classes.tableCell}
                >
                </TableCell>
                <TableCell
                  key={'process-request'}
                  className={classes.tableCell}
                >
                </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              const request = allRequests ? allRequests.find(eachRequest => eachRequest.id === row.id) : null;

              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        { dateColumns && dateColumns.includes(column.id)
                          ?
                          value === null ? '' : (new Date(Date.parse(value))).toLocaleString('en-US', dateFormatOptions)
                          :
                          column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                  {  onSubmit &&
                    <Fragment>
                      <TableCell key="status">
                        <div key={row.id}>
                          <Select
                            disabled={row["status"] !== 'PENDING'}
                            value={ request ? request.status : ''}
                            onChange={event => handleStatusChange(event.target.value,row.id)}
                            input={<Input />}
                          >
                            <MenuItem value={'APPROVED'}>APPROVE</MenuItem>
                            <MenuItem value={'DENIED'}>DENY</MenuItem>
                          </Select>
                        </div>
                      </TableCell>
                      <TableCell key="submit">
                        <Button
                          disabled={row["status"] !== 'PENDING'}
                          onClick={() => handleOnSubmit(row.id, allRequests)}
                        >
                        Submit
                        </Button>
                      </TableCell>
                    </Fragment>
                  }
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default TableComponent;