import React, { Component } from 'react';
import Emoji from './Emoji';
import '../styles/styles.css';
import moment from 'moment';

class EDashPD extends Component {
    costructor(){
        this.setState({ days2PD: 0, loading: true });
    }
    
    componentDidMount(){
        let today = moment();
        let daysInMonth = today.daysInMonth();
        let remaining = 0;
        if (today.date() <= 15) {
            if (today.day() !== (0 || 6)){
                remaining = 15 - today.date();
            } else if (today.day() === 0) {
                remaining = 15 - 2 - today.date();
            } else if (today.day() === 6) {
                remaining = 15 - 1 - today.date();
            }
        } else {
            if (today.day() !== (0 || 6)){
                remaining = daysInMonth - today.date();
            } else if (today.day() === 0) {
                remaining = daysInMonth - 2 - today.date();
            } else if (today.day() === 6) {
                remaining = daysInMonth - 1 - today.date();
            }
        }

        this.setState({
            days2PD: remaining
        })
        
    }

    render(){
        return(
            <div class="module2">
                <p class="moduleText moduleText2">Payday Countdown</p>
                <div class="moduleContainer2 PDContainer">
                    <div class="PDNumber">
                        {(this.state) ? this.state.days2PD : 0}
                    </div>
                    <div class="PDText">
                        Days until Payday
                    </div>
                    {(this.state && (this.state.days2PD % 2 === 0)) ? (
                        <Emoji class="FlyCash" symbol="💸" label="flycash" />
                    ) : (
                        <div class="EmojiPyramid">
                            <Emoji class="FlyCash1" symbol="💸" label="flycash" />
                            <div class="cashContainer">
                                <Emoji class="FlyCash2" symbol="💸" label="flycash" />
                                <Emoji class="FlyCash2" symbol="💸" label="flycash" />
                            </div>
                            <Emoji class="FlyCash3" symbol="💸" label="flycash" />
                            <Emoji class="FlyCash3" symbol="💸" label="flycash" />
                            <Emoji class="FlyCash3" symbol="💸" label="flycash" />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default EDashPD;