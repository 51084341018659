import React, { Component, Fragment } from 'react';
import { AppCtxConsumer } from '../context'
import { TextField, Grid, Button } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import axios from 'axios';
import { isEmpty } from 'lodash';
import DbTable from '../components/DbTable';

const filter = createFilterOptions();

class ProjectMapper extends Component {
    constructor() {
        super();
        this.state = {
            harvestProjectData: {
                project: {},
                inputValue: ''
            },
            githubProjectData: {
                project: {},
                inputValue: ''
            }
        }
    }

    handleHarvestProjectChange = (e, nv) => {
        this.setState({
            ...this.state,
            harvestProjectData: {
                ...this.state.harvestProjectData,
                project: nv
            }
        })
    }

    handleGithubProjectChange = (e, nv) => {
        this.setState({
            ...this.state,
            githubProjectData: {
                ...this.state.githubProjectData,
                project: nv
            }
        })
    }

    handleDisable = () => {
        if (isEmpty(this.state.harvestProjectData.project) || isEmpty(this.state.githubProjectData.project.name)) {
            return true
        } else {
            return false
        }
    }

    handleClick = async () => {
        await axios({
            method: 'post',
            url: '/project-mappings',
            data: {
                harvestProject: this.state.harvestProjectData.project.name,
                githubProject: this.state.githubProjectData.project.name
            },
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    render() {
        return (
            <AppCtxConsumer>
                {(context) => {
                    return (
                        <Grid container align="center" justify="center" spacing={1} xs={12}>
                            <Grid item xs={6}>
                                <h1>Connect a Harvest and Github Project</h1>
                            </Grid>
                            <Grid container align="center" justify="center" spacing={1} xs={8}>
                                <Grid item xs={6}>
                                    <Autocomplete id="Harvest Project Select"
                                        options={context.harvestProjects}
                                        value={this.state.harvestProjectData.project}
                                        onChange={this.handleHarvestProjectChange}
                                        getOptionLabel={(option) => option.name}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            return filtered;
                                        }}
                                        inputValue={this.state.harvestProjectData.inputValue}
                                        onInputChange={(e, niv) => {
                                            this.setState({
                                                ...this.state,
                                                harvestProjectData: {
                                                    ...this.state.harvestProjectData,
                                                    inputValue: niv
                                                }
                                            })
                                        }}
                                        style={{ width: 200 }}
                                        renderInput={(params) => <TextField {...params} label="Select Harvest Project" variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete id="Github Project Select"
                                        options={context.githubProjects}
                                        value={this.state.githubProjectData.project}
                                        onChange={this.handleGithubProjectChange}
                                        getOptionLabel={(option) => option.name}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            return filtered;
                                        }}
                                        inputValue={this.state.githubProjectData.inputValue}
                                        onInputChange={(e, niv) => {
                                            this.setState({
                                                ...this.state,
                                                githubProjectData: {
                                                    ...this.state.githubProjectData,
                                                    inputValue: niv
                                                }
                                            })
                                        }}
                                        style={{ width: 200 }}
                                        renderInput={(params) => <TextField {...params} label="Select Github Project" variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {(context.relatedProjects ? <DbTable data={context.relatedProjects} /> : <Fragment />)}
                                </Grid>
                                <Grid item xs={4}>
                                    <Button disabled={this.handleDisable()} variant="contained" color="secondary" onClick={this.handleClick}>
                                        Relate Projects
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }}
            </AppCtxConsumer>
        )
    }
}

export default ProjectMapper;