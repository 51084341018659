import React, { Component, Fragment } from 'react';
import AddHoliday from '../components/AddHoliday';
import Header from '../components/Header';
import HolidayTable from '../components/HolidayTable';

class AddHolidayPage extends Component {
    
    render() {
    
        return (
            <Fragment>
                <Header showLogoutButton showBackButton title="Add Company Holiday" />
                <AddHoliday />
                <HolidayTable />
            </Fragment>
        )
    }
}

export default AddHolidayPage;