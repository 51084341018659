import React, { Component, Fragment } from 'react';
import {
    Button,
    Grid,
    Input,
    FormControl
} from '@material-ui/core';
import axios from 'axios';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import { formatISO } from 'date-fns';
import moment from 'moment';

class AddHoliday extends Component {
    constructor() {
        super();

        this.state = {
            date: formatISO(new Date())
        }
    }

    handleDate = (e) => {
        const formatDate = formatISO(e)
        this.setState({
            date: formatDate
        })
    }

    handleClick = async () => {
        const year = moment(this.state.date).year();
        const date = moment(this.state.date).format("MM/DD/YYYY");
        let data = await axios({
            url: '/add-company-holiday',
            method: 'post',
            data: {
                year,
                date
            },
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if (data.data.message === 'success') {
            alert('Holiday Added Successfully');
        } else {
            alert('Holiday addition failed. Please try again');
        }
    }

    render() {
        return (
            <Fragment>
                <Grid container justify="center" alignItems="center" spacing={3} xs={12}>
                    <Grid item>
                        <h3>Select a date to add to Whitelabel's Holiday Calendar</h3>
                    </Grid>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={3} xs={12}>
                        <Grid item>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    id="date-picker-holiday"
                                    label="Holiday (MM/DD/YYYY)"
                                    value={this.state.date}
                                    onChange={this.handleDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'new-holiday-date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" onClick={this.handleClick}>Sumbit Holiday</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}

export default AddHoliday;