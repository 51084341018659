import React, { Component, Fragment } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, makeStyles, withStyles } from '@material-ui/core';

const styles = {
    table:{
        minWidth: 450
    }
}

class DbTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const classes = this.props.classes
        return (
            <Fragment>
                <h4 align="center">Current Related Projects</h4>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Harvest Project</TableCell>
                                <TableCell align="right">Github Project</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.data.map((row) => {
                                if (row['Harvest Project'] !== '') {
                                    return (
                                        <TableRow key={row['Harvest Project']}>
                                            <TableCell component="th" scope="row">
                                                {row['Harvest Project']}
                                            </TableCell>
                                            <TableCell align="right">{row['Github Project']}</TableCell>
                                        </TableRow>
                                    )
                                }
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Fragment>
        )
    }
}


export default withStyles(styles)(DbTable)