import React from 'react';
import  {createMuiTheme, FormControl, MenuItem, Select, ThemeProvider} from '@material-ui/core';

const AuthorizationForm = ({status, handleChange, menuItems, inputLabel}) => {
  const theme =  createMuiTheme({
    overrides: {
            MuiInput: {
                root: {
                    position: "relative",
                    width: "10rem",
                },
            }
        }
    });

  return (
    <FormControl>
      <h3>{inputLabel}</h3>
      <ThemeProvider theme={theme}>
        <Select
          value={status}
          onChange={handleChange}
        >
          <MenuItem value={Object.keys(menuItems)[0]}>{menuItems[Object.keys(menuItems)[0]]}</MenuItem>
          <MenuItem value={Object.keys(menuItems)[1]}>{menuItems[Object.keys(menuItems)[1]]}</MenuItem>
          <MenuItem value={Object.keys(menuItems)[2]}>{menuItems[Object.keys(menuItems)[2]]}</MenuItem>
        </Select>
      </ThemeProvider>
    </FormControl>
  );
}

export default AuthorizationForm;