import React, { Component, Fragment } from 'react';
import {
    TableRow,
    TableCell,
} from '@material-ui/core';
import { findTakenDays, findBillableTotal, findNonBillableTotal } from '../functions/BillableTargetHoursCalculations';
import axios from 'axios';

class EmployeeSummary extends Component {
    constructor(props) {
        super(props);
        let { employee, workDays, year, holidays } = this.props;
        this.state = {
            employee,
            workDays,
            year,
            holidays,
            timeData: []
        }
    }

    async componentDidMount() {
        let data = await axios({
            method: 'post',
            url: '/api/harvest/user_time_entries',
            data: {
                year: this.state.year,
                userId: this.state.employee.id
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });
        this.setState({
            ...this.state,
            timeData: data.data
        })
    }

    render() {
        return (
            <Fragment>
                {(this.state.timeData.length > 0) ? (
                    <TableRow>
                    <TableCell>{this.state.employee.first_name} {this.state.employee.last_name}</TableCell>
                    <TableCell>{(10 - findTakenDays(this.state.timeData, 'Vacation')).toFixed(2)}</TableCell>
                    <TableCell>{(findTakenDays(this.state.timeData, 'Bonus Day')).toFixed(2)}</TableCell>
                    <TableCell>{(findBillableTotal(this.state.timeData) + findNonBillableTotal(this.state.timeData)).toFixed(2)}</TableCell>
                    <TableCell>{(findBillableTotal(this.state.timeData)).toFixed(2)}</TableCell>
                </TableRow>
                ) : (
                <Fragment />
            )}
            </Fragment>
        )
    }
}

export default EmployeeSummary