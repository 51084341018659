import React, { Fragment, Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import AuditDisplay from '../components/AuditDisplay';

class AuditDisplayPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auditData: null
        }
    }

    async componentDidMount() {
        let data = await axios({
            method: 'post',
            url: '/lighthouse-audits',
            data: {
                id: this.props.match.params.id,
                type: this.props.match.params.auditType
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });
        this.setState({
            ...this.state,
            auditData: data.data
        });
    }

    render() {
        if (this.props.match.params.id === 0 || this.props.match.params.auditType === '') {
            return (
                <Redirect to="/dashboard/lighthouse-audit" />
            )
        } else {
            return (
                <AuditDisplay id={this.props.match.params.id} type={this.props.match.params.auditType} auditData={this.state.auditData} />
            )
        }
    }
}

export default AuditDisplayPage

