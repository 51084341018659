import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import axios from 'axios';

import {Button, Link, TextField} from '@material-ui/core';
import GradientButton from '../components/GradientButton';

import { statelessComponentStyles } from '../styles/styles';

import test from '../assets/Flowers.png';
import LockIcon from '../assets/Icons/Lock.svg';

const LoginPage = () => {
  const history = useHistory();
  const location = useLocation();
  const classes =  statelessComponentStyles();

  const [userPassword, setUserPassword] = useState('');
  const [errorStatus, setErrorStatus] = useState(false);
  const [invalidPasswordStatus, setInvalidPasswordStatus] = useState(false);

  const handleTextFieldChange = (event) => {
    const value = event.target.value

    if(value.trim().length > 0){
      setErrorStatus(false);
      setInvalidPasswordStatus(false);
      setUserPassword(value);
    }
    else {
      setErrorStatus(true);
    }
  }

  const authenticateUser = async () => {
    localStorage.clear();

    const data = location.response ? location.response.data[0] : null;

    if (data === null) {
      history.replace({
        pathname: '/register'
      })
      return;
    }
    const passwordLength = userPassword.trim().length;

    if (data.role_id && data.role_id === 1) {
      try {
        const email = data.email;
        if (email && email.length > 0) {
          let response = await axios.post('/login', {
            email: email,
            password: userPassword
          });
          const data = response.data[0];
          const password = data.password;
          const authenticated = data.authenticated;
          //this condition will only be true when the admin logs in the first time
          if (password === "") {
            if (passwordLength > 0) {
              // do something
              let passwordRes = await axios.post('/update-password', {
                admin: true,
                email: email,
                password: userPassword,
                resetPasswordToken: '',
              });
              if (passwordRes.data === 'updated') {
                localStorage.setItem('isAdmin', true);
                localStorage.setItem('adminEmail', email);
                history.push({
                  pathname: '/',
                });
              }
            } else {
              setErrorStatus(true);
            }
          } else if (!authenticated) {
            setInvalidPasswordStatus(true);
          } else if (authenticated) {
            setInvalidPasswordStatus(false);
            localStorage.setItem('isAdmin', true);
            localStorage.setItem('adminEmail', email);
            history.push({
              pathname: '/',
            });
          }
        }
      } catch (e) {
        console.log(e.toString());
      }
    } else {
      try {
        const email = data.email;

        if (email && email.length > 0) {
          let response = await axios.get('/validate-user', {
            params: {
              email: email,
              resetPasswordToken: '',
            }
          });
          if (response.data === 'user not found') {
            history.push({
              pathname: '/process-outcome',
              message: "Oops. Something went wrong",
              link: '/register'
            })
          } else {
            const password = response.data[0].password;
            const roleId = response.data[0].role_id;

            // this condition is only true when the user logs in for the first time
            if (password === '') {
              if (passwordLength > 0) {
                let updateRes = await axios.post('/update-password', {
                  email: email,
                  password: userPassword,
                  resetPasswordToken: '',
                });
                if (updateRes.data === 'updated') {
                  if(roleId === 2){
                    localStorage.setItem('isClient', true);
                    localStorage.setItem('clientEmail', email);
                    history.push({
                      pathname: '/dashboard',
                    })
                  } else if(roleId === 3) {
                    localStorage.setItem('isEmployee', true);
                    localStorage.setItem('employeeEmail', email);
                    history.push({
                      pathname: '/dashboard'
                    })
                  }
                }
              } else {
                setErrorStatus(true);
              }
            } else {
              let loginRes = await axios.post('/login', {
                email: email,
                password: userPassword,
              });
              const authenticated = loginRes.data[0].authenticated;

              if (loginRes.data === "user not found") {
                history.push({
                  pathname: '/process-outcome',
                  message: "Oops. Something went wrong",
                  link: '/register'
                })
              } else if (!authenticated) {
                setInvalidPasswordStatus(true);
              } else if (authenticated) {
                setInvalidPasswordStatus(false);
                if (roleId === 2) {
                  localStorage.setItem('isClient', true);
                  localStorage.setItem('clientEmail', email);
                  history.push({
                    pathname: '/dashboard',
                  })
                } else if(roleId === 3){
                  localStorage.setItem('isEmployee', true);
                  localStorage.setItem('employeeEmail', email);
                  history.push({
                    pathname: '/dashboard',
                  })
                }
              }
            }
          }
        } else {
          history.push({
            pathname: '/process-outcome',
            message: "Oops. Something went wrong",
            link: '/register'
          });
        }
      } catch (e) {
        console.log(e.toString());
      }
    }
  }

  const keyPressed = async (event) => {
   if (!errorStatus && !invalidPasswordStatus && event.key === "Enter") {
      event.preventDefault();
      await authenticateUser();
    }
  }

  return (
    <div class="signInMain">
      <div class="signInLeft">
        <img src={test} alt="flowers" />
      </div>
      <div class="signInRight">
        <div class="signInText">
          Sign In
      </div>
        <div class="signInUsername">
          <label class="emailLabel">
            <img class="emailIcon" src={LockIcon} alt="lockIcon" />
            <input
              class="emailInput"
              name="password"
              type="password"
              value={userPassword}
              onChange={handleTextFieldChange}
              placeholder="Password"
              onKeyPress={keyPressed}
            >

            </input>
          </label>
        </div>
        <div class="signInForgotP">
          <Link href="/forgot-password">Forgot password?</Link>
        </div>
        <div class="signInButton" onClick={authenticateUser}>
          <GradientButton primary arrow>
            Sign in
        </GradientButton>
        </div>
      </div>
    </div>
    
    // <div class={classes.form}>
    //   <h1>To proceed, kindly enter your password</h1>
    //   <div class={classes.formControl}>
    //     <TextField
    //       id="outlined-error-helper-text"
    //       required={!errorStatus || !invalidPasswordStatus}
    //       error={errorStatus || invalidPasswordStatus}
    //       hintText="Password"
    //       label="Enter your password"
    //       helperText={errorStatus ? "Password cannot be empty." : invalidPasswordStatus ? "Invalid password" : ""}
    //       onChange={handleTextFieldChange}
    //       variant="outlined"
    //       type="password"
    //       onKeyPress={keyPressed}
    //     />
    //   </div>
    //   <div>
    //     <Button
    //       onClick={authenticateUser}
    //     >Continue</Button>
    //   </div>
    //   <Link href="/forgot-password">Forgot password?</Link>
    // </div>
  );
}

export default LoginPage;