import React, { Component } from 'react';
import { Button } from '@material-ui/core'
import GoogleP from 'react-google-picker';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const DEV_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

class GooglePicker extends Component {
    handleItemSelect = async (data) => {
        if (data.action === "picked") {
            let totalSheet = {};
            let epicsData = await this.getData('Epics', data);
            let sheetNames = this.getSheetNames(epicsData);
            totalSheet['Epics'] = epicsData;
            for (let i = 0; i < sheetNames.length; i++) {
                if (sheetNames[i]['Hours'] !== '' && sheetNames[i]['Hours'].length > 0) {
                    let temp = await this.getData(sheetNames[i]['Name'], data)
                    totalSheet[sheetNames[i]['Name']] = temp;
                }
            }
            this.props.handleSelect(totalSheet);
        }

    }

    getSheetNames = (firstSheetData) => {
        let sheetNames = [];
        for (let i = 0; i < firstSheetData.length; i++){
            if (firstSheetData[i]['Name'] !== '' && firstSheetData[i]['Hours'] !== '') {
                let temp = { 
                    Name: firstSheetData[i]['Name'],
                    Hours: firstSheetData[i]['Hours']
                }
                sheetNames.push(temp);
            }
        }
        return sheetNames;
    }

    getData = async (sheetName, data) => {
        const response = await window.gapi.client.sheets.spreadsheets.values.get({
            spreadsheetId: data.docs[0].id,
            range: `${sheetName}!A:G`,
            majorDimension: 'ROWS'
        });
        let columnRange = response.result.values[0].length;
        let rowRange = response.result.values.length;
        let columnNames = response.result.values[0];
        let rowObjects = []; 
        this.normalizeArray(response.result.values);
        for (let i = 1; i < rowRange; i++) {
            let temp = {};
            for (let j = 0; j < columnRange; j++) {
                let columnName = columnNames[j];
                temp[columnName] = response.result.values[i][j] 
            }
            rowObjects.push(temp);
        }
        return rowObjects;
    }

    normalizeArray = (entry) => {
        for (let i = 1; i < entry.length; i++) {
            if (entry[i].length < entry[0].length) { 
                for (let count = entry[i].length; count < entry[0].length; count++) {
                    entry[i].push("");
                }
            }
        }
    }

    render () {
        return (
            <GoogleP clientId={CLIENT_ID}
             developerKey={DEV_KEY}
             onChange={this.handleItemSelect}
             onAuthFailed={data => console.log('on auth failed:', data)}
             multiselect={true}
             navHidden={true}
             authImmediate={false}
             mimeTypes={['application/vnd.google-apps.spreadsheet']}
             viewId={'DOCS'}>

                <Button variant="contained" color="primary" onClick={(e) => {e.preventDefault()}}>Load in Google Sheet *</Button>
                <div className="google"></div>
            </GoogleP>
        )
    }
}



export default GooglePicker;