import React, { Component } from 'react';
import HarvestProjectForm from '../components/HarvestProjectForm'
import { ApplicationContext } from '../context'
import { flattenDeep } from 'lodash';
class Project extends Component {
    static contextType = ApplicationContext;
    constructor() {
        super();
        this.state = { clientData: [] };
    }

    componentDidMount = async () => {
        this.refreshClients();
    }

    refreshClients = async () => {
        const clientData = await (await fetch('/api/harvest/clients')).json();
        let final = [];
        clientData.forEach(item => {
            final.push(item.clients);
        })
        final = flattenDeep(final);
        this.setState({ clientData: final});
    }


    render() {
            return (
                <div>                    
                    <HarvestProjectForm clientData={this.state.clientData} sheetData={this.state.sheetData} refreshClients={ this.refreshClients } />
                </div>
        )
    }    
}
export default Project;