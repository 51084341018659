import React, { Component } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/';
import TableComponent from '../components/TableComponent';
import Header from '../components/Header';
import { classComponentStyles } from '../styles/styles';

const columns = [
  { id: 'id', label: 'ID', minWidth: 100, format: (value) => value.toLocaleString('en-US'),},
  { id: 'url', label: 'URL', minWidth: 170 },
  { id: 'repo_name', label: 'Name of Github Repo', minWidth: 170 },
  { id: 'status', label: 'Status', minWidth: 100, align: 'right', },
  { id: 'updated_at', label: 'Updated at', minWidth: 150, align: 'right' }
];

const dateColumns = ["updated_at"];

class UrlStatusPage extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      rows: [],
     };
  }

  async componentDidMount() {
    try {
      const response = await axios.get('/urls');
      if(response.data === "no url found"){
        return;
      }
      response.data.forEach(item => {
        this.setState({ rows: [ ...this.state.rows, item ] })
      });

      this.setState({ isLoading: false });
    }
    catch (e){
      console.log(e.toString());
    }
  }
  
  render() {
    const { isLoading, rows } = this.state;
    const { classes } = this.props;
    return (
      (!isLoading)
      ?
      (
        <div class={classes.mainContainer}>
          <Header showLogoutButton title={"URLs and their statuses"}/>
          <TableComponent
            classes={classes}
            columns={columns}
            dateColumns={dateColumns}
            rows={rows}
          />
        </div>
      )
      :
      <div>Loading...</div>
    );
  }
}

export default withStyles(classComponentStyles)(UrlStatusPage);