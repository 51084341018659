export const findTotal = (timeData) => {
    let totalHours = 0;
    for (let i = 0; i < timeData.length; i++) {
        totalHours += timeData[i].hours;
    }
    return totalHours;
}

export const findBillableTotal = (timeData) => {
    let totalHours = 0;
    for (let i = 0; i < timeData.length; i++) {
        if (timeData[i].billable) {
            totalHours += timeData[i].hours;
        }
    }
    return totalHours;
}

export const findNonBillableTotal = (timeData) => {
    let totalHours = 0;
    for (let i = 0; i < timeData.length; i++) {
        if (!timeData[i].billable && timeData[i].project.name !== 'Out of Office') {
            totalHours += timeData[i].hours
        }
    }
    return totalHours;
}

export const findTakenDays = (timeData, type) => {
    let days = 0;
    for (let i = 0; i < timeData.length; i++) {
        if (timeData[i].task.name === type) {
            if (timeData[i].hours === 7) {
                days++;
            } else {
                let temp = timeData[i].hours / 7;
                days += temp;
            }
        }
    }
    return days;
}