import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import '../styles/styles.css';
import GradientButton from './GradientButton';
import WLLogo from '../assets/Logos/WhitelabelW.svg';
import ClockIcon from '../assets/Icons/Clock.svg';
import LuggageIcon from '../assets/Icons/Luggage.svg';
import PollsIcon from '../assets/Icons/Polls.svg';
import ChatIcon from '../assets/Icons/Chat.svg';
import LogoutIcon from '../assets/Icons/Logout.svg';

class Navigation extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div class="Navigation">
                <div class="navContent">
                    <div class="navHeader">
                        <div class="WLLogo">
                            <img src={WLLogo} alt="WLLogo" />
                        </div>
                    </div>
                    <div class="dividerHorizontal"></div>
                    <div class="navItem">
                        <div class="navItemHeader">
                            Work Stuff
                            </div>
                        <div class="navSelect">
                            <div class="navItemIcon">
                                <img src={ClockIcon} alt="ClockIcon" />
                            </div>
                            <div class="navItemText" onClick={() => {
                                window.open('/dashboard/billable-hours-target')
                            }}>
                                Hours
                            </div>
                        </div>
                        {/* <div class="navSelect">
                            <div class="navItemIcon">
                                <img src={LuggageIcon} alt="LuggageIcon" />
                            </div>
                            <div class="navItemText" onClick={() => window.open('/dashboard/employee-time-off')}>
                                Time Off
                            </div>
                        </div> */}
                    </div>
                    <div class="navItem">
                        <div class="navItemHeader">
                            Fun Stuff
                        </div>
                        <div class="navSelect">
                            <div class="tempNavItemText">
                                Coming Soon!
                            </div>
                        </div>
                        {/* <div class="navSelect">
                            <div class="navItemIcon">
                                <img src={PollsIcon} alt="PollsIcon" />
                            </div>
                            <div class="navItemText" onClick={() => window.open('/dashboard/employee-polls')}>
                                Polls
                            </div>
                        </div> */}
                        {/* <div class="navSelect">
                            <div class="navItemIcon">
                                <img src={ChatIcon} alt="ChatIcon" />
                            </div>
                            <div class="navItemText" onClick={() => window.open('/dashboard/employee-watercooler')}>
                                Water Cooler
                            </div>
                        </div> */}
                    </div>
                </div>
                <div class="logout">
                    <div class="dividerHorizontal"></div>
                    <div class="logoutButton" onClick={async () => {
                        localStorage.clear();
                        sessionStorage.clear();
                        window.location = '/logout';
                    }}>
                            <div class="navItemIcon">
                                <img src={LogoutIcon} alt="LogoutIcon" />
                            </div>
                            <div class="navItemText">Logout</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter (Navigation);