import React, { Component, Fragment } from 'react';
import Button from './Button';
import '../styles/styles.css';
import styled from 'styled-components';
import moment from 'moment';


const calendarId = process.env.REACT_APP_GOOGLE_CALENDAR_ID;

const Module = styled.div`
    width: 100%;
    height: 99%;
    ${props => props.authenticated ? `` : `filter: blur(18px);`}
    display: flex;
    flex-direction: column;
`;
class EDashWC extends Component {
    constructor(props) {
        super(props);
        let auth = true;
        if (!window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
            auth = false;
        }
        this.state = {
            authenticated: auth,
            upcomingEvents: []
        }

        this.handleAuth = this.handleAuth.bind(this);
    }
    
    async componentDidMount() {
        let response = await this.getCalander(this.state.authenticated);

        this.setState({
            ...this.state,
            upcomingEvents: response
        })
    }

    async getCalander(auth) {
        if (auth) {
            try {
                let response = await window.gapi.client.calendar.events.list({
                    'calendarId': calendarId,
                    'timeMin': (new Date()).toISOString(),
                    'showDeleted': false,
                    'singleEvents': true,
                    'maxResults': 10,
                    'orderBy': 'startTime'
                });
                response = response.result.items;
                response = response.filter(item => !item.summary.includes('Sprint'));
                let final = [];
                for(let i = 0; i < response.length && i < 2; i++){
                    final.push(response[i]);
                }
                return final;
            } catch (e) {
                console.log(JSON.stringify(e));
            }
        }
    }

    async handleAuth() {
        let auth;
        try {
            await window.gapi.auth2.getAuthInstance().signIn();
            auth = true;
        } catch (e) {
            auth = false;
        }
        let response = await this.getCalander(auth);
        this.setState({
            ...this.state,
            authenticated: auth,
            upcomingEvents: response
        });

    }
    
    parseSummary(summary) {
        let finalString = '';
        if (summary.includes('Holiday')) {
            finalString = summary.split('-')[1].trim()
        } else {
            finalString = summary;
        }
        return finalString;
    }

    parseDate(event) {
        if (moment(event.end.date).diff(moment(event.start.date), 'days') > 1) {
            return (moment(event.start.date).format('MMM DD') + ' - ' + moment(event.end.date).format('MMM DD'));
        } else {
            return (moment(event.start.date).format('MMM DD'));
        }
    }

    render() {
        return (
            <div class="module2">
                <p class="moduleText moduleText2">Events</p>
                <div class="module2Border">

                    {(this.state.authenticated) ? (
                        <Fragment />
                    ) : (
                        <div class="authGoogle" onClick={this.handleAuth}>
                            <Button arrow>Reauthenticate Google</Button>
                        </div>
                    )}

                    <Module authenticated={this.state.authenticated}>
                        {(this.state.upcomingEvents && this.state.upcomingEvents.length > 0) ? (
                            this.state.upcomingEvents.map(item => (
                                <Fragment>
                                    <p class="EventDate">
                                        {this.parseDate(item)}
                                    </p>
                                    <p class="EventDesc">
                                        {this.parseSummary(item.summary)}
                                    </p>
                                </Fragment>
                            ))
                        ) : (
                            <Fragment />
                        )}
                    </Module>
                </div>
            </div>
        )
    }
}

export default EDashWC;