import React from 'react';

const ApplicationContext = React.createContext();
const initialContextString = (typeof window !== "undefined" && window && window.__INITIAL_STATE__) || JSON.stringify({});
const initialContext = JSON.parse(initialContextString);
const ctx = {
  applicationName: 'BI-Tools',
  harvestProjects: null,
  ...initialContext
};

const CtxProvider = ApplicationContext.Provider;
const AppCtxConsumer = ApplicationContext.Consumer;

class AppCtxProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ctx }
  }
  componentDidMount() {
    if (typeof window !== "undefined" && window) console.log(window.__INITIAL_STATE__);
    this.refreshHarvestProjects();
    this.refreshGithubProjects();
    this.refreshRelatedProjects();
    this.refreshGithubRepos();
    // this.setState(initialContextString);
  }

  refreshHarvestProjects = async () => {
    const projectData = await (await (fetch('/api/harvest/active_projects'))).json();
    this.setState({ ...this.state, ctx: { ...this.state.ctx, harvestProjects: projectData } });
  }

  refreshGithubProjects = async () => {
    const projectData = await (await (fetch('/api/github/projects'))).json();
    this.setState({ ...this.state, ctx: { ...this.state.ctx, githubProjects: projectData}});
  }

  refreshRelatedProjects = async () => {
    const data = await (await (fetch('/project-mappings'))).json();
    this.setState({ ...this.state, ctx: { ...this.state.ctx, relatedProjects: data } })
  }

  refreshGithubRepos = async () => {
    const repoData = await (await (fetch('/api/github/repositories'))).json();
    this.setState({ ...this.state, ctx: { ...this.state.ctx, githubRepos: repoData }});
  }
  
  render () {
    return (
      <CtxProvider value={this.state.ctx}>
        {this.props.children}
      </CtxProvider>
    )
  }
}

export { 
    ApplicationContext,
    AppCtxProvider,
    AppCtxConsumer,
}