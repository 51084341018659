import React, { Fragment, Component } from 'react';
import { createBurndownData, createBurndownBarData } from './BurndownPage';
import axios from 'axios';
import BurndownChart from '../components/burndownChart';
import BurndownBar from '../components/BurndownBar';
import moment from 'moment';
import { TextField, Grid, Button } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';

const filter = createFilterOptions();

class ReloadBurndown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            burndownData: {
                totalHours: 1,
                devHourDay: 1,
                date: moment(moment().add(4, 'days')).format('MM/DD/YYYY'),
            },
            projectData: {
                project: {},
                projects: [],
                inputValue: ''
            },
            timeData: [{ spent_date: moment().format('MM/DD/YYYY') }],
            taskData: {
                task: { task: { name: '' }}
            },
            tasks: []
        }
    }

    async componentDidMount() {
        let result = await axios({
            method: 'get',
            url: '/burndown-config',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        this.setState({
            ...this.state,
            projectData: {
                ...this.state.projectData,
                projects: result.data
            }
        })
    }

    handleProjectChange = async (e, nv) => {
        let projectData = await axios({
            method: 'get',
            url: `/api/harvest/project/${nv.projectID}`,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        let taskData = await axios({
            url: '/api/harvest/tasks',
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                id: nv.projectID
            }
        });
        let timeData = await axios({
            url: '/api/harvest/project_time_entries',
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                projectId: nv.projectID
            }
        });
        let configName = this.state.projectData.projects.filter(item => item.name === nv.name);
        let task = null;
        if (nv.taskID && nv.taskID.length > 0) {
            task = taskData.data.task_assignments.filter(item => item.task.id.toString() === nv.taskID);
        }

        if (task && task.length > 0){
            this.setState({
                ...this.state,
                burndownData: {
                    ...this.state.burndownData,
                    totalHours: task[0].budget,
                    devHourDay: nv.devHourDay,
                    date: nv.endDate,
                },
                projectData: {
                    ...this.state.projectData,
                    project: projectData.data,
                    configName: configName[0].name
                },
                taskData: {
                    ...this.state.taskData,
                    task: task[0]
                },
                timeData: timeData.data,
                tasks: taskData.data.task_assignments
            });
        } else {
            this.setState({
                ...this.state,
                burndownData: {
                    ...this.state.burndownData,
                    totalHours: projectData.data.budget,
                    devHourDay: nv.devHourDay,
                    date: nv.endDate,
                },
                projectData: {
                    ...this.state.projectData,
                    project: projectData.data,
                    configName: configName[0].name
                },
                timeData: timeData.data,
                tasks: taskData.data.task_assignments
            });
        }
    }

    render() {
        return (
            <Grid container justify="center" alignItems="center" direction="column" spacing={2}>
                <Grid item xs={12}>
                    <h1>Reload a Burndown Configuration</h1>
                </Grid>
                <Grid item>
                <Autocomplete id="Search Select"
                    options={this.state.projectData.projects}
                    value={this.state.projectData.project}
                    onChange={this.handleProjectChange}
                    getOptionLabel={(option) => option.name}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        return filtered;
                    }}
                    inputValue={this.state.projectData.inputValue}
                    onInputChange={(e, niv) => {
                        this.setState({
                            ...this.state,
                            projectData: {
                                ...this.state.projectData,
                                inputValue: niv
                            }
                        })
                    }}
                    style={{ width: 200 }}
                    renderInput={(params) => <TextField {...params} label="Select A Project" variant="outlined" />}
                    />
                    </Grid>
            
                    <BurndownChart data={createBurndownData(this.state.burndownData.totalHours, this.state.burndownData.date, this.state.burndownData.devHourDay, this.state.timeData, this.state.taskData.task, this.state.projectData.project.starts_on)} configName={this.state.projectData.configName} projectName={this.state.projectData.project.name} taskName={this.state.taskData.task.task.name} />
                    <BurndownBar data={createBurndownBarData(this.state.tasks, this.state.timeData)} projectName={this.state.projectData.project.name} />
            </Grid>
        )
    }
}

export default ReloadBurndown