import React from 'react';
import { useLocation } from "react-router-dom";
import Link from '@material-ui/core/Link';
import { useHistory } from "react-router-dom";
import GradientButton from '../components/GradientButton';

import Header from '../components/Header';
import { statelessComponentStyles } from '../styles/styles';
import flowers from '../assets/Flowers.png';

const ProcessOutcomePage = () => {
  const history = useHistory();
  const classes =  statelessComponentStyles();
  const location = useLocation();
  const message = location.message
  const link = location.link;
  const showLogoutButton = location.showLogoutButton;

  const redirect = () => {
    history.push(link);
  }

  return (
    <div class="signInMain">
      <div class="signInLeft">
        <img src={flowers} alt="flowers" />
      </div>
      <div class="signInRight">
        <div class="processOutcomeText">
          {message}
        </div>
        <div class="processOutcomeButton" onClick={redirect}>
          <GradientButton primary arrow>
            Continue
          </GradientButton>
        </div>
      </div>
    </div>
  )
}

export default ProcessOutcomePage;
