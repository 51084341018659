import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { TextField, Grid, Button } from '@material-ui/core';
import LighthouseTable from '../components/LighthouseTable';

class LighthouseAudit extends Component {
    constructor(){
        super();
        this.state = {
            URL: '',
            URLS: null,
            rows: []
        }
    }

    async componentDidMount() {
        try {
            let response = await axios({
                method: 'get',
                url: '/lighthouse-audits',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (response.data === 'no audits found') {
                this.setState({
                    ...this.state,
                })
            } else {
                this.setState({
                    ...this.state,
                    rows: response.data.resultArr
                })
            }
        } catch (e) {
            console.log(e.toString());
        }
    }

    handleURL = (e) => {
        this.setState({
            ...this.state,
            URL: e.target.value
        });
    }
    
    renderTable = (rows) => {
        if (rows.length > 0) {
            return (
                <LighthouseTable rows={rows} />
            )
        } else {
            return (
                <Fragment />
            )
        }
    }

    render() {
        return (
            <Grid container justify="center" align="center" xs={12} spacing={1}>
                <Grid item justify="center">
                    <h1>Lighthouse Audits</h1>
                </Grid>
                <Grid container justify="center" align="center" alignItems="center" xs={12} spacing={1}>
                    <Grid item>
                        <TextField variant="outlined" label="URL" id="URL" onChange={this.handleURL}>{this.state.URL}</TextField>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={() => {
                            axios({
                                method: 'post',
                                url: `/lighthouse-audit?url=${this.state.URL}`,
                                timeout: 1000 * 60 * 20,
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            });
                            return;
                        }}>Generate Lighthouse Audit</Button>
                    </Grid>
                    {this.renderTable(this.state.rows)}
                </Grid>
            </Grid>
        )
    }
}

export default LighthouseAudit;