import React, { Component } from 'react';
import { ApplicationContext } from '../context'
import GithubProjectForm from '../components/GithubProjectForm';

class Github extends Component {
    static contextType = ApplicationContext;
    constructor() {
        super();
    }

    render() {
        return(
            <div>
                <GithubProjectForm />
            </div>
        )
    }
}

export default Github;