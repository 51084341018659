import React from 'react';
import { useHistory } from "react-router-dom";
import { AppBar, Button, Toolbar, Typography, IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import { statelessComponentStyles } from '../styles/styles';

const Header = ({title, showLogoutButton, showBackButton}) => {
  const classes = statelessComponentStyles();
  const history = useHistory();

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location = '/logout';
  }

  return (
    <AppBar position="static">
      <Toolbar>
          {showBackButton && (<IconButton aria-label="go back" size="large" onClick={() => {
                                    history.push({
                                        pathname: '/dashboard'
                                      })
                                    }}>
                                  <ArrowBackIosIcon />
                                </IconButton>)}
          <Typography variant="h6" className={classes.title}>
              {title}
          </Typography>
          {showLogoutButton && <Button color="inherit" onClick={handleLogout}>Logout</Button>}
      </Toolbar>
    </AppBar>
  );
}

export default Header;