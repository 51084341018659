import moment from 'moment';

const companyHolidays = [
  "1/1/2020", "1/20/2020","3/20/2020","3/23/2020","5/25/2020","7/3/2020", "8/7/2020",
  "8/10/2020", "9/7/2020", "11/11/2020", "11/26/2020", "11/27/2020", "12/24/2020", "12/25/2020"
]

export const convertTimeZone = (date) => {
  return new Date(new Date(date).toLocaleString("en-US", {timeZone: "America/New_York"}))
}

export const calculateVacationTimeUsed = (vacationRequests) => {
  const formattedHolidayDates = companyHolidays.map(holiday => convertTimeZone(holiday));
  let vacationDaysUsed = 0;
  vacationRequests.forEach(request => {
    let startDate = convertTimeZone(request.start_date);
    let endDate = convertTimeZone(request.end_date);
    const startOfYear = moment().startOf('year').toDate();
    const endOfYear = moment().endOf('year').toDate();

    if(startDate < startOfYear && endDate < startOfYear){
      return;
    }
    if (endDate > endOfYear){
      endDate = endOfYear;
    }
    if (startDate < startOfYear && endDate >= startOfYear) {
      startDate = startOfYear;
    }

    let fullCount = 0, paidCount = 0;
    for (var i = 0; i < formattedHolidayDates.length; i++) {
      if(formattedHolidayDates[i] >= startDate && formattedHolidayDates[i] <= endDate) {
        paidCount++;
      }
    }
    while (startDate <= endDate) {
      var day = startDate.getDay();
      if(day !== 0 && day !== 6) {
        fullCount++;
      }
      startDate.setDate(startDate.getDate() + 1);
    }

    vacationDaysUsed += fullCount - paidCount;
  })
 return vacationDaysUsed;
}