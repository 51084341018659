import React, { Component, Fragment } from 'react';
import axios from 'axios';
import moment from 'moment';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { findTakenDays } from '../functions/BillableTargetHoursCalculations';
import Button from './Button.js';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Check from '../assets/Icons/Check.svg'
import '../styles/styles.css';
import { renderEmail } from 'react-html-email';
import RegistrationEmail from './EmailTemplate';

import { MuiThemeProvider } from "@material-ui/core/styles";
import TodayIcon from '@material-ui/icons/Today';
import { createMuiTheme } from '@material-ui/core';
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import styled from 'styled-components';

const Module = styled.div`
    ${props => props.authenticated ? `` : `filter: blur(18px);`}
    width: 100%;
    height: 52%;
    display: flex;
`;


export const customTheme = createMuiTheme({
    palette: {
        background: {
            paper: '#000',
            default: '#000'
        },
        text: {
            primary: '#ffffff',
            secondary: '#ffffff',
            disabled: '#ffffff',
            hint: '#ffffff',
        },
        primary: {
            contrastText: '#000',
            main: '#fff',
            light: '#fff',
            dark: '#000',
        },
        secondary: {
            main: '#fff',
        },
    },
})

class EDashVac extends Component {

    constructor(props) {
        super(props);
        this.state = {
            upcomingDays: [],
            upcomingHoliday: '',
            vacationUsed: 0,
            sickUsed: 0,
            otherUsed: 0,
            requestInputs: false,
            startDate: null,
            focusedInput: null,
            endDate: null,
        }

        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
    }

    async componentDidMount() {
        let today = moment();
        let { timeData } = this.props;
        let vacationUsed, sickUsed, otherUsed = 0;

        try {
            let management = await axios.get('/get-users', {
                params: {
                    roleId: 1
                }
            });
            if (Array.isArray(management.data) && management.data !== "no users found") {
                management = management.data.filter(item => item.email !== 'michael@wlabel.co' && item.email !== 'jake@wlabel.co' && item.email !== 'olu@wlabel.co')
                this.setState({managementEmails: management})
            }
        } catch (e) {
            console.log(e.toString());
        }


        vacationUsed = findTakenDays(timeData, 'Vacation');

        sickUsed = findTakenDays(timeData, 'Sick Day');

        otherUsed = otherUsed +
            findTakenDays(timeData, 'Bereavement Leave') +
            findTakenDays(timeData, 'Parental Leave') +
            findTakenDays(timeData, 'Jury Duty');



        let holidayData = await axios({
            method: 'get',
            url: `/company-holidays?year=${today.year()}`,
            headers: {
                'Content-Type': 'application/json'
            }
        });


        let vacationData = [];

        let approved = await axios.get('/vacation-request', {
            params: {
                email: localStorage.getItem('employeeEmail'),
                status: 'APPROVED'
            }
        });

        let pending = await axios.get('/vacation-request', {
            params: {
                email: localStorage.getItem('employeeEmail'),
                status: 'PENDING'
            }
        });

        if (approved.data != 'no request found') {
            vacationData.push.apply(vacationData, approved.data)
        }
        if (pending.data != 'no request found') {
            vacationData.push.apply(vacationData, pending.data);
        }

        let sortedVacationData = [];
        if (vacationData.length > 0) {
            sortedVacationData = vacationData.sort((a, b) => moment(a.start_date).unix() - moment(b.start_date).unix());
            sortedVacationData = sortedVacationData.filter(item => moment().unix() < moment(item.end_date).unix());
        }

        let upcomingDays = [];
        if (sortedVacationData.length === 1) {
            let dateString = ''
            if (sortedVacationData[0].start_date === sortedVacationData[0].end_date) {
                dateString += `${moment(sortedVacationData[0].start_date).format('MMM DD')}`
            } else {
                dateString += `${moment(sortedVacationData[0].start_date).format('MMM DD')} - ${moment(sortedVacationData[0].end_date).format('MMM DD')}`
            }

            upcomingDays.push({ dateString, status: sortedVacationData[0].status });
        } else if (sortedVacationData.length > 1) {
            let dateString;

            for (let i = 0; i < 2; i++) {
                dateString = '';
                if (sortedVacationData[i].start_date === sortedVacationData[i].end_date) {
                    dateString += `${moment(sortedVacationData[i].start_date).format('MMM DD')}`
                } else {
                    dateString += `${moment(sortedVacationData[i].start_date).format('MMM DD')} - ${moment(sortedVacationData[i].end_date).format('MMM DD')}`
                }

                upcomingDays.push({ dateString, status: sortedVacationData[i].status });
            }
        }


        holidayData = holidayData.data.data;

        let holidayString = '';

        for (let i = 0; holidayString === ''; i++) {
            let current = moment(holidayData[i].date, 'MM/DD/YYYY');
            let next;
            if (i != holidayData.length - 1) {
                next = moment(holidayData[i + 1].date, 'MM/DD/YYYY');
            }
            if (today <= current) {
                switch (current.month()) {
                    case 0:
                        if (current.date() === 1) {
                            holidayString += `New Years Day : ${current.format('MMM')} ${current.format('DD')}`;
                        } else {
                            holidayString += `MLK Day : ${current.format('MMM')} ${current.format('DD')}`;
                        }
                        break;
                    case 2:
                        holidayString += `Spring Break : ${current.format('MMM')} ${current.format('DD')} - ${next.format('MMM')} ${next.format('DD')}`
                        break;
                    case 4:
                        holidayString += `Memorial Day : ${current.format('MMM')} ${current.format('DD')}`
                        break;
                    case 6:
                        holidayString += `Independance Day : ${current.format('MMM')} ${current.format('DD')}`
                        break;
                    case 7:
                        holidayString += `Summer Break : ${current.format('MMM')} ${current.format('DD')} - ${next.format('MMM')} ${next.format('DD')}`
                        break;
                    case 8:
                        holidayString += `Labor Day : ${current.format('MMM')} ${current.format('DD')}`
                        break;
                    case 10:
                        if (current.date() === 11) {
                            holidayString += `Veteran's Day : ${current.format('MMM')} ${current.format('DD')}`;
                        } else {
                            holidayString += `Thanksgiving : ${current.format('MMM')} ${current.format('DD')} - ${next.format('MMM')} ${next.format('DD')}`;
                        }
                        break;
                    case 11:
                        if (current.date() === 31) {
                            holidayString += `New Years Eve : ${current.format('MMM')} ${current.format('dd')}`;
                        } else {
                            holidayString += `Christmas : ${current.format('MMM')} ${current.format('dd')} - ${next.format('MMM')} ${next.format('dd')}`;
                        }
                        break;
                }
            }
        }



        this.setState({
            upcomingHoliday: holidayString,
            vacationUsed,
            sickUsed,
            otherUsed,
            upcomingDays
        })
    }

    renderRequests() {
        if (this.state.upcomingDays.length === 1) {
            return (
                <div class="vacationRequest">
                    <p class="vacDate">{this.state.upcomingDays[0].dateString}</p>
                    {(this.state.upcomingDays[0].status === "APPROVED") ? (
                        <div class="vacStatus">
                            <img class="checkMark2" src={Check} alt="Check" />
                            <p class="dayName">Approved</p>
                        </div>
                    ) : (
                        <div class="vacStatus">
                            <p class="dayName">?  Pending</p>
                        </div>
                    )
                    }
                </div>
            )
        } else if (this.state.upcomingDays.length > 1) {
            return (
                <Fragment>

                    <div class="vacationRequest">
                        <p class="vacDate">{this.state.upcomingDays[0].dateString}</p>
                        {(this.state.upcomingDays[0].status === "APPROVED") ? (
                            <div class="vacStatus">
                                <img class="checkMark2" src={Check} alt="Check" />
                                <p class="dayName">Approved</p>
                            </div>
                        ) : (
                            <div class="vacStatus">
                                <p class="dayName">?  Pending</p>
                            </div>
                        )
                        }
                    </div>
                    <div class="vacationRequest">
                        <p class="vacDate">{this.state.upcomingDays[1].dateString}</p>
                        {(this.state.upcomingDays[1].status === "APPROVED") ? (
                            <div class="vacStatus">
                                <img class="checkMark2" src={Check} alt="Check" />
                                <p class="dayName">Approved</p>
                            </div>
                        ) : (
                            <div class="vacStatus">
                                <p class="dayName">?  Pending</p>
                            </div>
                        )
                        }
                    </div>
                </Fragment>
            )
        } else {
            return (
                <div class="emptyRequest">Nothing to show here</div>
            )
        }
    }

    showInputs() {
        this.setState({
            ...this.state,
            requestInputs: true,
        })
    }

    handleStartDateChange(date) {
        this.setState({
            ...this.state,
            startDate: date
        })
    }

    handleEndDateChange(date) {
        this.setState({
            ...this.state,
            endDate: date
        })
    }

    async sendVacationRequest() {
        try {
            const { startDate, endDate, managementEmails } = this.state;
            const email = localStorage.getItem('employeeEmail');
            let response = await axios.post('/submit-vacation-request', {
                email: email,
                startDate: moment(startDate).startOf('day'),
                endDate: endDate === null ? moment(startDate).startOf('day') : moment(endDate).endOf('day'),
            });
            if (response.data === 'request submitted successfully') {
                const message = `Hi, <br>A new request for time off has been submitted by ${email}`
                const messageHtml = renderEmail(<RegistrationEmail message={message} />);
                managementEmails.forEach(async (management) => {
                    await axios({
                        method: "POST",
                        url: "/send-email",
                        data: {
                            receiverEmail: management.email,
                            messageHtml: messageHtml,
                            senderEmail: email,
                            subject: `NEW REQUEST FOR TIME OFF`
                        }
                    })
                })
                alert("Request was submitted successfully");
                this.setState({ ...this.state, startDate: null, endDate: null })
                this.forceUpdate();
            }
        }
        catch (error) {
            console.log(error.toString());
        }
    }

    closeRequest() {
        this.setState({
            ...this.state,
            requestInputs: false,
        })
    }

    findDays = (timeData, type) => {
        return findTakenDays(timeData, type);
    }

    render() {
        let authenticated = this.props.authenticated
        return (
            <div class="module1Vac">
                <p class="moduleText moduleText2">Vacation</p>
                <div class="moduleContainer1">
                    <div class="vacUpcoming">
                        <div class="vacDays">
                            <p class="dayName vacText">Upcoming</p>
                            {(this.state) ? this.renderRequests() : <Fragment />}
                        </div>
                        <div class="dividerHorizontal" />
                        <div class="vacOuterButton">
                            {(this.state.requestInputs) ? (
                                <div class="requestInputs">
                                    <MuiThemeProvider theme={customTheme} >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <div class="inputContainer">
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    autoOk={true}
                                                    leftArrowIcon={<KeyboardArrowLeft style={{"fill": "#ffffff"}} />}
                                                    rightArrowIcon={<KeyboardArrowRight style={{"fill": "#ffffff"}} />}
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    id="start-date"
                                                    label="Start Date"
                                                    value={this.state.startDate}
                                                    onChange={this.handleStartDateChange}
                                                    keyboardIcon={<TodayIcon style={{ fill: "#ffffff" }} />}
                                                />
                                                <KeyboardDatePicker
                                                    classes="datePicker"
                                                    disableToolbar
                                                    autoOk={true}
                                                    leftArrowIcon={<KeyboardArrowLeft style={{"fill": "#ffffff"}} />}
                                                    rightArrowIcon={<KeyboardArrowRight style={{"fill": "#ffffff"}} />}
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    id="end-date"
                                                    label="End Date"
                                                    value={this.state.endDate}
                                                    onChange={this.handleEndDateChange}
                                                    keyboardIcon={<TodayIcon style={{ fill: "#ffffff" }} />}
                                                />
                                                <div class="requestButtons">
                                                    <div class="requestButton1" onClick={this.closeRequest.bind(this)}>
                                                        <Button primary>
                                                            Close
                                                        </Button>
                                                    </div>
                                                    <div class="requestButton2" onClick={this.sendVacationRequest.bind(this)}>
                                                        <Button primary>
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </MuiPickersUtilsProvider>
                                    </MuiThemeProvider>
                                </div>
                            ) : (
                                <div class="vacButton" onClick={this.showInputs.bind(this)}>
                                    <Button primary plus>Request</Button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div class="dividerVertical" />
                    <div class="vacContainer">
                        <div class="moduleBorderVac">
                            <Module authenticated={authenticated}>
                                <div class="dayContainer1">
                                    <p class="takenDays">
                                        Vacation Days Taken
                                </p>
                                    <p class="remainingNumber">
                                        {(this.state) ? (this.findDays(this.props.timeData, 'Vacation')).toFixed(1) : '0'}
                                    </p>
                                </div>
                                <div class="dayContainer2">
                                    <p class="takenDays">
                                        Sick Days Taken
                                </p>
                                    <p class="remainingNumber">
                                        {(this.state) ? (this.findDays(this.props.timeData, 'Sick Day')).toFixed(1) : '0'}
                                    </p>
                                </div>
                                <div class="dayContainer2">
                                    <p class="takenDays">
                                        Other Days Taken
                                </p>
                                    <p class="remainingNumber">
                                        {(this.state) ?
                                            (this.findDays(this.props.timeData, 'Bereavement Leave') +
                                            this.findDays(this.props.timeData, 'Parental Leave') +
                                            this.findDays(this.props.timeData, 'Jury Duty')).toFixed(1)
                                            :
                                            '0'
                                        }
                                    </p>
                                </div>
                            </Module>
                        </div>
                        <div class="dividerHorizontal" />
                        <div class="upcomingHoliday">
                            <p class="PollsName">Upcoming Holiday</p>
                            <p class="PollsQuestion">
                                {(this.state) ? this.state.upcomingHoliday : 'loading'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default EDashVac;