import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Check from '../assets/Icons/Check.svg'
import ArrowRight from '../assets/Icon/ChevronRightBlack.svg';
import '../styles/styles.css';

const GButton = styled.button`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-image: ${props => props.primary ? ('linear-gradient(75deg, #31ff98 -59%, #3fd0f2 98%)') : ('linear-gradient(72deg, #3fd0f2 1%, #9358f5 98%)')};
    border: solid 1px #2b2b2d;
    flex-direction: row;
    margin: ${props => props.margin || '0'};
    &:hover{
        cursor: pointer;
    }
`;

const ButtonText = styled.p`
    font-family: 'Work Sans';
    font-size: 15px;
    color: #000000;
    align-self: center;
    margin: 0 0 0 2%;
`;

class GradientButton extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { margin, children, primary = false, arrow = false } = this.props;
        return(
            <GButton margin={margin} primary={primary}>
                <ButtonText>
                    {children}
                </ButtonText>
                {(arrow) ? (<img class="forwardArrow2" src={ArrowRight} alt="arrow" />) : (<Fragment />)}
            </GButton>
        )
    }
}

export default GradientButton;
