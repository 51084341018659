import React, {Component} from 'react';
import { renderEmail } from 'react-html-email';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { Button, createMuiTheme, Collapse, FormControl, FormHelperText, InputLabel, Select, TextField, ThemeProvider, withStyles, Table, TableBody, TableRow, TableHead, TableCell, Paper } from '@material-ui/core';

import AuthorizationForm from '../components/AuthorizationForm';
import RegistrationEmail from '../components/EmailTemplate';
import Header from '../components/Header';

import { classComponentStyles } from '../styles/styles';

const GET_LIST_OF_REPOSITORIES = `
  query (
    $organization: String!,
    $cursor: String
  ) {
      organization(login: $organization){
        repositories(first:100, after: $cursor){
          totalCount
          edges{
            node{
              name
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  `;

const axiosGitHubGraphQL = axios.create({
  baseURL: 'https://api.github.com/graphql',
  headers: {
    Authorization: `bearer ${process.env.REACT_APP_GITHUB_ACCESS_TOKEN}`
  }
});

const theme =  createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        marginTop: "1rem",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "15px",
      }
    }
  }
});

class ProcessAccessRequestPage extends Component {
  constructor() {
    super();
    this.state = {
      emailAddress: '',
      error: false,
      expandGitHubRepoField: false,
      isLoading: true,
      registrationStatus: {'pending': 'Pending', 'approved': 'Approved', 'denied': 'Denied'},
      repositories: [],
      requestStatus: 'pending',
      roleId: '0',
      roles: {1: 'Management', 2: 'Client', 3: 'Employee'},
      selectedRepo: [],
      requests: [],
     };
  }

  async componentDidMount() {
    try {
      const organization = "whitelabelco";
      let repositories = [];
      const urlParams = new URLSearchParams(window.location.search);
      const emailAddress = urlParams.get('email');
      const response = await axiosGitHubGraphQL.post('', {
        query: GET_LIST_OF_REPOSITORIES,
        variables: { organization },
      });
      let errors = response.data.errors;
      if (!errors){
        let hasNextPage = response.data.data.organization.repositories.pageInfo.hasNextPage;
        let cursor = response.data.data.organization.repositories.pageInfo.endCursor;
        repositories = response.data.data.organization.repositories.edges.map(edge => edge.node.name);
        while (hasNextPage){
          const response = await axiosGitHubGraphQL.post('', {
            query: GET_LIST_OF_REPOSITORIES,
            variables: { organization, cursor },
          });
          errors = response.data.errors;
          if(!errors){
            const moreRepositories = response.data.data.organization.repositories.edges.map(edge => edge.node.name);
            hasNextPage = response.data.data.organization.repositories.pageInfo.hasNextPage;
            cursor = response.data.data.organization.repositories.pageInfo.endCursor;
            repositories = [...repositories, ...moreRepositories];
          } else {
            hasNextPage = false;
          }
        }
      }

      let requests = await axios({
        method: 'get',
        url: '/user-statuses',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      requests = requests.data
      
      this.setState({ emailAddress, isLoading: false, repositories, requests })
    }
    catch (e){
      console.log(e.toString());
    }
  }

  handleStatusChange = (event) => {
    this.setState({ requestStatus: event.target.value});
  };

  handleRoleChange = (event) => {
    const value = event.target.value;
    if (value === '2'){
      this.setState({roleId: value, expandGitHubRepoField: true})
    } else {
      this.setState({roleId: value, expandGitHubRepoField: false})
    }
  };

  handleGitHubRepoSelect = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ selectedRepo: value });
  }

  handleEmailChange = (event) => {
    const EMAIL_REGEX = /^\w+([.\-+]\w+)*@\w+([.\-]\w+)*\.\w{2,3}$/;
    const email = event.target.value;

    if(email.match(EMAIL_REGEX)){
      this.setState({emailAddress: email, error: false});
    } else{
      this.setState({error: true});
    }
  }

  handleTableRender = (requests) => {
    if (requests.length > 0) {
      return (
        <Table classcomponent={Paper}>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map(item => (
              <TableRow>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.request_status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )
    }
  }

  processRequest = async () => {
    try {
      const { emailAddress, selectedRepo, requestStatus, roleId } = this.state;
      const { history } = this.props;
      let response = await axios({
        method: "POST",
        url: "/process-request",
        data: { email: emailAddress, requestStatus, roleId, selectedRepo }
      });
      if (response.data === 'registration processed successfully') {
        if (requestStatus === 'approved' || requestStatus === 'denied') {
          const message = `This is to inform you that your request to access Bi-tools has been ${requestStatus}.`
          const messageHtml = renderEmail(<RegistrationEmail message={message} />);

          axios({
            method: "POST",
            url: "/send-email",
            data: {
              receiverEmail: emailAddress,
              messageHtml: messageHtml,
              senderEmail: 'noreply@wlabel.co',
              subject: `Bi-tools application ${requestStatus}`
            }
          })
        }
        history.push({
          pathname: '/process-outcome',
          message: "Application was processed successfully",
          link: '/',
          showLogoutButton: true,
        })
      } else {
        history.push({
          pathname: '/process-outcome',
          message: "Oops. Something went wrong",
          link: '/',
          showLogoutButton: true,
        })
      }
    } catch (e) {
      console.log(e.toString());
    }
  }

  render(){
    const { classes } = this.props;
    const {
      emailAddress,
      error,
      expandGitHubRepoField,
      isLoading,
      registrationStatus,
      repositories,
      requestStatus,
      roleId,
      roles,
      selectedRepo,
    } = this.state;

    return (
      (!isLoading)
      ?
      (<div className={classes.root}>
        <Header showLogoutButton showBackButton title="Process Access Requests"/>
        <div className={classes.selectContainer}>
          <ThemeProvider theme={theme}>
            <TextField
              error={error}
              label="Requestor's email address"
              defaultValue={emailAddress}
              helperText={error ? "Invalid email" : ""}
              onChange={this.handleEmailChange}
              style = {{width: '16rem'}}
              variant="outlined"
            />
            <AuthorizationForm
              status={requestStatus}
              handleChange={this.handleStatusChange}
              menuItems={registrationStatus}
              inputLabel={'Status'}
            />
            <AuthorizationForm
              status={roleId}
              handleChange={this.handleRoleChange}
              menuItems={roles}
              inputLabel={'Role'}
            />
            <Collapse in={expandGitHubRepoField} timeout="auto" unmountOnExit>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="select-multiple-native">
                  <strong>Name(s) of Client's Github Repo(s)</strong>
                </InputLabel>
                <Select
                  multiple
                  native
                  value={selectedRepo}
                  onChange={this.handleGitHubRepoSelect}
                  inputProps={{
                    id: 'select-multiple-native',
                  }}
                >
                  {repositories.map((repository) => (
                    <option key={repository} value={repository}>
                      {repository}
                    </option>
                  ))}
                </Select>
                <FormHelperText>You can select multiple repos if necessary</FormHelperText>
              </FormControl>
            </Collapse>
            <Button className={classes.processButton} onClick={this.processRequest}>Process</Button>
            {this.handleTableRender(this.state.requests)}
          </ThemeProvider>
        </div>
      </div>
      ) : (
        <div>Loading...</div>
      )
    );
  }
}

export default withStyles(classComponentStyles)(withRouter(ProcessAccessRequestPage));