import React, { Component, Fragment } from 'react';
import '../styles/styles.css'


class Announcement extends Component {
    constructor() {
        super();
        this.state = {
            show: true
        }
    }

    render(){
        let show = this.state.show;
        return (
            (show) ? (
                <div class="Announcement">
                    <div class="AnnouncementContainer">
                        <div class="AnnouncementText">
                            <p>• Welcome to ML-E, your personalized Whitelabel dashboard, feedback encouraged! •</p>
                        </div>
                        <div class="AnnouncementMask">
    
                        </div>
                    </div>
                    <div class="AnnouncementClose">
                        <div class="AnnouncementCloseOval" onClick={() => this.setState({ show: false })}>
                            x
                        </div>
                    </div>
                </div>
            ) : (
                <Fragment />
                )
    
        )
    }
}

export default Announcement
