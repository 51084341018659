import React, { Component, Fragment } from 'react';
import { Button, Grid } from '@material-ui/core';
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, Label, ReferenceLine } from 'recharts';
import domtoimage from 'dom-to-image';
import fileDownload from "js-file-download";
import moment from 'moment';


class BurndownChart extends Component {
    constructor(props) {
        super(props);
    }

    handleDevs = (devs) => {
        let temp = Math.floor(devs);
        if (devs > temp && devs < temp + 0.5) {
            return temp + 0.5;
        } else if (devs > temp + 0.5 && devs < temp + 1) {
            return temp + 1;
        }
    }
    
    handleSaveClick = () => {
        domtoimage.toBlob(document.getElementById('burndown'), { bgcolor: '#FFFFFF' })
            .then(function (blob) {
                fileDownload(blob, `Burndown-Chart.png`);
            });
    }

    chartTitle = (projectName, taskName, configName) => {
        if (configName) {
            return (
                <h3 style={{ textAlign: "center" }}>{configName}</h3>
            )
        } else if (projectName && !taskName && !configName) {
            return (
                <h3 style={{ textAlign: "center" }}>{projectName}</h3>
            )
        } else if (projectName && taskName && !configName) {
            return (
                <h3 style={{ textAlign: "center" }}>{taskName}</h3>
            )
        } else {
            return (
                <Fragment />
            )
        }
    }

    handleSaveClick = () => {
        domtoimage.toBlob(document.getElementById('burndown'), { bgcolor: '#FFFFFF' })
            .then(function (blob) {
                fileDownload(blob, `Burndown-Chart.png`);
            });
    }

    render() {
        return (
            <Fragment>
                <Grid container direction="column" justify="center" alignItems="center">
                    <div id="burndown" style={{ paddingBottom: "20px"}}>
                        <Grid item>
                            {this.chartTitle(this.props.projectName, this.props.taskName, this.props.configName)}
                        </Grid>
                        <Grid item>
                            <h3 style={{ textAlign: "center" }}>Number of Developers: {this.handleDevs(this.props.data.devs)}</h3>
                        </Grid>
                        <Grid item>
                            <LineChart width={850} height={350} data={this.props.data.timeData}
                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="time" />
                                <YAxis>
                                    <Label value="Hours" position="left" angle={-90} />
                                </YAxis>
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="estimate" dot={false} stroke="#0000FF" />
                                <Line type="monotone" dataKey="actual" dot={false} stroke="#FF0041" />
                                <ReferenceLine x={moment().format('MM/DD/YYYY')} stroke="green" strokeDasharray="3 3"><Label angle={-90}>Today</Label></ReferenceLine>
                            </LineChart>
                        </Grid>
                    </div>
                </Grid>
                <Grid item>
                    <Button onClick={this.handleSaveClick}>
                        Save
                    </Button>
                </Grid>
            </Fragment>
        )
    }
}

export default BurndownChart;