import React, { Component, Fragment } from 'react';
import axios from 'axios';
import 'date-fns';
import { formatISO } from 'date-fns';
import { Button, TextField, MenuItem, Grid, Select, InputLabel, Input, FormControl, CircularProgress } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import GooglePicker from './GooglePicker';
import { ApplicationContext } from '../context'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import SheetTable from './SheetTable';

const filter = createFilterOptions();

const tomorrow = new Date();
tomorrow.setDate(new Date().getDate() + 1)

class HarvestProjectForm extends Component {
    static contextType = ApplicationContext;
    constructor() {
        super();
        this.state = {
            selection: {
                value: '',
                inputValue: ''
            },
            body: {
                alignment: 0,
                QA: 0,
                project: {
                    'client_id': 0,
                    'name': '',
                    'is_billable': true,
                    'bill_by': 'Project',
                    'budget_by': 'task',
                    'notify_when_over_budget': true,
                    'over_budget_notification_percentage': 80.0,
                    'starts_on': formatISO(new Date()),
                    'ends_on': formatISO(tomorrow),
                    'is_fixed_fee': false,
                    'hourly_rate': null,
                    'fee': null
                },
                sheets: null
            }
        }

    }

    handleSelectChange = (e, nv) => {
        if (nv.id === 'NEW_ID') {
            axios({
                url: '/api/harvest/clients',
                method: 'post',
                data: {
                    'name': nv.name.split("Add ")[1]
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(() => {
                this.props.refreshClients();
            })
        }
        this.setState({
            selection: {
                ...this.state.selection,
                value: nv
            },
            body: {
                ...this.state.body,
                project: { ...this.state.body.project, 'client_id': nv.id }
            }
        });
    }

    handleNameChange = (e) => {
        this.setState({
            selection: {
                ...this.state.selection
            },
            body: {
                ...this.state.body,
                project: { ...this.state.body.project, 'name': e.target.value }
            }
        });
    }

    handleCodeChange = (e) => {
        this.setState({
            selection: {
                ...this.state.selection
            },
            body: {
                ...this.state.body,
                project: { ...this.state.body.project, 'code': e.target.value }
            }
        });
    }

    handleNoteChange = (e) => {
        this.setState({
            selection: {
                ...this.state.selection
            },
            body: {
                ...this.state.body,
                project: { ...this.state.body.project, 'notes': e.target.value }
            }
        });
    }

    handleStartDate = (e) => {
        try {
            const formatDate = formatISO(e)
            this.setState({ ...this.state, body: { ...this.state.body, project: { ...this.state.body.project, 'starts_on': formatDate } } });
        } catch (error) {
            this.setState({ ...this.state, body: { ...this.state.body, project: { ...this.state.body.project, 'starts_on': e } } });
        }
    }

    handleEndDate = (e) => {
        try {
            const formatDate = formatISO(e)
            this.setState({ ...this.state, body: { ...this.state.body, project: { ...this.state.body.project, 'ends_on': formatDate } } });
        } catch (error) {
            this.setState({ ...this.state, body: { ...this.state.body, project: { ...this.state.body.project, 'ends_on': e } } });
        }
    }

    handleSheetSelect = (sheet) => {
        this.setState({ ...this.state, body: { ...this.state.body, sheets: sheet } });
    }

    handleTypeChange = (e) => {
        this.setState({ ...this.state, body: { ...this.state.body, project: { ...this.state.body.project, 'is_fixed_fee': e.target.value } } })
    }

    handleRateChange = (e) => {
        this.setState({ ...this.state, body: { ...this.state.body, project: { ...this.state.body.project, 'hourly_rate': parseFloat(e.target.value) } } })
    }

    handleFeeChange = (e) => {
        this.setState({ ...this.state, body: { ...this.state.body, project: { ...this.state.body.project, 'fee': parseFloat(e.target.value) } } })
    }

    rateOrFee = () => {
        if (!this.state.body.project.is_fixed_fee) {
            return (
                <TextField key="hourly" id="standard-basic" label="Hourly Rate" variant="standard" onChange={this.handleRateChange} value={this.state.body.project.hourly_rate}></TextField>
            )
        } else {
            return (
                <TextField key="fixed" id='standard-basic' label="Fixed Fee" variant="standard" onChange={this.handleFeeChange} value={this.state.body.project.fee}></TextField>
            )
        }
    }

    handleDisable = () => {
        if (this.state.body.project.client_id !== null && this.state.body.project.name !== "" && this.state.body.sheets != null) {
            return false
        } else {
            return true
        }
    }

    handleAlignmentChange = (e) => {
        this.setState({ ...this.state, body: { ...this.state.body, alignment: e.target.value } })
    }

    handleQAChange = (e) => {
        this.setState({ ...this.state, body: { ...this.state.body, QA: e.target.value } })
    }

    renderTable = (sheetData) => {
        if (sheetData !== null) {
            return (
                <SheetTable data={sheetData} />
            )
        } else {
            return (
                <Fragment />
            )
        }
    }

    render() {
        return (
            <Grid
                direction="row"
                justify="center"
                alignItems="center"
                container
                spacing={1} xs={12}>
                <Grid item xs={5}>
                    <h1>Create a Harvest Project</h1>
                </Grid>
                <Grid
                    direction="row"
                    justify="center"
                    alignItems="center"
                    container
                    spacing={1}
                    xs={8}
                >
                    <Grid item xs={4}>

                        <Autocomplete id="Search Select"
                            options={this.props.clientData}
                            value={this.state.selection.value}
                            onChange={this.handleSelectChange}
                            getOptionLabel={(option) => option.name}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                if (filtered.length === 0) {
                                    filtered.push({
                                        'id': 'NEW_ID',
                                        'name': `Add ${this.state.selection.inputValue}`
                                    })
                                }
                                return filtered;
                            }}
                            inputValue={this.state.selection.inputValue}
                            onInputChange={(e, niv) => {
                                if (niv.includes('Add')) {
                                    this.setState({
                                        selection: {
                                            ...this.state.selection,
                                            inputValue: niv.split('Add ')[1]
                                        }
                                    })
                                } else {
                                    this.setState({
                                        selection: {
                                            ...this.state.selection,
                                            inputValue: niv
                                        }
                                    })
                                }
                            }}
                            style={{ width: 200 }}
                            renderInput={(params) => <TextField {...params} label="Select A Client *" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="outlined-basic-name" label="Project Name *" variant="outlined" onChange={this.handleNameChange}>{this.state.body.project.name}</TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="standard-basic-code" label="Project Code" variant="standard" onChange={this.handleCodeChange}>{this.state.body.project.code}</TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="standard-multiline-flexible" label="Notes" multiline rowsMax={20} onChange={this.handleNoteChange}>{this.state.body.project.notes}</TextField>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl>
                            <InputLabel>Project Type</InputLabel>
                            <Select
                                labelId="Project-Type"
                                id="Project-Type"
                                value={this.state.body.project.is_fixed_fee}
                                onChange={this.handleTypeChange}
                                input={<Input />}
                            >
                                <MenuItem value={false}>Time & Materials</MenuItem>
                                <MenuItem value={true}>Fixed Fee</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        {this.rateOrFee()}
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="standard-basic-alignment" label="Alignment Percent" variant="standard" onChange={this.handleAlignmentChange}>{this.state.body.alignment}</TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="standard-basic-QA" label="QA Percent" variant="standard" onChange={this.handleQAChange}>{this.state.body.QA}</TextField>
                    </Grid>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={4}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                id="date-picker-start"
                                label="Start Date (MM/DD/YYYY)"
                                value={this.state.body.project.starts_on}
                                onChange={this.handleStartDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                variant="inline"
                                id="date-picker-end"
                                label="End Date (MM/DD/YYYY)"
                                format="MM/dd/yyyy"
                                value={this.state.body.project.ends_on}
                                onChange={this.handleEndDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                    <Grid justify="center" item xs={6}>
                        <GooglePicker handleSelect={this.handleSheetSelect} />
                    </Grid>

                    <Grid item={12}>
                        {this.renderTable(this.state.body.sheets)}
                    </Grid>

                    <Grid justify="center" item xs={6}>
                        <Button disabled={this.handleDisable()} variant="contained" color="secondary" onClick={() => {
                            this.setState({...this.state, loading: true}, () => {
                                axios({
                                    url: '/api/harvest/projects',
                                    method: 'post',
                                    data: this.state.body,
                                    sheets: this.state.sheetData,
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                }).then(result => this.setState({
                                    ...this.state,
                                    loading: false
                                }))
                            })
                        }}>Create Project</Button>
                    </Grid>
                    <Grid container justify="center" direction="row" alignItems="center" xs={12}>
                        <Grid justify="center" item xs={4}>
                            {this.state.loading ? <CircularProgress align="center" /> : <Fragment />}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default HarvestProjectForm;