import React, { Component, Fragment } from 'react';
import axios from 'axios';
import {
    Grid,
    Button,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Table,
    TableContainer,
    Paper,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';
import Header from '../components/Header';
import moment from 'moment';
import lodash from 'lodash';
import YearlyBreakdown from '../components/YearlyBreakdown';
import QuarterlyBreakdown from '../components/QuarterlyBreakdown';
import AdminBillableHoursTargetPage from './AdminBillableHoursTargetPage';
import EmployeeBillableHoursTargetPage from './EmployeeBillableHoursTargetPage';

const years = lodash.range(2020, 2100);

class BillableHoursTargetPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            role: -1,
        }
    }

    componentDidMount() {
        let userRole = -1;
        if (localStorage.getItem('isAdmin')) {
            userRole = 0;
        } else if (localStorage.getItem('isEmployee')) {
            userRole = 1;
        } else {
            userRole = 2
        }
        this.setState({
            ...this.state,
            role: userRole
        });
    }

    render() {
        return (
            <Fragment>
                {(this.state.role === 0) ? 
                    <AdminBillableHoursTargetPage /> : 
                    (this.state.role === 1 ? 
                        <EmployeeBillableHoursTargetPage /> :
                        <h1>403 Forbidden</h1>
                    )
                } 
            </Fragment>
        )
    }
}

export default BillableHoursTargetPage;