import React, { Component, Fragment } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from 'react-router-dom';
import { AppCtxConsumer } from '../context'

import PersistentDrawerLeft from '../components/PersistentDrawer';
import { statelessComponentStyles } from '../styles/styles';
import '../styles/styles.css';

import GithubPage from './GithubPage';
import HarvestPage from './ProjectPage';
import BurndownPage from './BurndownPage';
import ReloadBurndownPage from './ReloadBurndownPage';
import Dashboard from './DashboardPage';
import ProjectMapper from './ProjectMappingPage'
import LightHouse from './LighthouseAuditPage';
import GanttChart from './GanttChartPage';
import AuditDisplayPage from './AuditDisplayPage';
import BillableHoursTargetPage from './BillableHoursTargetPage';
import EmployeeTimeOff from './EmployeeTimeOff';
import EmployeePolls from './EmployeePolls';
import EmployeeWaterCooler from './EmployeeWaterCooler';
class Home extends Component {
    constructor(props) {
        super(props);
    }

    

    render() {
        
        const isEmployee = localStorage.getItem('isEmployee');
        return(
        
        <AppCtxConsumer>
            {(context) => {
                if (isEmployee) {
                    return (
                        <div class="root">
                            <Switch>
                                <Route exact path={this.props.match.path} component={Dashboard} />
                                <Route path={`${this.props.match.path}/harvest-project`} component={HarvestPage} />
                                <Route path={`${this.props.match.path}/github-project`} component={GithubPage} />
                                <Route path={`${this.props.match.path}/create-burndown`} component={BurndownPage} />
                                <Route path={`${this.props.match.path}/reload-burndown`} component={ReloadBurndownPage} />
                                <Route path={`${this.props.match.path}/project-mapper`} component={ProjectMapper} />
                                <Route path={`${this.props.match.path}/lighthouse-audit/:id/:auditType`} component={AuditDisplayPage} />
                                <Route exact path={`${this.props.match.path}/lighthouse-audit`} component={LightHouse} />
                                <Route path={`${this.props.match.path}/gantt-chart`} component={GanttChart} />
                                <Route path={`${this.props.match.path}/billable-hours-target`} component={BillableHoursTargetPage} />
                                <Route path={`${this.props.match.path}/employee-time-off`} component={EmployeeTimeOff} />
                                <Route path={`${this.props.match.path}/employee-polls`} component={EmployeePolls} />
                                <Route path={`${this.props.match.path}/employee-watercooler`} component={EmployeeWaterCooler} />
                            </Switch>
                        </div>
                    )
                } else {
                    return (
                        <PersistentDrawerLeft history={this.props.history}>
                        <div class="root">
                            <Switch>
                                <Route exact path={this.props.match.path} component={Dashboard} />
                                <Route path={`${this.props.match.path}/harvest-project`} component={HarvestPage} />
                                <Route path={`${this.props.match.path}/github-project`} component={GithubPage} />
                                <Route path={`${this.props.match.path}/create-burndown`} component={BurndownPage} />
                                <Route path={`${this.props.match.path}/reload-burndown`} component={ReloadBurndownPage} />
                                <Route path={`${this.props.match.path}/project-mapper`} component={ProjectMapper} />
                                <Route path={`${this.props.match.path}/lighthouse-audit/:id/:auditType`} component={AuditDisplayPage} />
                                <Route exact path={`${this.props.match.path}/lighthouse-audit`} component={LightHouse} />
                                <Route path={`${this.props.match.path}/gantt-chart`} component={GanttChart} />
                                <Route path={`${this.props.match.path}/billable-hours-target`} component={BillableHoursTargetPage} />
                                <Route path={`${this.props.match.path}/employee-time-off`} component={EmployeeTimeOff} />
                                <Route path={`${this.props.match.path}/employee-polls`} component={EmployeePolls} />
                                <Route path={`${this.props.match.path}/employee-watercooler`} component={EmployeeWaterCooler} />
                            </Switch>
                        </div>
                    </PersistentDrawerLeft>
                    )
                }
            }}
        </AppCtxConsumer>
        )
    }
}

export { Home };