import React, { Component, Fragment } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Chart } from 'react-google-charts';
import { AppCtxConsumer } from '../context'
import { TextField, Grid, Button } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import domtoimage from 'dom-to-image';
import fileDownload from 'js-file-download';
//import { exportComponentAsJPEG, exportComponentAsPNG, exportComponentAsPDF} from '../lib/index';

const filter = createFilterOptions();

class GanttChart extends Component {
    constructor() {
        super();
        this.chartRef = React.createRef();
        this.state = {
            githubProjectData: {
                project: {},
                inputValue: ''
            },
            chartData: {
                data: null
            }
        }
    }

    parseIssues = (issues) => {
        let hourMap = {
            XS: 3,
            Small: 6,
            Medium: 12,
            Large: 24,
            XL: 40,
            Jumbo: 80
        }

        let dataArr = [[
            { type: 'string', label: 'Task ID' },
            { type: 'string', label: 'Task Name' },
            { type: 'string', label: 'Milestone' },
            { type: 'date', label: 'Start Date' },
            { type: 'date', label: 'End Date' },
            { type: 'number', label: 'Duration' },
            { type: 'number', label: 'Percent Complete' },
            { type: 'string', label: 'Dependencies' }
        ]];
        let categoryArr = [
            {category: 'FE', previous: null},
            {category: 'BE', previous: null},
            {category: 'Wire-up', previous: null},
            {category: 'Design', previous: null},
        ]
        for (let i = 0; i < issues.length; i++) {
            if (issues[i].node.content !== null) {
                let temp = [];
                temp.push(issues[i].node.content.id);
                temp.push(issues[i].node.content.title);
                temp.push(issues[i].node.content.milestone.title)
                if (i === 0) {
                    //temp[3] = new Date(moment(this.state.githubProjectData.project.createdAt));
                    temp.push(null);
                } else {
                    //temp.push(previous[4]);
                    temp.push(null);
                }
                let sizeLabel = issues[i].node.content.labels.edges.filter(label =>
                    label.node.name === 'XS' ||
                    label.node.name === 'Small' ||
                    label.node.name === 'Medium' ||
                    label.node.name === 'Large' ||
                    label.node.name === 'XL' ||
                    label.node.name === 'Jumbo'
                )

                let category = issues[i].node.content.labels.edges.filter(label =>
                    label.node.name === 'FE' ||
                    label.node.name === 'BE' ||
                    label.node.name === 'Wire-up' ||
                    label.node.name === 'Design')

                let hours = hourMap[sizeLabel[0].node.name];
                //let endDate = new Date(moment(temp[3]).add(parseInt(hours), 'h'));
                //temp.push(endDate);
                temp.push(null);
                temp.push(parseInt(hours) * 60 * 60 * 1000);
                temp.push(0);

                // eslint-disable-next-line default-case
                switch(category[0].node.name) {
                    case 'FE':
                        if (categoryArr[0].previous !== null) {
                            temp.push(categoryArr[0].previous[0]);
                        } else {
                            temp.push(null);
                        }
                        categoryArr[0].previous = temp.slice(0, temp.length - 1);
                        break;
                    case 'BE':
                        if (categoryArr[1].previous !== null) {
                            temp.push(categoryArr[1].previous[0]);
                        } else {
                            temp.push(null);
                        }
                        categoryArr[1].previous = temp.slice(0, temp.length - 1);
                        break;
                    case 'Wire-up':
                        if (categoryArr[2].previous !== null) {
                            temp.push(categoryArr[2].previous[0]);
                        } else {
                            temp.push(null);
                        }
                        categoryArr[2].previous = temp.slice(0, temp.length - 1);
                        break;
                    case 'Design':
                        if (categoryArr[3].previous !== null) {
                            temp.push(categoryArr[3].previous[0]);
                        } else {
                            temp.push(null);
                        }
                        categoryArr[3].previous = temp.slice(0, temp.length - 1);
                        break;
                }
                dataArr.push(temp);
            }
        }
        return dataArr;
    }

    handleGithubProjectChange = (e, nv) => {
        this.setState({
            ...this.state,
            githubProjectData: {
                ...this.state.githubProjectData,
                project: nv
            }
        })
    }

    handleClick = async () => {
        let response = await axios({
            method: 'post',
            url: '/api/github/tasks',
            data : {
                projectId: this.state.githubProjectData.project.id,
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });
        let formattedData = this.parseIssues(response.data)
        this.setState({
            ...this.state,
            chartData: {
                data: formattedData
            }
        })
    }

    renderGantt = (ganttData, project) => {
        if (ganttData && project) {
            let height = ganttData.length * 30 + 30
            return (
                <div id="gantt-chart" style={{ height: `${height + 120}px`, overflowY: 'scroll', paddingTop: 15, paddingBottom: 15, paddingLeft: 15, paddingRight: 15 }}>
                    <h3>{project.name}</h3>
                    <Chart
                        width="100%"
                        height={`${height}px`}
                        chartType="Gantt"
                        loader={<div>Loading Chart</div>}
                        data={ganttData}
                        options={{
                            height: height,
                            gantt: {
                                trackHeight: 30,
                                defaultStartDateMillis: new Date(moment(project.createdAt)),
                            },
                            explorer: { axis: 'horizontal', keepInBounds: true },
                            title: `${project.name}`,
                            hAxis: { title: "Date" },
                            vAxis: { title: "Issue" }
                        }}
                        rootProps={{ 'data-testid': '4' }}
                    />
                </div>
            )
        } else {
            return (
                <Fragment />
            )
        }
    }

    print = () => {
        domtoimage.toBlob(document.getElementById('gantt-chart'), { bgcolor: '#FFFFFF' })
            .then(function (blob) {
                fileDownload(blob, `Gantt-Chart.png`);
            });
    }


    render() {
        return (
            <AppCtxConsumer>
                {(context) => {
                    return (
                        <Fragment>
                            <Grid container direction="row" justify="center" align="center" xs={12} spacing={1}>
                                <h1>Gantt Chart</h1>
                                <Grid container direction="row" justify="center" align="center" xs={8} spacing={1}>
                                    <Grid item xs={12}>
                                        <Autocomplete id="Github Project Select"
                                            options={context.githubProjects}
                                            value={this.state.githubProjectData.project}
                                            onChange={this.handleGithubProjectChange}
                                            getOptionLabel={(option) => option.name}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);
                                                return filtered;
                                            }}
                                            inputValue={this.state.githubProjectData.inputValue}
                                            onInputChange={(e, niv) => {
                                                this.setState({
                                                    ...this.state,
                                                    githubProjectData: {
                                                        ...this.state.githubProjectData,
                                                        inputValue: niv
                                                    }
                                                })
                                            }}
                                            style={{ width: 200, paddingTop: '30px' }}
                                            renderInput={(params) => <TextField {...params} label="Select Github Project" variant="outlined" />}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    {this.renderGantt(this.state.chartData.data, this.state.githubProjectData.project)}
                                </Grid>
                                <Grid item>
                                    <Button onClick={this.print}>Save</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={this.handleClick}>
                                        Create Gantt
                                    </Button>
                                </Grid>
                            </Grid>
                        </Fragment>
                    )
                }}
            </AppCtxConsumer>
        )
    }
}

export default GanttChart;