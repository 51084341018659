import React, { useState } from 'react';
import axios from 'axios';
import { Button, createMuiTheme, FormControl, makeStyles, TextField, ThemeProvider} from '@material-ui/core';
import Header from '../components/Header';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "5rem",
    textAlign: "center",
  }
}))

const AddNewUrlPage = () => {
  const [urlError, setUrlError] = useState(false);
  const [repoNameError, setRepoNameError] = useState(false);
  const [url, setUrl] = useState('');
  const [repoName, setRepoName] = useState('');
  const classes = useStyles();

  const theme =  createMuiTheme({
    overrides: {
      MuiFormControl: {
            root: {
                margin: "1rem",
                minWidth: "32rem",
            },
        }
    }
  });

  const addNewUrl = async () => {
    if (!urlError && url.trim().length > 0){
      try {
        let result = await axios.post('/urls', {
          url,
          repoName
        });
        if (result.data === 'url successful added') {
          alert('Url was added successfully');
          window.location = '/dashboard';
        } else {
          alert("An error occurred. As a result, url wasn't added successfully")
        }
      } catch(e){
        console.log(e.toString());
      }
    }
  }

  const validateUrl = event => {
    const url = event.target.value
    const URL_REGEX = /^(ftp|http|https):\/\/[^ "]+$/;

      if(url.trim().length > 0 && url.match(URL_REGEX)){
        setUrlError(false);
        setUrl(url);
      } else {
        setUrlError(true);
      }
  }

  const handleRepoNameChange = event => {
    const repositoryName = event.target.value
    if(repositoryName.trim().length === 0){
      setRepoNameError(true);
    }
    else{
      setRepoNameError(false);
      setRepoName(repositoryName);
    }
  }

  return (
    <div class={classes.container}>
      <Header showLogoutButton/>
      <h1>Add new urls to check if they are still accessible</h1>
      <ThemeProvider theme={theme}>
        <FormControl>
          <TextField
            error={urlError}
            label="Enter new url"
            helperText={urlError ? "Invalid url" : ""}
            onChange={validateUrl}
            variant="outlined"
          />
          <TextField
            error={repoNameError}
            label="Enter repo name for this website/project as listed on Github"
            onChange={handleRepoNameChange}
            variant="outlined"
          />
          <Button
            disabled={repoNameError || urlError || url.trim().length === 0 || repoName.trim().length === 0}
            onClick={addNewUrl}
          >
            ADD URL
          </Button>
        </FormControl>
      </ThemeProvider>
    </div>
  );
}

export default AddNewUrlPage;