import React from 'react';
import { Email, Item, A} from 'react-html-email';

export default function ({message, link}) {
  return (
  <Email title='link'>
    <Item>
      <div dangerouslySetInnerHTML={{__html: message}} />
      {link && <A href={link}>this link</A>}
    </Item>
  </Email>
)};