import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import crypto from 'crypto'
import { Button, withStyles  } from '@material-ui/core';
import { AppCtxConsumer } from '../context'
import Header from '../components/Header';

import { classComponentStyles } from '../styles/styles';

const HARVEST_CLIENT_ID = process.env.REACT_APP_HARVEST_CLIENT_ID;
const GITHUB_CLIENT_ID = process.env.REACT_APP_GITHUB_CLIENT_ID;
const STATE_CODE = crypto.randomBytes(16).toString('hex');

class Authentication extends Component {
    componentDidMount() {

    }

    render() {
        const { classes } = this.props;
        return (
            <AppCtxConsumer>
                {(context) => {

                    if (context.harvestAuthorized && context.githubAuthorized){
                        return(
                            <Redirect to="/dashboard" />
                        )
                    }

                    return (
                        <div className={classes.root}>
                            <Header showLogoutButton title="Authenticate Yourself!"/>

                            <div>
                                {!context.harvestAuthorized && <Button href={`https://id.getharvest.com/oauth2/authorize?client_id=${HARVEST_CLIENT_ID}&response_type=code`}>Sign into Harvest</Button>}
                                {!context.githubAuthorized && <Button href={`https://github.com/login/oauth/authorize?client_id=${GITHUB_CLIENT_ID}&state=${STATE_CODE}&scope=admin:org,repo`}>Sign into Github</Button>}
                            </div>
                        </div>

                    )
                }}
            </AppCtxConsumer>
        )
    }
}

export default withStyles(classComponentStyles)(Authentication);