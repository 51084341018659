import React, { Component, Fragment } from 'react';
import axios from 'axios';
import moment from 'moment';
import Button from './Button.js';
import '../styles/styles.css';
import { Chart } from 'react-charts';
import styled from 'styled-components';

const Module = styled.div`
    width: 100%;
    height: 99%;
    border-radius: 2px;
    border: solid 1px #2b2b2d;
    display: flex;
    flex-direction: column;
    ${props => props.authenticated ? `` : `filter: blur(18px);`}
`;

const defs = (
    <defs>
        <linearGradient id="0" x1="0" x2="0" y1="1" y2="0" gradientTransform="rotate(11)">
            <stop offset="-63%" stopColor="#31ff98" />
            <stop offset="93%" stopColor="#3fd0f2" />
        </linearGradient>
        <linearGradient id="1" x1="0" x2="0" y1="1" y2="0" gradientTransform="rotate(47)">
            <stop offset="47%" stopColor="#dd2476" />
            <stop offset="53%" stopColor="#9358f5" />
        </linearGradient>
    </defs>
)



class EDashHT extends Component {
    constructor(props) {
        super(props);

        this.state = {
            today: moment(),
            targetInfo: [{
                target: 0,
                start_date: moment().format('MM/DD/YYYY'),
                schedule: 'full-time'
            }],
            holidays: [],
        }
    }

    async componentDidMount() {
        if (this.props.authenticated) {
            let data = await axios({
                method: 'get',
                url: `/yearly-target?year=${moment().year()}`,
                headers: {
                    'Content-Type': 'applcation/json'
                }
            });
            
            let holidayData = await axios({
                method: 'get',
                url: `/company-holidays?year=${moment().year()}`,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            

            this.setState({
                ...this.state,
                targetInfo: data.data,
                holidays: holidayData.data.data
            });
        }
    }

    parseTimeDataToChartData = (timeData, today, schedule) => {

        let finalData = [{ data: [] }];
        let hoursPerDay = 7 
        if (schedule === 'part-time') hoursPerDay = 3.5;

        for (let i = 0; i < 12; i++) {
            let totalDaysInMonth = 0;
            let totalMonthHours = 0;
            timeData.forEach(item => {
                if (moment(item.spent_date, 'YYYY-MM-DD').month() == i.toString()) {
                    totalMonthHours += item.hours;
                }
            })
            let firstDay = moment(`${(i + 1).toString()}/01/${today.year()}`, 'MM/DD/YYYY')

            while (firstDay.month() == i) {
                if (!(firstDay.day() === 0 || firstDay.day() === 6)) {
                    totalDaysInMonth++;
                }
                firstDay = firstDay.add(1, 'days');
            }
            let finalHeight = ((totalMonthHours / totalDaysInMonth) - hoursPerDay);
            if (finalHeight === -(hoursPerDay)) {
                finalHeight = 0;
            }
            finalData[0].data.push([i.toString(), finalHeight])
        }

        return finalData;
    }

    formatX = (label) => {
        switch (label) {
            case '0':
                return 'J';
            case '1':
                return 'F';
            case '2':
                return 'M';
            case '3':
                return 'A';
            case '4':
                return 'M';
            case '5':
                return 'J';
            case '6':
                return 'J';
            case '7':
                return 'A';
            case '8':
                return 'S';
            case '9':
                return 'O';
            case '10':
                return 'N';
            case '11':
                return 'D';
        }
    }

    formatYFT = (label) => {
        switch (label) {
            case '-3.0':
                return '4';
            case '-2.0':
                return '5';
            case '-1.0':
                return '6';
            case '0.0':
                return '7';
            case '1.0':
                return '8';
            case '2.0':
                return '9';
            case '3.0':
                return '10';
        }
        return '';
    }

    formatYPT = (label) => {
        switch (label) {
            case '-3.0':
                return '0.5';
            case '-2.0':
                return '1.5';
            case '-1.0':
                return '2.5';
            case '0.0':
                return '3.5';
            case '1.0':
                return '4.5';
            case '2.0':
                return '5.5';
            case '3.0':
                return '6.5';
        }
        return '';
    }

    findWorkDaysToDate(today, startDate) {
        let count = 1;
        for (let i = moment(startDate, 'MM/DD/YYYY'); i.format('MM/DD/YYYY') != today.format('MM/DD/YYYY'); i = i.add(1, 'days')) {
            if (i.day() !== 6 && i.day() !== 0) {
                count++;
            }
        }
        return count;
    }

    findHoursWorkedToDate(timeData, today) {
        let total = 0;
        let current = moment(`01/01/${today.year()}`, 'MM/DD/YYYY');
        let tomorrow = moment(today);
        let day = moment(tomorrow).add(1, 'days');
        while (current.format('MM/DD/YYYY') !== day.format('MM/DD/YYYY')) {
            timeData.forEach(item => {
                if (item.spent_date == current.format('YYYY-MM-DD') && item.billable) {
                    total += item.hours;
                }
            });
            current = current.add(1, 'days');
        }

        return total;
    }

    findRemainingHolidays(today) {
        let count = 0;
        let current = moment(today);

        this.state.holidays.forEach(item => {
            if (moment(item.date, 'MM/DD/YYYY').unix() >= current.unix()) {
                count++;
            }
        })

        return count;
    }

    findRemainingDaysInYear(today) {
        let count = 0;
        let current = moment(today);
        let nextYear = (today.year()) + 1;
        while (current.year() !== nextYear) {
            if (current.day() !== 6 && current.day() !== 0) {
                count++;
            }
            current = current.add(1, 'days');
        }

        return count;
    }

    render() {
        const data = this.parseTimeDataToChartData(this.props.timeData, this.state.today, this.state.targetInfo[0].schedule);

        let remainingHolidays = this.findRemainingHolidays(this.state.today);
        let remainingDays = this.findRemainingDaysInYear(this.state.today);
        remainingDays = remainingDays - remainingHolidays;

        let workDays = this.findWorkDaysToDate(this.state.today, this.state.targetInfo[0].start_date);

        let hoursWorked = this.findHoursWorkedToDate(this.props.timeData, this.state.today);

        let averageToWork = ((parseInt(this.state.targetInfo[0].target) - hoursWorked) / remainingDays);
        let hoursPerDay = (this.state.targetInfo[0].schedule === 'part-time') ? (3.5) : (7);
        let bonus = (hoursWorked) - (workDays * hoursPerDay);

        let authenticated = this.props.authenticated;

        const series = {
            type: "bar"
        }

        let count = 0;

        const getSeriesStyle = (series) => {
            let selector = '0';
            if (series.originalSeries.data[count][1] <= 0) {
                selector = '1'
            }
            return ({
                color: `url(#${selector})`,
            })

        }

        count = 0;

        const axes = [
            { primary: true, type: 'ordinal', position: 'bottom', format: this.formatX },
            (this.state.targetInfo[0].schedule === 'full-time') ? (
                {
                    type: 'linear',
                    position: 'left',
                    hardMin: -3,
                    hardMax: 3,
                    showGrid: false,
                    tickValues: ['-3', '-2', '-1', '0', '1', '2', '3'],
                    format: this.formatYFT
                }
            ) : (
                {
                    type: 'linear',
                    position: 'left',
                    hardMin: -3,
                    hardMax: 3,
                    showGrid: false,
                    tickValues: ['-3', '-2', '-1', '0', '1', '2', '3'],
                    format: this.formatYPT
                }
            )
        ]

        return (
            <div class="module1">
                <p class="moduleText moduleText1">Yearly Trend</p>
                <div class="moduleBorder">
                    <Module authenticated={authenticated}>
                        <div class="HTBreakdown">
                            <div class="BarChart">
                                <Chart data={data} series={series} axes={axes} getSeriesStyle={getSeriesStyle} renderSVG={() => defs} dark>

                                </Chart>
                            </div>
                            <div class="outerHTTrend">
                                <div class="hourlyGoal">
                                    {(hoursWorked).toFixed(2)}
                                </div>
                                <div class="HourlyGoalText">
                                    Billable Hours To-Date
                                </div>
                                <div class="hourlyGoal">
                                    {(this.state.targetInfo[0].target - hoursWorked).toFixed(2)}
                                </div>
                                <div class="HourlyGoalText">
                                    Remaining Hours till Target
                                </div>
                            </div>
                        </div>
                        <div class="dividerHorizontal" />
                        <div class="HTInformation">
                            <div class="HourlyGoalText">
                                You would need to average <b class="HourlyGoalText2">{averageToWork.toFixed(2)}</b> hours a day to meet your yearly goal.
                        </div>
                        </div>
                    </Module>
                </div>
            </div>
        )
    }
}

export default EDashHT;
