import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Check from '../assets/Icons/Check.svg';
import Plus from '../assets/Icon/Plus.svg';
import ArrowRight from '../assets/Icon/ChevronRight.svg';
import '../styles/styles.css';

// change padding to be fixed rather than percentages, change margin to pixels (maybe 10px 0px)
const NButton = styled.button`
    height: 100%;
    width: fit-content;
    padding: 0px 15px;
    border-radius: 35px;
    ${props => props.primary ? 'background-color: #141416' : 'background-image: linear-gradient(73deg, #fb8a2f -19%, #f36c00 98%)'};
    border: solid 1px #2b2b2d;
    margin: ${props => props.margin || '10px 20px'};
    display: flex;
    align-items: center;
    &:hover {
        ${props => props.disabled ? `cursor: default` : `cursor: pointer`};
    }
`;

const ButtonText = styled.p`
    white-space: nowrap;
    font-family: 'Work Sans';
    font-size: 15px;
    color: #ffffff;
    opacity: ${props => props.primary ? '0.6' : '1'};
    margin: 10px 0px;
`;

class Button extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { margin, primary = false, checkMark = false, plus = false, arrow = false, disabled = false, children } = this.props;
        return(
            <NButton margin={margin} primary={primary} disabled={disabled} >
                {(checkMark) ? (<img class="checkMark" src={Check} alt="Check" />) : (<Fragment />)}
                    <ButtonText primary={primary}>
                        {children}
                    </ButtonText>
                    {(plus) ? (<img class="plusSign" src={Plus} alt="Plus" />) : (<Fragment />)}
                    {(arrow) ? (<img class="forwardArrow" src={ArrowRight} alt="arrow" />) : (<Fragment />)}
            </NButton>
        )
    }
}

export default Button;
