import { makeStyles } from '@material-ui/core';

const drawerWidth = 240;

export const statelessComponentStyles = makeStyles(theme => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerRoot: {
    display: 'flex',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    marginTop: 25,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  form: {
    textAlign: "center",
  },
  logoutButton: {
    marginLeft: "auto",
  },
  root: {
    flexGrow: 1,
  },
  selectOptions:{
    width: "10rem",
  },
  title: {
    flexGrow: 1,
  },
}));

export const classComponentStyles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  processButton: {
    marginTop: "1rem",
  },
  root: {
    flexGrow: 1,
  },
  selectContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    flexGrow: 1,
  },
  resetPasswordContainer: {
    textAlign: 'center',
  },
  resetPasswordFormControl: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '20rem',
    margin: 'auto',
  },
  resetPasswordTextField: {
    paddingBottom: '2rem',
  },
  mainContainer: {
    textAlign: 'center',
  },
  tableRoot: {
    width: '100%',
  },
  tableCell: {
    backgroundColor: "#03a9f4 !important",
  },
  chartMainContainer: {
    textAlign: 'center',
  },
  radarChartContainer:{
    display: 'inline-block',
  }
});