import React, { Component } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/';
import TableComponent from '../components/TableComponent';
import Header from '../components/Header';
import { classComponentStyles } from '../styles/styles';

const CREATE_NEW_ISSUE = `
  mutation ($input: CreateIssueInput!){
    createIssue(input: $input) {
        issue {
            id
            title
            body
        }
    }
  }
`;

const newIssueData = (repositoryId, title, body) => `
  {
    "repositoryId": "${repositoryId}",
    "title": "${title}",
    "body": "${body}"
  }
`;

const axiosGitHubGraphQL = axios.create({
  baseURL: 'https://api.github.com/graphql',
  headers: {
    Authorization: `bearer ${process.env.REACT_APP_GITHUB_ACCESS_TOKEN}`
  }
});

const columns = [
  { id: 'email', label: "Client's Email Address", minWidth: 150},
  { id: 'issue_title', label: 'Issue Title', minWidth: 150 },
  { id: 'issue_body', label: 'Issue Content', minWidth: 150 },
  { id: 'repo_name', label: 'Name of Repository', minWidth: 150, align: 'right' },
  { id: 'status', label: 'Status', minWidth: 100, align: 'right' },
  { id: 'created_at', label: 'Created At', minWidth: 150, align: 'right' },
  { id: 'updated_at', label: 'Updated At', minWidth: 150, align: 'right' },
];


const dateColumns = ["created_at", "updated_at"];

class GithubIssuesPage extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      issues: [],
      rows: [],
      page: 0,
      rowsPerPage: 10,
     };
  }

  async componentDidMount() {
    try {
      const response = await axios.get('/github-issues');
      if(response.data === "no issues found"){
        return;
      }
      response.data.forEach(item => {
        const newIssue = { id: item.id, status: item.status };
        this.setState({ issues: [...this.state.issues, newIssue], rows: [...this.state.rows, item] });
      });
      this.setState({ isLoading: false });
    }
    catch (e){
      console.log(e.toString());
    }
  }

  onSubmit = async (id, issues) => {
    try {
        const { rows } = this.state;
        const issue = issues.find(issue => issue.id === id);
        const row = rows.find(row => row.id === id);
        let response = await axios.post('/process-github-issue', {
          issue
        });
        if (response.data === 'ticket successfully processed'){
          if (issue.status === 'APPROVED'){
            const data = newIssueData(row.repo_id, row.issue_title, row.issue_body);
            this.addNewIssueToRepository(JSON.parse(data));
          } else {
            alert("Ticket was processed successfully");
            window.location.reload();
          }
        }
    } catch(e) {
      console.log(e.toString());
    }
  }

  addNewIssueToRepository = (input) => {
    axiosGitHubGraphQL
      .post('', { query: CREATE_NEW_ISSUE, variables: { input } })
      .then(() => {
        alert("Issue was created successfully");
        window.location.reload();
      })
  };

  render() {
    const { isLoading, issues, rows } = this.state;
    const { classes } = this.props;

    return (
      (!isLoading && rows.length > 0 && issues.length > 0)
      ?
      (
        <div class={classes.mainContainer}>
          <Header showLogoutButton title="Github Tickets Created By Clients"/>
          <TableComponent
            classes={classes}
            columns={columns}
            dateColumns={dateColumns}
            requests={issues}
            rows={rows}
            onSubmit={this.onSubmit}
          />
        </div>
      )
      :
      <div>Loading...</div>
    );
  }
}


export default withStyles(classComponentStyles)(GithubIssuesPage);