import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Announcement from '../components/Announcement';
import Navigation from '../components/Navigation';
import Emoji from '../components/Emoji';
import EDashHours from '../components/EDashHours';
import EDashVac from '../components/EDashVac';
import EDashHT from '../components/EDashHT';
import EDashWC from '../components/EDashWC';
import EDashPolls from '../components/EDashPolls';
import EDashPD from '../components/EDashPD';
import moment from 'moment';
import { AppCtxConsumer } from '../context'
import '../styles/styles.css';

class EmployeeDashboard extends Component {

    constructor(props){
        super(props);

        this.state = {
            timeData: [],
            today: moment(),
            name: 'User'
        }
    }

    async componentDidMount() {
        let timeData;
        let user, userId;
        let today = moment();
        
        let name;

        try {
            user = await axios({
                method: 'get',
                url: '/api/harvest/user',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            userId = user.data.id;
            name = user.data.first_name;

            timeData = await axios({
                method: 'post',
                url: '/api/harvest/user_time_entries',
                data: {
                    year: today.year(),
                    userId: userId
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            timeData = timeData.data;
        } catch (e) {
            timeData = [];
            name = 'User';
            console.log('please authenicate harvest');
        }

        this.setState({
            ...this.state,
            timeData,
            today,
            name
        })
    }

    render(){
        let date = this.state.today;
        return (
            <AppCtxConsumer>
                {(context) => (
                    <div class="EDashboardMain">
                        <Navigation />
                        <div class="dividerVertical"></div>
                        <div class="EDashboardContent">
                            <div class="contentHeader">
                                <div class="dateGreeting">
                                    {(() => {
                                        switch(date.format('dddd')) {
                                            case 'Monday':
                                                return <Emoji currentClass="Emoji" symbol="⏰" label="monday-alarmclock" />
                                            case 'Tuesday':
                                                return <Emoji currentClass="Emoji" symbol="🌮" label="tuesday-taco"/>
                                            case 'Wednesday':
                                                return <Emoji currentClass="Emoji" symbol="🐪" label="wednesday-camel"/>
                                            case 'Thursday':
                                                return <Emoji currentClass="Emoji" symbol="🍺" label="thursday-beer"/>
                                            case 'Friday':
                                                return <Emoji currentClass="Emoji" symbol="🎉" label="friday-partypopper" />
                                            default:
                                                return <Emoji currentClass="Emoji" symbol="☀️" label="weekend-sun" />
                                        }
                                    }) ()}
                                    <div class="CurrentDate">
                                        It's {date.format('dddd')}, {date.format('MMMM')} {date.format('DD')}
                                    </div>
                                </div>
                                <div class="userGreeting">
                                    Hello, {this.state.name}
                        </div>
                            </div>
                            <div class="dividerHorizontal"></div>
                            <Announcement />
                            <div class="EDashboardModules">
                                <EDashHours timeData={this.state.timeData} today={this.state.today} authenticated={context.harvestAuthorized} />
                                <EDashHT timeData={this.state.timeData} authenticated={context.harvestAuthorized} />
                                <div class="outerVacContainer">
                                    <EDashVac timeData={this.state.timeData} authenticated={context.harvestAuthorized} />
                                </div>
                                <EDashWC />
                                <EDashPD />
                            </div>
                        </div>
                    </div>
                )}
            </AppCtxConsumer>
        )
    }
}

export default EmployeeDashboard;