import React, { Component, Fragment } from 'react';
import axios from 'axios';
import {
    Grid,
    Button,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Table,
    TableContainer,
    Paper,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';
import moment from 'moment';
import lodash from 'lodash';
import YearlyBreakdown from '../components/YearlyBreakdown';
import QuarterlyBreakdown from '../components/QuarterlyBreakdown';
import EmployeeSummary from '../components/EmployeeSummary';

const years = lodash.range(2020, 2100);

class AdminBillableHoursTargetPage extends Component {
    constructor() {
        super()
        this.state = {
            year: moment().year(),
            timeData: null,
            quarter1: false,
            quarter2: false,
            quarter3: false,
            quarter4: false,
            employees: [],
            employee: ""
        }

    }

    async componentDidMount() {
        let user;
        let users;
        try {
            users = await axios({
                url: `/api/harvest/users`,
                method: 'get',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            users = users.data.users.filter(item => item.is_active && !item.is_contractor);
            this.setState({ ...this.state, employees: users, employee: users[0].id });
        } catch {
            try {
                user = await axios({
                    method: 'get',
                    url: '/api/harvest/user',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                this.setState({
                    ...this.state,
                    employee: user.data.id,
                    employees: [user.data]
                });

            } catch (e) {
                console.log(e.toString());
            }

        }
    }

    findWorkDays = async (year) => {
        // let holidays = await axios({
        //     url: `/company-holidays?year=${year}`,
        //     method: 'get',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     }
        // })
        // holidays = holidays.data.data

        //query harvest for users email as well as id
        let employee = this.state.employees.filter(item => item.id === this.state.employee);
        console.log('employee', employee);
        let data = await axios({
            method: 'get',
            url: `/yearly-target?year=${year}&email=${employee[0].email}`,
            headers: {
                'Content-Type': 'applcation/json'
            }
        });

        let dailyHours = 7;
        if (data.data[0].schedule === 'part-time') {
            dailyHours = 3.5;
        }

        let workDays = parseInt(data.data[0].target) / dailyHours;
        

        // for (let i = moment(`01/01/${year}`, 'MM/DD/YYYY').dayOfYear(); i <= moment(`12/31/${year}`, 'MM/DD/YYYY').dayOfYear(); i++) {
        //     let isHoliday = false;

        //     for (let j = 0; j < holidays.length; j++) {
        //         if (moment().dayOfYear(i).format('MM/DD/YYYY') === holidays[j].date) {
        //             isHoliday = true;
        //         }
        //     }

        //     if (!isHoliday && moment().dayOfYear(i).day() !== 6 && moment().dayOfYear(i).day() !== 0) {
        //         workDays++;
        //     }
        // }
        return { workDays, dailyHours };
    }

    handleYearChange = (e) => {
        this.setState({ ...this.state, year: e.target.value });
    }

    handleEmployeeChange = (e) => {
        this.setState({ ...this.state, employee: e.target.value })
    }

    handleSubmit = async () => {
        let data = await axios({
            method: 'post',
            url: '/api/harvest/user_time_entries',
            data: {
                year: this.state.year,
                userId: this.state.employee
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });
        let workDays;
        if (data.data && data.data.length > 0) {
            workDays = await this.findWorkDays(this.state.year);
        } else {
            alert(`No time data found for ${this.state.year}, Please select a different year.`);
            return;
        }

        let now = moment();
        let qtr1, qtr2, qtr3, qtr4;
        if (now.year() === this.state.year && now.isBetween(moment(`01/01/${this.state.year}`), moment(`03/31/${this.state.year}`))) {
            qtr1 = false;
            qtr2 = false;
            qtr3 = false;
            qtr4 = false;
        } else if (now.year() === this.state.year && now.isBetween(moment(`04/01/${this.state.year}`), moment(`06/30/${this.state.year}`))) {
            qtr1 = true;
            qtr2 = false;
            qtr3 = false;
            qtr4 = false;
        } else if (now.year() === this.state.year && now.isBetween(moment(`07/01/${this.state.year}`), moment(`09/30/${this.state.year}`))) {
            qtr1 = true;
            qtr2 = true;
            qtr3 = false;
            qtr4 = false;
        } else if (now.year() === this.state.year && now.isBetween(moment(`10/01/${this.state.year}`), moment(`12/31/${this.state.year}`))) {
            qtr1 = true;
            qtr2 = true;
            qtr3 = true;
            qtr4 = false;
        } else if (now.year() > this.state.year) {
            qtr1 = true;
            qtr2 = true;
            qtr3 = true;
            qtr4 = true;
        }

        this.setState({
            ...this.state,
            timeData: data.data,
            workDays,
            targetHours: workDays.workDays * workDays.dailyHours,
            quarter1: qtr1,
            quarter2: qtr2,
            quarter3: qtr3,
            quarter4: qtr4
        });
    }

    render() {
        console.log(this.state);
        return (
            <Fragment>
                <Grid direction="column" justify="center" alignItems="center" container xs={12} spacing={3}>
                    {(this.state.employees.length > 1) ? (
                        <Fragment>
                            <Grid item>
                                <Fragment />
                            </Grid>
                            <Grid item>
                                <h2>Company Overview</h2>
                            </Grid>
                            <Grid item>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Employee</TableCell>
                                                <TableCell>Vacation Remaining</TableCell>
                                                <TableCell>Bonus Projected</TableCell>
                                                <TableCell>Total Hours</TableCell>
                                                <TableCell>Total Billable Hours</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.employees.map(item => (
                                                <EmployeeSummary employee={item} workDays={this.state.workDays} year={this.state.year} holidays={this.state.holidays} />    
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Fragment>
                    ) : (<Fragment />)}
                    <Grid item>
                        <h2>Select an Employee and Year</h2>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <InputLabel>Employee</InputLabel>
                            <Select
                                labelId="breakdown-employee-select"
                                id="billable-target-employee-select"
                                value={this.state.employee}
                                onChange={this.handleEmployeeChange}
                            >
                                {this.state.employees.map(item => (
                                    <MenuItem value={item.id}>{item.first_name} {item.last_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <InputLabel>Year</InputLabel>
                            <Select
                                labelId="breakdown-year-select"
                                id="billable-target-year-select"
                                value={this.state.year}
                                onChange={this.handleYearChange}
                            >
                                {years.map(item => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button onClick={this.handleSubmit}>Generate Report</Button>
                    </Grid>
                </Grid>
                <Grid container justify="center" alignItems="center" spacing={3}>
                    {(this.state.timeData && this.state.timeData.length > 0) ? (
                        <YearlyBreakdown timeData={this.state.timeData} year={this.state.year} workDays={this.state.workDays} targetHours={this.state.targetHours} />
                    ) : (
                            <Fragment />
                        )}
                </Grid>
                {this.state.quarter1 && (
                    <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
                        <Grid item>
                            <h3><u>Quarterly Breadown</u></h3>
                        </Grid>
                        <Grid item>
                            <div style={{ paddingBottom: '200px' }}>

                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell>Actual Billable Hours</TableCell>
                                                <TableCell>Expected Hours</TableCell>
                                                <TableCell>Bonus Hours</TableCell>
                                                <TableCell>Percentage</TableCell>
                                                <TableCell>Sick Taken</TableCell>
                                                <TableCell>Sick Remaining</TableCell>
                                                <TableCell>Vacation Taken</TableCell>
                                                <TableCell>Vacation Remaining</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.quarter1 && <QuarterlyBreakdown qtr={1} timeData={this.state.timeData} year={this.state.year} workDays={this.state.workDays} targetHours={this.state.targetHours} />}
                                            {this.state.quarter2 && <QuarterlyBreakdown qtr={2} timeData={this.state.timeData} year={this.state.year} workDays={this.state.workDays} targetHours={this.state.targetHours} />}
                                            {this.state.quarter3 && <QuarterlyBreakdown qtr={3} timeData={this.state.timeData} year={this.state.year} workDays={this.state.workDays} targetHours={this.state.targetHours} />}
                                            {this.state.quarter4 && <QuarterlyBreakdown qtr={4} timeData={this.state.timeData} year={this.state.year} workDays={this.state.workDays} targetHours={this.state.targetHours} />}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Grid>
                    </Grid>)}
            </Fragment>
        )
    }
}

export default AdminBillableHoursTargetPage;