import React, { Component, Fragment } from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Select,
    MenuItem,
    TextField,
    withStyles,
    Collapse,
    IconButton,
    Grid
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { flattenDeep } from 'lodash'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ObjectToCSV from 'object-to-csv';
import fileDownload from "js-file-download";

const useStyles = () => ({
    tableRoot: {
        width: '1200px',
    }
})

function EmbeddedRow(props) {
    const { row, headCells } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <Fragment>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {headCells.map(column => {
                    const value = row[column.id];
                    return (
                        <TableCell>
                            {value}
                        </TableCell>
                    )
                })}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Grid container justify="center" align="center" alignItems="center" spacing={3} xs={12}>
                            <Grid item>
                            <Button>
                                <Link to={`/dashboard/lighthouse-audit/${row.id}/desktop`}>
                                    Desktop Audit
                                </Link>
                            </Button>
                            </Grid>
                            <Grid>
                            <Button>
                                <Link to={`/dashboard/lighthouse-audit/${row.id}/mobile`}>
                                    Mobile Audit
                                </Link>
                            </Button>
                            </Grid>
                        </Grid>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

function Row(props) {
    const { row, headCells } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <Fragment>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {headCells.map(column => {
                    const value = row[0][column.id];
                    if (column.id === 'created_at') {
                        return (
                            <TableCell style={{ minWidth: 200}}>
                                {value}
                            </TableCell>
                        )
                    } else {
                        return (
                            <TableCell>
                                {value}
                            </TableCell>
                        )
                    }
                })}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Fragment>
                            <TableRow>
                                <TableCell colSpan={15}>
                                <Grid container justify="center" align="center" alignItems="center" spacing={3} xs={12}>
                                    <Grid item>
                                        <Button>
                                            <Link to={`/dashboard/lighthouse-audit/${row[0].id}/desktop`}>
                                                Desktop Audit
                                            </Link>
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button>
                                            <Link to={`/dashboard/lighthouse-audit/${row[0].id}/mobile`}>
                                                Mobile Audit
                                            </Link>
                                        </Button>
                                    </Grid>
                                </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4} style={{ paddingRight: 20}}>
                                    <strong>Difference from Original (ID: {row[0]['id']} from ID: {row[row.length - 1]['id']})</strong>
                                </TableCell>
                                {headCells.map(column => {
                                    if (column.id !== 'id' && column.id !== 'domain' && column.id !== 'URL' && column.id !== 'created_at') {
                                        const value = row[0][column.id] - row[row.length - 1][column.id];
                                        let final;
                                        if (value > 0) {
                                            final = `+${value}`
                                        } else {
                                            final = `${value}`
                                        }
                                        return (
                                            <TableCell>
                                                {final}
                                            </TableCell>
                                        )
                                    }
                                })}
                                <TableCell />
                            </TableRow>
                            <TableRow>
                                <TableCell />
                                {headCells.map(column => (
                                    <TableCell key={column.id}>
                                        <strong>{column.label}</strong>
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableBody>
                                {row.slice(1, row.length).map(item => (
                                    <EmbeddedRow row={item} headCells={headCells} />
                                ))}
                            </TableBody>
                        </Fragment>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

class LighthouseTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            query: '',
            columnToQuery: 'domain',
            search: '',
        }
    }

    handleChangePage = (e, newPage) => {
        this.setState({
            ...this.state,
            page: newPage
        })
    }

    handleChangeRowsPerPage = (e) => {
        this.setState({
            rowsPerPage: +e.target.value,
            page: 0
        })
    }

    getAverage = (array) => {
        let avg = array.reduce((a, b) => a + b) / array.length;
        return Math.round((avg + Number.EPSILON) * 100) / 100
    }

    filterArray = (rows, type) => {
        return rows.map(item => item[0][type]);
    }

    render() {
        const headCells = [
            { id: 'id', numeric: true, disablePadding: true, label: 'ID' },
            { id: 'domain', numeric: false, disablePadding: true, label: 'Domain' },
            { id: 'URL', numeric: false, disablePadding: true, label: 'URL' },
            { id: 'da', numeric: true, disablePadding: true, label: 'Desktop Accessibilty' },
            { id: 'dp', numeric: true, disablePadding: true, label: 'Desktop Performance' },
            { id: 'dbp', numeric: true, disablePadding: true, label: 'Desktop Best-Practices' },
            { id: 'dseo', numeric: true, disablePadding: true, label: 'Desktop SEO' },
            { id: 'dpwa', numeric: true, disablePadding: true, label: 'Desktop PWA' },
            { id: 'ma', numeric: true, disablePadding: true, label: 'Mobile Accessibilty' },
            { id: 'mp', numeric: true, disablePadding: true, label: 'Mobile Performance' },
            { id: 'mbp', numeric: true, disablePadding: true, label: 'Mobile Best-Practices' },
            { id: 'mseo', numeric: true, disablePadding: true, label: 'Mobile SEO' },
            { id: 'mpwa', numeric: true, disablePadding: true, label: 'Mobile PWA' },
            { id: 'created_at', numeric: false, disablePadding: true, label: 'Created At' }
        ]
        const { page, rowsPerPage } = this.state
        const { classes } = this.props
        const rows = (this.state.query.length > 0 ?
            this.props.rows.filter(x => 
                (x[0][this.state.columnToQuery]).toString().includes(this.state.query)
            )
            : this.props.rows)
        return (
            <Fragment>
                <Grid container justify="center" align="center" alignItems="center" xs={12} spacing={2}>
                    <Grid item>
                        <TextField id="column-search" label="Search" variant="standard" value={this.state.query} onChange={(e) => {
                            this.setState({ ...this.state, query: e.target.value });
                        }}
                        />
                    </Grid>
                    <Grid item>
                        <Select
                            labelId="Column"
                            id="select-column"
                            value={this.state.columnToQuery}
                            onChange={(e) => {
                                this.setState({
                                    ...this.state,
                                    query: '',
                                    columnToQuery: e.target.value
                                })
                            }}>
                            {headCells.map(column => (
                                <MenuItem value={column.id}>{column.label}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    {(rows && rows.length > 0) ? (
                    <Grid item>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={10}><strong>Average Scores of Filtered</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableRow>
                                    {headCells.map(item => {
                                        if (item.id !== 'id' && item.id !== 'domain' && item.id !== 'URL' && item.id !== 'created_at') {
                                            return(
                                                <TableCell><strong>{item.label}</strong></TableCell>
                                            )
                                        }
                                    })}
                                </TableRow>
                                <TableRow>
                                    <TableCell>{this.getAverage(this.filterArray(rows, 'da'))}</TableCell>
                                    <TableCell>{this.getAverage(this.filterArray(rows, 'dp'))}</TableCell>
                                    <TableCell>{this.getAverage(this.filterArray(rows, 'dbp'))}</TableCell>
                                    <TableCell>{this.getAverage(this.filterArray(rows, 'dseo'))}</TableCell>
                                    <TableCell>{this.getAverage(this.filterArray(rows, 'dpwa'))}</TableCell>
                                    <TableCell>{this.getAverage(this.filterArray(rows, 'ma'))}</TableCell>
                                    <TableCell>{this.getAverage(this.filterArray(rows, 'mp'))}</TableCell>
                                    <TableCell>{this.getAverage(this.filterArray(rows, 'mbp'))}</TableCell>
                                    <TableCell>{this.getAverage(this.filterArray(rows, 'mseo'))}</TableCell>
                                    <TableCell>{this.getAverage(this.filterArray(rows, 'mpwa'))}</TableCell>
                                </TableRow>
                            </Table>
                        </TableContainer>
                    </Grid>
                    ) : (
                        <Fragment />
                    )}
                    {(rows && rows.length > 0)
                        ?
                        (
                            <Grid item>
                                <Paper className={classes.tableRoot}>
                                    <TableContainer>
                                        <Table stickyheader aria-label="lighthouse-table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell />
                                                    {headCells.map(column => (
                                                        (column.id === 'created_at') ? (
                                                            <TableCell key={column.id} style={{ minWidth: 200}}>
                                                                <strong>{column.label}</strong>
                                                            </TableCell>
                                                        ) : (
                                                            <TableCell key={column.id}>
                                                                <strong>{column.label}</strong>
                                                            </TableCell>
                                                        )
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                                                    <Row row={row} headCells={headCells} />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                </Paper>
                            </Grid>
                        )
                        :
                        <div>No Entries</div>
                    }
                    <Grid item>
                        <Button onClick={async () => {
                            const tableData = flattenDeep(this.props.rows);
                            let keys = [
                                {key: 'id', as: 'ID'},
                                {key: 'domain', as: 'Domain'},
                                {key: 'URL', as: 'URL'},
                                {key: 'da', as: 'Desktop Accessibility'},
                                {key: 'dp', as: 'Desktop Performance'},
                                {key: 'dbp', as: 'Desktop Best-Practices'},
                                {key: 'dseo', as: 'Desktop SEO'},
                                {key: 'dpwa', as: 'Desktop PWA'},
                                {key: 'ma', as: 'Mobile Accessibility'},
                                {key: 'mp', as: 'Mobile Performance'},
                                {key: 'mbp', as: 'Mobile Best-Practices'},
                                {key: 'mseo', as: 'Mobile SEO'},
                                {key: 'mpwa', as: 'Mobile PWA'},
                            ]
                            let otc = new ObjectToCSV({keys, data: tableData});
                            let csv = otc.getCSV();
                            fileDownload(csv, `lighthouse-audits.csv`);
                        }}>Export to CSV</Button>
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}

export default withStyles(useStyles)(LighthouseTable)