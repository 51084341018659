import React, { Fragment } from 'react';
import { useHistory } from "react-router-dom";
import { Grid, AppBar, Toolbar, CircularProgress, Box, Typography, Divider, TableContainer, Table, TableBody, TableCell, TableRow, TableHead, Paper, Collapse, IconButton } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme) => ({
    progressLow: {
        color: '#FF3333'
    },
    progressMedium: {
        color: '#FF9F00'
    },
    progressHigh: {
        color: '#32cd32'
    },
    container: {
        paddingTop: '40px'
    },
    divider: {
        paddingTop: '40px',
        paddingBottom: '20px'
    }
}));

function CircularProgressWithLabel(props) {

    return (
        <Box position="relative" display="inline-flex">
            {(props.refs) ? (
                <div onClick={() => {
                    switch (props.category) {
                        case 'performance':
                            window.scrollTo(0, props.refs.pRef.current.offsetTop);
                            break;
                        case 'accessibility':
                            window.scrollTo(0, props.refs.aRef.current.offsetTop);
                            break;
                        case 'best-practices':
                            window.scrollTo(0, props.refs.bpRef.current.offsetTop);
                            break;
                        case 'seo':
                            window.scrollTo(0, props.refs.seoRef.current.offsetTop);
                            break;
                        case 'pwa':
                            window.scrollTo(0, props.refs.pwaRef.current.offsetTop);
                            break;
                    }
                }}>
                    <CircularProgress variant="static" className={props.className} value={props.value} size={props.size} />
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography variant={props.text} component="div" >{`${Math.round(
                            props.value,
                        )}%`}</Typography>
                    </Box>
                </div>
            ) : (
                    <div>
                        <CircularProgress variant="static" className={props.className} value={props.value} size={props.size} />
                        <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant={props.text} component="div" >{`${Math.round(
                                props.value,
                            )}%`}</Typography>
                        </Box>
                    </div>
                )}
        </Box>
    );
}

function ProgressDisplay(props) {
    return (
        <Grid item>
            <CircularProgressWithLabel className={props.className} value={props.value} size={100} text="h5" />
            <Typography variant="h5">{props.label}</Typography>
        </Grid>
    )
}

function Metrics(props) {
    let metrics = [
        { type: 'First Contentful Paint', value: `${Math.round(((props.data.audits.metrics.details.items[0]["firstContentfulPaint"]) + Number.EPSILON) * 1000) / 1000 / 1000} s` },
        { type: 'Largest Contentful Paint', value: `${Math.round(((props.data.audits.metrics.details.items[0]["largestContentfulPaint"]) + Number.EPSILON) * 1000) / 1000 / 1000} s` },
        { type: 'Speed Index', value: `${Math.round(((props.data.audits.metrics.details.items[0]["speedIndex"]) + Number.EPSILON) * 1000) / 1000 / 1000} s` },
        { type: 'Total Blocking Time', value: `${Math.round(((props.data.audits.metrics.details.items[0]["totalBlockingTime"]) + Number.EPSILON) * 1000) / 1000 / 1000} s` },
        { type: 'Time to Interactive', value: `${Math.round(((props.data.audits.metrics.details.items[0]["interactive"]) + Number.EPSILON) * 1000) / 1000 / 1000} s` },
        { type: 'Cumulative Layout Shift', value: `${Math.round(((props.data.audits.metrics.details.items[0]["cumulativeLayoutShift"]) + Number.EPSILON) * 1000) / 1000 / 1000} s` },
        { type: 'First Input Delay', value: `${Math.round(((props.data.audits.metrics.details.items[0]["maxPotentialFID"]) + Number.EPSILON) * 1000) / 1000 / 1000} s` },
    ]
    return (
        <Fragment>
            <Typography variant="h6">Metrics</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>
                                    Metric
                                </strong>
                            </TableCell>
                            <TableCell>
                                <strong>
                                    Time
                                </strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {metrics.map(row => (
                            <TableRow>
                                <TableCell>{row.type}</TableCell>
                                <TableCell>{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    )
}

function Opportunities(props) {
    let data = props.data.audits;
    let keys = Object.keys(data);
    let result = [];
    for (let i = 0; i < keys.length; i++) {
        if (data[keys[i]].details &&
            data[keys[i]].details["type"] === 'opportunity' &&
            data[keys[i]].details["overallSavingsMs"] > 0) {
            let temp = { title: data[keys[i]].title, value: `${Math.round(((data[keys[i]].details["overallSavingsMs"]) + Number.EPSILON) * 1000) / 1000 / 1000} s` }
            result.push(temp);
        }
    }

    return (
        <Fragment>
            <Typography variant="h6">
                Opportunities
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>Opportunities</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Est. Time-save</strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result.map(row => (
                            <TableRow>
                                <TableCell>{row.title}</TableCell>
                                <TableCell>{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    )
}

function DiagnosticRow(props) {
    let item = props.data
    const [open, setOpen] = React.useState(false);

    let headings = [];
    for (let i = 0; i < item.data.headings.length; i++) {
        let temp = {
            key: item.data.headings[i].key,
            itemType: item.data.headings[i].itemType,
            text: item.data.headings[i].text
        }
        headings.push(temp);
    }

    return (
        <Fragment>
            <TableRow>
                <TableCell>{item.title}</TableCell>
                <TableCell align="right">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {headings.map(heading => {
                                            if (heading.itemType === 'ms') {
                                                return (

                                                    <TableCell value={heading.key}>
                                                        {`${heading.text} (ms)`}
                                                    </TableCell>
                                                )
                                            } else if (heading.itemType === 'bytes') {
                                                return (

                                                    <TableCell value={heading.key}>
                                                        {`${heading.text} (bytes)`}
                                                    </TableCell>
                                                )
                                            } else {
                                                return (
                                                    <TableCell value={heading.key}>
                                                        {heading.text}
                                                    </TableCell>
                                                )
                                            }
                                        })}
                                    </TableRow>
                                </TableHead>
                                {item.data.items.map(row => (
                                    <TableRow>
                                        {headings.map(heading => {
                                            if (typeof row[heading.key] === 'number') {
                                                return (
                                                    <TableCell>{Math.round((row[heading.key] + Number.EPSILON) * 100) / 100}</TableCell>
                                                )
                                            } else if (heading.key === 'entity') {
                                                return (
                                                    <TableCell>{row[heading.key].url}</TableCell>
                                                )
                                            } else if (heading.key === 'node') {
                                                return (
                                                    <TableCell>{row[heading.key].nodeLabel}</TableCell>
                                                )
                                            } else if (row[heading.key] && heading.key === 'element') {
                                                return (
                                                    <TableCell>{row[heading.key].value}</TableCell>
                                                )
                                            } else {
                                                return (
                                                    <TableCell>{row[heading.key]}</TableCell>
                                                )
                                            }
                                        })}
                                    </TableRow>
                                ))}
                            </Table>
                        </TableContainer>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

function Diagnostics(props) {
    let auditData = props.data.audits;
    let auditKeys = Object.keys(auditData);
    let categoryData = props.data.categories.performance.auditRefs;
    let diagArr = [];
    let resultArr = [];
    for (let i = 0; i < categoryData.length; i++) {
        if (categoryData[i].group && categoryData[i].group === 'diagnostics') {
            diagArr.push(categoryData[i].id);
        }
    }

    for (let i = 0; i < auditKeys.length; i++) {
        for (let j = 0; j < diagArr.length; j++) {
            if (auditKeys[i] === diagArr[j]) {
                let temp = {
                    id: diagArr[j],
                    title: auditData[auditKeys[i]].title,
                    data: auditData[auditKeys[i]].details
                };
                resultArr.push(temp);
            }
        }
    }

    let filteredArr = resultArr.filter(item => item.data.type === 'table' && item.data.headings.length > 0);


    return (
        <Fragment>
            <Typography variant="h6">
                Diagnostics
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>Title</strong>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredArr.map(item => (<DiagnosticRow data={item} />))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    )
}

function PassedRow(props) {
    const [open, setOpen] = React.useState(false);
    let item = props.data

    return (
        <Fragment>

            <TableRow>
                <TableCell>
                    <strong>
                        <ReactMarkdown source={item.title} />
                    </strong>
                </TableCell>
                <TableCell align="right">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingTop: 0, paddingBottom: 0 }} colSpan={2}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <ReactMarkdown source={item.description} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

function PassedAudits(props) {
    const [open, setOpen] = React.useState(false);
    const { auditData, type } = props;
    let ids = auditData.categories[type].auditRefs.map(item => item.id);
    let finalIds = [];
    for (let i = 0; i < ids.length; i++) {
        if (auditData.audits[ids[i]].score >= 1) {
            finalIds.push(auditData.audits[ids[i]])
        }
    }

    return (
        <Fragment>
            <Typography variant="h6">
                Passed Audits
            </Typography>
            <TableContainer component={Paper}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {(open) ? <strong>Title</strong> : <strong>Expand...</strong>}
                        </TableCell>
                        <TableCell align="right">
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <TableBody colSpan={2}>
                        {finalIds.map(item => (<PassedRow data={item} />))}
                    </TableBody>
                </Collapse>
            </TableContainer>
        </Fragment>
    )
}

function AdditionalItems(props) {
    const { auditData, type } = props
    let ids = auditData.categories[type].auditRefs.map(item => item.id);
    let finalIds = [];
    for (let i = 0; i < ids.length; i++) {
        if (auditData.audits[ids[i]].scoreDisplayMode === 'manual') {
            finalIds.push(auditData.audits[ids[i]])
        }
    }

    return (
        <Fragment>
            <Typography variant="h6">
                Additional Items to Check Manually
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>Title</strong>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {finalIds.map(item => (<PassedRow data={item} />))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    )
}

function FailedRow(props) {
    let item = props.data
    const [open, setOpen] = React.useState(false);

    let headings = [];
    if (item.details) {
        for (let i = 0; i < item.details.headings.length; i++) {
            let temp = {
                key: item.details.headings[i].key,
                itemType: item.details.headings[i].itemType,
                text: item.details.headings[i].text
            }
            headings.push(temp);
        }
    }

    return (
        <Fragment>
            <TableRow>
                <TableCell>
                    <ReactMarkdown>{item.title}</ReactMarkdown>
                </TableCell>
                <TableCell align="right">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <ReactMarkdown>{item.description}</ReactMarkdown>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {headings.map(item => (
                                            <TableCell value={item.key}>
                                                {item.text}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {(item.details) ? item.details.items.map(row => (
                                    <TableRow>
                                        {headings.map(heading => {
                                            if (typeof row[heading.key] === 'number') {
                                                return (
                                                    <TableCell>{Math.round((row[heading.key] + Number.EPSILON) * 100) / 100}</TableCell>
                                                )
                                            } else if (heading.key === 'entity') {
                                                return (
                                                    <TableCell>{row[heading.key].url}</TableCell>
                                                )
                                            } else if (heading.key === 'node') {
                                                return (
                                                    <TableCell>{row[heading.key].nodeLabel}</TableCell>
                                                )
                                            } else if (row[heading.key] && heading.key === 'element') {
                                                return (
                                                    <TableCell>{row[heading.key].value}</TableCell>
                                                )
                                            } else {
                                                return (
                                                    <TableCell>{row[heading.key]}</TableCell>
                                                )
                                            }
                                        })}
                                    </TableRow>
                                )) : (
                                        <Fragment />
                                    )}
                            </Table>
                        </TableContainer>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

function FailedAudits(props) {
    const { auditData, type } = props;
    let ids = auditData.categories[type].auditRefs.map(item => item.id);
    let finalIds = [];
    for (let i = 0; i < ids.length; i++) {
        if (auditData.audits[ids[i]].scoreDisplayMode === 'binary' && auditData.audits[ids[i]].score === 0) {
            finalIds.push(auditData.audits[ids[i]])
        }
    }


    return (
        <Fragment>
            <Typography variant="h6">
                Failed Audits
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {(finalIds.length > 0) ? (<strong>Title</strong>) : (<strong>Nothing to see here</strong>)}
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {finalIds.map(item => (<FailedRow data={item} />))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    )
}

function parseType(type) {
    if (type === 'pwa-fast-reliable') {
        return (
            <Typography variant="h6">
                Fast & Reliable
            </Typography>
        )
    } else if (type === 'pwa-installable') {
        return (
            <Typography variant="h6">
                Installable
            </Typography>
        )
    } else if (type === 'pwa-optimized') {
        return (
            <Typography variant="h6">
                PWA Optimized
            </Typography>
        )
    }
}

function PWATable(props) {
    const { auditData, type } = props;
    let ids = auditData.categories['pwa'].auditRefs.map(item => ({ id: item.id, type: item.group }));
    ids = ids.filter(item => item.type === type);
    let finalIds = [];
    for (let i = 0; i < ids.length; i++) {
        if (auditData.audits[ids[i].id] && auditData.audits[ids[i].id].scoreDisplayMode === 'binary') {
            finalIds.push(auditData.audits[ids[i].id])
        }
    }


    return (
        <Fragment>
            {parseType(type)}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>
                                <strong>Title</strong>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {finalIds.map(item => (<PWARow data={item} />))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    )
}

function PWARow(props) {
    let item = props.data;
    const [open, setOpen] = React.useState(false);

    return (
        <Fragment>
            <TableRow>
                <TableCell>
                    {(item.score === 0) ? <RemoveCircleIcon style={{ color: 'ff0000' }} /> : <AddCircleIcon style={{ color: '008000' }} />}
                </TableCell>
                <TableCell>
                    <ReactMarkdown>{item.title}</ReactMarkdown>
                </TableCell>
                <TableCell align="right">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <ReactMarkdown>
                            {item.description}
                        </ReactMarkdown>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

function NotApplicable(props) {
    const [open, setOpen] = React.useState(false);
    const { auditData, type } = props;
    let ids = auditData.categories[type].auditRefs.map(item => item.id);
    let finalIds = [];
    for (let i = 0; i < ids.length; i++) {
        if (auditData.audits[ids[i]].scoreDisplayMode === 'notApplicable') {
            finalIds.push(auditData.audits[ids[i]])
        }
    }

    return (
        <Fragment>
            <Typography variant="h6">Not Applicable</Typography>
            <TableContainer component={Paper}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {(open) ? <strong>Title</strong> : <strong>Expand...</strong>}
                        </TableCell>
                        <TableCell align="right">
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <TableBody>
                        {finalIds.map(item => (<NARow data={item} />))}
                    </TableBody>
                </Collapse>
            </TableContainer>
        </Fragment>

    )
}

function NARow(props) {
    let item = props.data
    const [open, setOpen] = React.useState(false);

    return (
        <Fragment>
            <TableRow>
                <TableCell>
                    <ReactMarkdown>{item.title}</ReactMarkdown>
                </TableCell>
                <TableCell align="right">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <ReactMarkdown>
                            {item.description}
                        </ReactMarkdown>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

export default function AuditDisplay(props) {
    let refs = {
        pRef: React.createRef(),
        aRef: React.createRef(),
        bpRef: React.createRef(),
        seoRef: React.createRef(),
        pwaRef: React.createRef(),
    }
    const history = useHistory();
    const { id, type, auditData } = props
    const classes = useStyles();

    function getClassLabel(value) {
        if (value >= 0 && value <= 49) {
            return 'progressLow'
        } else if (value >= 50 && value <= 89) {
            return 'progressMedium'
        } else if (value >= 90 && value <= 100) {
            return 'progressHigh'
        }
    }

    function renderProgress(value, type, classes) {
        let string = getClassLabel(value);

        return (
            <ProgressDisplay className={classes[string]} value={value} label={type} />
        )
    }
    
    if (auditData && (auditData.audits.error && auditData.audits.error === 'null')) {
        return (
            (auditData) ? (
            <Fragment>
                <AppBar>
                        <Toolbar>
                            <Grid container align="center" justify="flex-start" alignItems="center" spacing={1} xs={12}>
                                <Grid container alignItems="flex-start" justify="flex-start" xs={1}>
                                    <IconButton aria-label="go back" size="small" onClick={() => {
                                        history.push({
                                            pathname: '/dashboard/lighthouse-audit'
                                        })
                                    }}>
                                        <ArrowBackIosIcon />
                                    </IconButton>
                                </Grid>
                                <Grid container align="center" justify="space-between" alignItems="center" spacing={1} xs={11}>
                                    <Grid item>
                                        {(type === 'desktop') ? <strong>Desktop Audit</strong> : <strong>Mobile Audit</strong>}
                                    </Grid>
                                    <Grid item>
                                        <strong>{auditData.url}</strong>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                <h1>Error:  Audit Failed on This URL. Please run the audit on this domain again, or audit the URL by hand.</h1>
            </Fragment>
            ) : (
                <div>Loading...</div>
            )
        )
    } else {
        return (
            (auditData)
                ?
                (<Fragment>
                    <AppBar>
                        <Toolbar>
                            <Grid container align="center" justify="flex-start" alignItems="center" spacing={1} xs={12}>
                                <Grid container alignItems="flex-start" justify="flex-start" xs={1}>
                                    <IconButton aria-label="go back" size="small" onClick={() => {
                                        history.push({
                                            pathname: '/dashboard/lighthouse-audit'
                                        })
                                    }}>
                                        <ArrowBackIosIcon />
                                    </IconButton>
                                </Grid>
                                <Grid container align="center" justify="space-between" alignItems="center" spacing={1} xs={11}>

                                    <Grid item>
                                        {(type === 'desktop') ? <strong>Desktop Audit</strong> : <strong>Mobile Audit</strong>}
                                    </Grid>
                                    <Grid item>
                                        <strong>{auditData.url}</strong>
                                    </Grid>
                                    {Object.keys(auditData.categories).map(key => {
                                        let value = auditData.categories[key].score * 100
                                        let colorString;
                                        if (value >= 0 && value <= 49) {
                                            colorString = 'progressLow'
                                        } else if (value >= 50 && value <= 89) {
                                            colorString = 'progressMedium'
                                        } else if (value >= 90 && value <= 100) {
                                            colorString = 'progressHigh'
                                        }
                                        return (
                                            <Grid item direction="column">
                                                <CircularProgressWithLabel className={classes[colorString]} value={value} size={50} text="caption" category={key} refs={refs} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Grid container className={classes.container} align="center" justify="center" alignItems="baseline" spacing={3} xs={12}>
                        {Object.keys(auditData.categories).map(key => {
                            let value = auditData.categories[key].score * 100
                            let label;
                            let colorString;
                            switch (key) {
                                case 'performance':
                                    label = 'Performance';
                                    break;
                                case 'accessibility':
                                    label = 'Accessibility';
                                    break;
                                case 'best-practices':
                                    label = 'Best Practices';
                                    break;
                                case 'seo':
                                    label = 'SEO';
                                    break;
                                case 'pwa':
                                    label = 'Progressive Web-App';
                                    break;
                            }
                            if (value >= 0 && value <= 49) {
                                colorString = 'progressLow'
                            } else if (value >= 50 && value <= 89) {
                                colorString = 'progressMedium'
                            } else if (value >= 90 && value <= 100) {
                                colorString = 'progressHigh'
                            }
                            return (
                                <Grid item direction="column">
                                    <CircularProgressWithLabel className={classes[colorString]} value={value} size={100} text="h5" />
                                    <Typography variant="h5">{label}</Typography>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <div ref={refs.pRef} className={classes.divider}>
                        <Divider />
                    </div>
                    <Grid container align="center" className={classes.container} justify="center" spacing={3} xs={12}>
                        {renderProgress(auditData.categories['performance'].score * 100, 'Performance', classes)}
                    </Grid>
                    <Grid container align="center" justify="center" spacing={3} xs={12}>
                        <Grid item>
                            <Metrics data={auditData} />
                        </Grid>
                        <Grid item>
                            <Opportunities data={auditData} />
                        </Grid>
                        <Grid item>
                            <Diagnostics data={auditData} />
                        </Grid>
                        <Grid item>
                            <PassedAudits auditData={auditData} type="performance" />
                        </Grid>
                    </Grid>
                    <div ref={refs.aRef} className={classes.divider}>
                        <Divider />
                    </div>
                    <Grid container align="center" className={classes.container} justify="center" spacing={3} xs={12}>
                        {renderProgress(auditData.categories['accessibility'].score * 100, 'Accessibility', classes)}
                    </Grid>
                    <Grid container align="center" justify="center" spacing={3} xs={12}>
                        <Grid item>
                            <FailedAudits auditData={auditData} type="accessibility" />
                        </Grid>
                        <Grid item>
                            <AdditionalItems auditData={auditData} type="accessibility" />
                        </Grid>
                        <Grid item>
                            <PassedAudits auditData={auditData} type="accessibility" />
                        </Grid>
                        <Grid item>
                            <NotApplicable auditData={auditData} type="accessibility" />
                        </Grid>
                    </Grid>
                    <div ref={refs.bpRef} className={classes.divider}>
                        <Divider />
                    </div>
                    <Grid container align="center" className={classes.container} justify="center" spacing={3} xs={12}>
                        {renderProgress(auditData.categories['best-practices'].score * 100, 'Best Practices', classes)}
                    </Grid>
                    <Grid container align="center" justify="center" spacing={3} xs={12}>
                        <Grid item>
                            <FailedAudits auditData={auditData} type="best-practices" />
                        </Grid>
                        <Grid item>
                            <PassedAudits auditData={auditData} type="best-practices" />
                        </Grid>
                    </Grid>
                    <div ref={refs.seoRef} className={classes.divider}>
                        <Divider />
                    </div>
                    <Grid container align="center" className={classes.container} justify="center" spacing={3} xs={12}>
                        {renderProgress(auditData.categories['seo'].score * 100, 'SEO', classes)}
                    </Grid>
                    <Grid container align="center" justify="center" spacing={3} xs={12}>
                        <Grid item>
                            <FailedAudits auditData={auditData} type="seo" />
                        </Grid>
                        <Grid item>
                            <AdditionalItems auditData={auditData} type="seo" />
                        </Grid>
                        <Grid item>
                            <PassedAudits auditData={auditData} type="seo" />
                        </Grid>
                        <Grid item>
                            <NotApplicable auditData={auditData} type="seo" />
                        </Grid>
                    </Grid>
                    <div ref={refs.pwaRef} className={classes.divider}>
                        <Divider />
                    </div>
                    <Grid container align="center" className={classes.container} justify="center" spacing={3} xs={12}>
                        {renderProgress(auditData.categories['pwa'].score * 100, 'Progressive Web-App', classes)}
                    </Grid>
                    <Grid container align="center" justify="center" spacing={3} xs={12}>
                        <Grid item>
                            <PWATable auditData={auditData} type="pwa-fast-reliable" />
                        </Grid>
                        <Grid item>
                            <PWATable auditData={auditData} type="pwa-installable" />
                        </Grid>
                        <Grid item>
                            <PWATable auditData={auditData} type="pwa-optimized" />
                        </Grid>
                        <Grid item>
                            <AdditionalItems auditData={auditData} type="pwa" />
                        </Grid>
                    </Grid>
                    <Divider />
                </Fragment>)
                :
                (<div>Loading...</div>)
        )
    }
}