import React from 'react';
import '../styles/styles.css'

const Emoji = ({ currentClass, label, symbol }) => (
    <div
        className={currentClass}
        role="img"
        aria-label={label ? label : ""}
        aria-hidden={label ? "false" : "true"}
    >
        {symbol}
    </div>
);

export default Emoji;