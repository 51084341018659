import React, { Component, Fragment } from 'react';

class EmployeeTimeOff extends Component {
    
    render() {
        return (
            <h1>This part of the website is currently under construction, please come back later.</h1>
        )
    }
}

export default EmployeeTimeOff;