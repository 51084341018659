import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import crypto from 'crypto';
import { renderEmail } from 'react-html-email';
import {Button, TextField} from '@material-ui/core';

import RegistrationEmail from '../components/EmailTemplate';

import { statelessComponentStyles } from '../styles/styles';

const ForgotPasswordPage = () => {
  const history = useHistory();
  const classes =  statelessComponentStyles();
  const [errorStatus, setErrorStatus] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');

  const validateUser = async () => {
    if (emailAddress.trim().length > 0 && !errorStatus) {
      try {
        let response = await axios.get('/validate-user', {
          params: {
            email: emailAddress,
            resetPasswordToken: '',
          }
        });
        if(response.data === 'user does not exist'){
          history.push({
            pathname: '/process-outcome',
            message: "The email address isn't recognized. Please try again or register for a new account",
            link: '/register'
          })
        } else {
          const registrationMessage = `Hello. We received your request for a password reset. If you didn't request for this, you can ignore this message. Otherwise, kindly reset your password using`
          const resetPasswordToken = crypto.pseudoRandomBytes(20).toString('hex');
          const link = `${process.env.REACT_APP_ENV_URL}/reset-password?e=${resetPasswordToken}`
          const messageHtml = renderEmail(<RegistrationEmail message={registrationMessage} link={link} />);
          const response = await axios({
            method: "POST",
            url: "/reset-password",
            data: {
              emailAddress,
              resetPasswordToken,
            }
          });

          if(response.data !== "token added successfully"){
            history.push({
              pathname: '/process-outcome',
              message: "Oops, something went wrong. Please try again",
              link: '/register'
            })
          }

          const emailResponse = await axios({
            method: "POST",
            url: "/send-email",
            data: {
              receiverEmail: emailAddress,
              messageHtml: messageHtml,
              senderEmail: 'noreply@wlabel.co',
              subject: 'Password Reset Link'
            }
          });

          if (emailResponse.data.msg === 'success') {
            history.push({
              pathname: '/process-outcome',
              message: "Password Reset Email Successfully Sent!",
              link: '/register'
            })

          } else if (emailResponse.data.msg === 'fail') {
            history.push({
              pathname: '/process-outcome',
              message: "Oops, something went wrong. Please try again",
              link: '/register'
            })
          }
        }
      } catch (e) {
        console.log(e.toString());
      }
    }
  }

  const keyPressed = async (event) => {
    if (!errorStatus && event.key === "Enter") {
       event.preventDefault();
       await validateUser();
     }
  }

  const validateEmail = (event) => {
    const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const email = event.target.value;

    if(email.match(EMAIL_REGEX)){
      setEmailAddress(email);
      setErrorStatus(false);
    } else{
      setErrorStatus(true);
    }
  }

  return (
    <div class={classes.form}>
      <h1>To reset your password, kindly enter your registered email address</h1>
      <div class={classes.formControl}>
        <TextField
          required={!errorStatus}
          error={errorStatus}
          id="outlined-error-helper-text"
          helperText={errorStatus ? "Invalid email address." : ""}
          label="Email address"
          onChange={validateEmail}
          variant="outlined"
          onKeyPress={keyPressed}
        />
      </div>
      <div>
        <Button
          onClick={validateUser}
        >Continue</Button>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;