import React, { Component, Fragment } from 'react';
import Button from './Button';
import { CircularProgress, Box, Typography } from '@material-ui/core';
import '../styles/styles.css';
import { Chart } from 'react-charts';
import moment from 'moment';
import styled from 'styled-components';
import axios from 'axios';

const HARVEST_CLIENT_ID = process.env.REACT_APP_HARVEST_CLIENT_ID;

const Module = styled.div`
    width: 100%;
    height: 99%;
    ${props => props.authenticated ? `` : `filter: blur(18px);`}
    border-radius: 2px;
    border: solid 1px #2b2b2d;
    display: flex;
    flex-direction: column;
`;

const defs = (
    <defs>
      <linearGradient id="0" x1="0" x2="0" y1="1" y2="0" gradientTransform="rotate(11)">
        <stop offset="-63%" stopColor="#31ff98" />
        <stop offset="93%" stopColor="#3fd0f2" />
      </linearGradient>
      <linearGradient id="1" x1="0" x2="0" y1="1" y2="0" gradientTransform="rotate(47)">
        <stop offset="1%" stopColor="#dd2476" />
        <stop offset="96%" stopColor="#9358f5" />
      </linearGradient>
    </defs>
  )

  
  const CircularProgressWithLabel = ({ style, value }) => {
      return (
        <Box position="relative" display="inline-flex">
            <div>

          <CircularProgress variant="static" style={style} value={value} size={84} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            >
            <div class="progressLabel">{`${Math.round(
                value
                )}%`}</div>
          </Box>
                </div>
        </Box>
      );
    }

class EDashHours extends Component {

    constructor(props){
        super(props);

        this.state = {
            lastUpdated: '',
            data: [],
            billableTime: 0,
            nonBTime: 0,
            weeklyHours: 35
        }
    }

    async componentDidMount() {
        let { timeData, today } = this.props;
        let lastUpdated = today.format('MMMM Do, @ h:mm a');

        if (this.props.authenticated) {
            let data = await axios({
                method: 'get',
                url: `/yearly-target?year=${moment().year()}`,
                headers: {
                    'Content-Type': 'applcation/json'
                }
            });

            let weeklyHours = 35;
            if (data.data[0] && data.data[0].schedule === 'part-time') {
                weeklyHours = 5 * 3.5;
            }

            this.setState({
                ...this.state,
                weeklyHours,
                lastUpdated
            });
        } else {
            this.setState({
                ...this.state,
                lastUpdated
            })
        }

    }

    parseTimeDataForHours = (timeData, today) => {
        let billableTime = 0 
        let nonBTime = 0;

        let weeklyTimeData = timeData.filter(item => 
            moment(item.spent_date).unix() > moment(today).subtract('8', 'days').unix() && 
            moment(item.spent_date).unix() <= moment(today).unix()
        );

        for(let i = 0; i < weeklyTimeData.length; i++) {
            if (weeklyTimeData[i].billable) {
                billableTime += weeklyTimeData[i].hours
            } else {
                nonBTime += weeklyTimeData[i].hours
            }
        }

        return { billableTime, nonBTime };
    }

    parseTimeDataToChartData = (timeData, today) => {
        // Su, M, T, W, TR, F, Sa
        let dayCount = [0, 0, 0, 0, 0, 0, 0];

        let sortedTimeData = timeData.filter(item => moment(item.spent_date).unix() > moment(today).subtract('12', 'days').unix() );

        let finalData =[{data: []}, {data: []}];

        for (let i = 11; i >= 0; i--) {
            let day = moment(today).subtract(i, 'days');
            dayCount[day.day()] = dayCount[day.day()] + 1;
            let dayString = `${day.format('dddd').slice(0, 2)}${dayCount[day.day()]}`;
            let totalBillableHours = 0;
            let totalNonBHours = 0;
            sortedTimeData.forEach(item => {
                if (day.format('YYYY-MM-DD') == item.spent_date) {
                    if (item.billable) {
                        totalBillableHours = totalBillableHours + item.hours;
                    } else {
                        totalNonBHours = totalNonBHours + item.hours;
                    }
                }
            });

            finalData[0].data.push([dayString, totalBillableHours]);
            finalData[1].data.push([dayString, totalNonBHours])

        }

        return finalData;
    }

    formatX = (label) => {
        switch (label){ 
            case 'Mo1':
                return 'M';
            case 'Tu1':
                return 'T';
            case 'We1':
                return 'W';
            case 'Th1':
                return 'T';
            case 'Fr1':
                return 'F';
            case 'Sa1':
                return 'S';
            case 'Sa2':
                return 'S';
            case 'Mo2':
                return 'M';
            case 'Tu2':
                return 'T';
            case 'We2':
                return 'W';
            case 'Th2':
                return 'T';
            case 'Fr2':
                return 'F';
            case 'Su1':
                return 'S';
            case 'Su2':
                return 'S';
        }
    }

    
    render(){
        const { timeData, today, authenticated } = this.props;
        const data = this.parseTimeDataToChartData(timeData, today);
        const hours = this.parseTimeDataForHours(timeData, today);

        const series = {
            type: "bar"
        }

        const getSeriesStyle = (series) =>({
              color: `url(#${series.index % 2})`,
        })

        const axes = [
            { primary: true, type: 'ordinal', position: 'bottom', format: this.formatX},
            { type: 'linear', position: 'left', stacked: true, hardMin: 0, hardMax: 9, showGrid: false, tickValues: [0, 2, 4, 6, 8, 10, 12]}
        ]

        return(
            <div class="module1">
                <p class="moduleText moduleText1">Hours</p>
                <div class="moduleBorder">

                {(authenticated) ? (
                    <Fragment />
                ) : (
                    <div class="authHarvest" onClick={() => {
                        window.location = `https://id.getharvest.com/oauth2/authorize?client_id=${HARVEST_CLIENT_ID}&response_type=code&state=${`EDashboard`}` ;
                        }}
                    >
                        <Button arrow>Reauthenticate Harvest</Button>
                    </div>
                )}

                    <Module authenticated={authenticated}>
                        <div class="hoursBreakdown">
                            <div class="BarChart">
                                <Chart data={data} series={series} axes={axes} getSeriesStyle={getSeriesStyle} renderSVG={() => defs} dark>

                                </Chart>
                            </div>
                            <div class="hoursDayOverview">
                                <div class="hoursDayOverviewText">
                                    7-Day Overview
                                </div>
                                <div class="hoursDayOverviewInformation">
                                    <div class="circularProgress">
                                        <CircularProgressWithLabel style={{ color: '#3fd0f2' }} value={
                                            (Math.round((hours.billableTime / this.state.weeklyHours) * 100) > 100) ?
                                                (100) :
                                                (Math.round((hours.billableTime / this.state.weeklyHours) * 100))
                                        }>

                                        </CircularProgressWithLabel>
                                    </div>
                                    <div class="outerHoursKey">
                                        <div class="billableText">
                                            Billable Hours
                                        </div>
                                        <div class="billableHours">
                                            <div class="billableOval">

                                            </div>
                                            <div class="hoursText">
                                                {Math.round(hours.billableTime)} Billable
                                            </div>
                                        </div>
                                        <div class="nonBHours">
                                            <div class="nonBOval">

                                            </div>
                                            <div class="hoursText">
                                                {Math.round(hours.nonBTime)} Non Billable
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="dividerHorizontal" />
                        <div class="hoursInformation">
                            <div class="hoursLastUpdated">
                                Last Updated: {this.state.lastUpdated}
                            </div>
                            <div class="harvestLink" onClick={() => {
                                if (authenticated) {
                                    window.open('https://harvestapp.com');
                                }
                            }}
                            >
                                <Button arrow disabled={!authenticated}>
                                    Harvest
                            </Button>
                            </div>
                        </div>
                    </Module>
                </div>
            </div>
        )
    }
}

export default EDashHours;