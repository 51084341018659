import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { ApplicationContext } from '../context'
import GooglePicker from './GooglePicker';
import { Button, Grid, TextField, CircularProgress, Switch, FormControlLabel } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import SheetTable from './SheetTable';
import { AppCtxConsumer } from '../context'

const filter = createFilterOptions();

class GithubProjectForm extends Component {
    static contextType = ApplicationContext;
    constructor() {
        super();
        this.state = {
            selection: {
                'inputValue': '',
                'value': ''
            },
            body: {
                project: {
                    'name': '',
                    'body': ''
                },
                repo: {
                    'name': '',
                    'id': '',
                },
                sheets: null,
                repoLevelCreation: false,
                exisitingRepo: false,

            }
        }
    }
    handleNameChange = (e) => {
        this.setState({ ...this.state, body: { ...this.state.body, project: { ...this.state.body.project, 'name': e.target.value } } })
    }

    handleDescChange = (e) => {
        this.setState({ ...this.state, body: { ...this.state.body, project: { ...this.state.body.project, 'body': e.target.value } } })
    }

    handleRepoChange = (e) => {
        this.setState({ ...this.state, body: { ...this.state.body, repo: { ...this.state.body.repo, 'name': e.target.value } } })
    }

    handleSheetSelect = (e) => {
        this.setState({ ...this.state, body: { ...this.state.body, sheets: e } })
    }

    handleDisable = () => {
        if (this.state.body.project.name !== "" && this.state.body.repo.name !== "" && this.state.body.sheets != null) {
            return false
        } else {
            return true
        }
    }

    handleCompleteChange = (e, nv) => {
        this.setState({
            ...this.state,
            selection: {
                ...this.state.selection,
                value: nv
            },
            body: {
                ...this.state.body,
                repo: {
                    ...this.state.body.repo,
                    name: nv.name,
                    id: nv.id
                }
            }
        })
    }

    renderTable = (sheetData) => {
        if (sheetData !== null) {
            return (
                <SheetTable data={sheetData} />
            )
        } else {
            return (
                <Fragment />
            )
        }
    }

    render() {
        return (
            <AppCtxConsumer>
                {(context) => {
                    return (
                        <Grid container align="center" justify="center" spacing={2} xs={12}>
                            <Grid item xs={5}>
                                <h1>Create a Github Project</h1>
                            </Grid>
                            <Grid container align="center" justify="center" spacing={2} xs={8}>
                                <Grid item xs={4}>
                                    <TextField id="standard-basic-project-name" label="Project Name *" variant="standard" onChange={this.handleNameChange}>{this.state.body.name}</TextField>
                                    <FormControlLabel
                                        control={<Switch checked={this.state.body.repoLevelCreation} onChange={(e) => {
                                            this.setState({ ...this.state, body: { ...this.state.body, repoLevelCreation: e.target.checked } })
                                        }} />}
                                        label={this.state.body.repoLevelCreation ? "Repo Level" : "Org Level"}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    {(this.state.body.exisitingRepo) ? (
                                        <Autocomplete id="Search Select"
                                            clearOnEscape={true}
                                            options={context.githubRepos}
                                            value={this.state.selection.value}
                                            onChange={this.handleCompleteChange}
                                            getOptionLabel={(option) => option.name}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);
                                                return filtered;
                                            }}
                                            inputValue={this.state.selection.inputValue}
                                            onInputChange={(e, niv) => {
                                                this.setState({
                                                    ...this.state,
                                                    selection: {
                                                        ...this.state.selection,
                                                        inputValue: niv
                                                    }
                                                })
                                            }}
                                            style={{ width: 200 }}
                                            renderInput={(params) => <TextField {...params} label="Select A Repo" variant="standard" />}
                                        />
                                    ) : (
                                        <TextField id="standard-basic-repo-name" label="Repository Name *" variant="standard" onChange={this.handleRepoChange}>{this.state.body.repo.name}</TextField>
                                    )}
                                    <FormControlLabel
                                        control={<Switch checked={this.state.body.exisitingRepo} onChange={(e) => {
                                            this.setState({ ...this.state, body: { ...this.state.body, exisitingRepo: e.target.checked } })
                                        }} />}
                                        label={this.state.body.exisitingRepo ? "Use Existing Repo" : "Create Repo"}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField id="standard-multiline-flexible" label="Notes" multiline rowsMax={20} onChange={this.handleDescChange}></TextField>
                                </Grid>
                                <Grid item xs={4}>
                                    <GooglePicker handleSelect={this.handleSheetSelect} />
                                </Grid>
                                <Grid item xs={12}>
                                    {this.renderTable(this.state.body.sheets)}
                                </Grid>
                                <Grid item xs={4}>
                                    <Button disabled={this.handleDisable()} variant="contained" color="secondary" onClick={() => {
                                        this.setState({ ...this.state, loading: true }, () => {
                                            axios({
                                                url: '/api/github/projects',
                                                method: 'post',
                                                data: this.state.body,
                                                timeout: 1000 * 60 * 20,
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                }
                                            }).then(result => {this.setState({
                                                ...this.state,
                                                loading: false
                                            })})
                                        })
                                    }}>Create Project</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    {this.state.loading ? <CircularProgress /> : <Fragment />}
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }}
            </AppCtxConsumer>

        )
    }
}

export default GithubProjectForm;