import React, { Fragment } from 'react';
import {
    Grid,
    CircularProgress,
    Box,
    Typography,
    Table,
    TableContainer,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@material-ui/core';
import {
    findTotal,
    findBillableTotal,
    findNonBillableTotal,
    findTakenDays
} from '../functions/BillableTargetHoursCalculations';

const CircularProgressWithLabel = (props) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="static" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="h5" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const YearlyBreakdown = ({ timeData, year, workDays, targetHours }) => {
    let dialPercentage = (findBillableTotal(timeData) / targetHours) * 100;
    return (
        <Fragment>
            <Grid item>
                <h3>{year}: Breakdown for {timeData[0].user.name}</h3>
            </Grid>
            <Grid container direction="row" justify="center" alignItems="center" xs={12}>
                <Grid container direction="column" justify="center" alignItems="center" xs={3}>
                    <Grid item>
                        <Typography variant="h6">
                            <u>Target Hours:</u>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h7">
                            {targetHours}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container xs={4} justify="center" alignItems="center" xs={3}>
                    <Grid item>
                        <CircularProgressWithLabel value={dialPercentage} size={100} />
                    </Grid>
                </Grid>
                <Grid container direction="column" justify="center" alignItems="center" xs={3}>
                    <Grid item>
                        <Typography variant="h6">
                            <u>Total Workdays:</u>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h7">
                            {workDays.workDays - 15}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Grid item>
                        <Typography variant="h6">
                            <u>Remaining Hours:</u>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h7">
                            {((targetHours - findBillableTotal(timeData)) * 100 / 100).toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={{ paddingTop: '20px', paddingBottom: '20px' }} direction="row" justify="center" alignItems="center" spacing={4}>
                    <Grid item>
                        <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                            <TableContainer component={Paper}>
                                <Table className={{ minWidth: '650px' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Hours</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Total</TableCell>
                                            <TableCell align="right">{((findTotal(timeData)) * 100 / 100).toFixed(2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Total Billable</TableCell>
                                            <TableCell align="right">{((findBillableTotal(timeData)) * 100 / 100).toFixed(2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Non-Billable Total</TableCell>
                                            <TableCell align="right">{((findNonBillableTotal(timeData)) * 100 / 100).toFixed(2)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                            <TableContainer component={Paper}>
                                <Table className={{ minWidth: '650px' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Days Taken</TableCell>
                                            <TableCell>Days Remaining</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Vacation</TableCell>
                                            <TableCell align="right">{(findTakenDays(timeData, 'Vacation')).toFixed(2)}</TableCell>
                                            <TableCell align="right">{(10 - findTakenDays(timeData, 'Vacation')).toFixed(2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Sick</TableCell>
                                            <TableCell align="right">{(findTakenDays(timeData, 'Sick Day')).toFixed(2)}</TableCell>
                                            <TableCell align="right">{(5 - findTakenDays(timeData, 'Sick Day')).toFixed(2)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                            <TableContainer component={Paper}>
                                <Table className={{ minWidth: '650px' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Days</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Bereavement</TableCell>
                                            <TableCell align="right">{(findTakenDays(timeData, 'Bereavement Leave')).toFixed(2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Bonus Days <strong>*</strong></TableCell>
                                            <TableCell align="right">{(findTakenDays(timeData, 'Bonus Day')).toFixed(2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Parental Leave</TableCell>
                                            <TableCell align="right">{(findTakenDays(timeData, 'Parental Leave')).toFixed(2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Jury Duty</TableCell>
                                            <TableCell align="right">{(findTakenDays(timeData, 'Jury Duty')).toFixed(2)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                </Grid>
                <Grid item>
                    <p><a href="https://docs.google.com/document/d/14VhmPtB5l5KrkDIDJeYlUQ0rlg8ZPzu6n2JEtVWISo8/edit#bookmark=id.7h30t2f5vl0"><strong>*</strong> Learn more about Bonus Days</a></p>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default YearlyBreakdown