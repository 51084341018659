import React, { Component, Fragment } from 'react';
import {
    Button,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { formatISO } from 'date-fns';
import axios from 'axios';
import moment from 'moment';
import Header from '../components/Header';
import lodash from 'lodash';

const years = lodash.range(2021, 2100);

class SetYearlyTargetPage extends Component {
    constructor() {
        super();

        this.state = {
            emailAddress: '',
            newYear: '',
            year: moment().year(),
            target: '',
            currentTargets: [],
            start_date: null,
            schedule: 'full-time'
        }

        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleScheduleChange = this.handleScheduleChange.bind(this)
    }

    async componentDidMount() {
        let data = await axios({
            method: 'get',
            url: `/yearly-targets?year=${moment().year()}`,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        this.setState({
            ...this.state,
            currentTargets: data.data
        })
    }

    handleYearChange = async (e) => {
        let data = await axios({
            url: `/yearly-targets?year=${e.target.value}`,
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        this.setState({
            year: e.target.value,
            currentTargets: data.data
        })
    }

    handleEmailChange = (event) => {
        this.setState({
            ...this.state,
            emailAddress: event.target.value
        });
    }

    handleTargetChange = (event) => {
        this.setState({
            ...this.state,
            target: event.target.value
        });
    }

    handleClick = async () => {
        let data = await axios({
            method: 'post',
            url: '/edit-yearly-target',
            data: {
                year: this.state.year,
                email: this.state.emailAddress,
                target: this.state.target,
                start_date: this.state.start_date,
                schedule: this.state.schedule
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });
        alert(data.data.message);
    }

    handleStartDateChange (date) {
        this.setState({
            ...this.state,
            start_date: date
        })
    }

    handleScheduleChange (event) {
        this.setState({
            ...this.state,
            schedule: event.target.value
        })
    }

    render() {
        return (
            <Fragment>
                <Header showLogoutButton showBackButton title="Process Access Requests" />
                <Grid container justify="center" alignItems="center" spacing={3} xs={12}>
                    <Grid item>
                        <h3>Select a year, enter a user's email, yearly target, and start date</h3>
                    </Grid>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={3} xs={12}>
                        <Grid item>
                            <FormControl>
                                <InputLabel>Year</InputLabel>
                                <Select
                                    labelId="holiday-year-select"
                                    id="year-select"
                                    value={this.state.year}
                                    onChange={this.handleYearChange}
                                >
                                    {years.map(item => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Users email address"
                                defaultValue={this.state.emailAddress}
                                onChange={this.handleEmailChange}
                                style={{ width: '16rem' }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="new yearly target"
                                defaultValue={this.state.target}
                                onChange={this.handleTargetChange}
                                style={{ width: '16rem' }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="start-date"
                                    label="Start Date"
                                    value={this.state.start_date}
                                    onChange={this.handleStartDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item>
                            <Select
                                labelId="schedule-select-label"
                                id="schedule-select"
                                value={this.state.schedule}
                                onChange={this.handleScheduleChange}
                            >
                                <MenuItem value={'full-time'}>Full-Time</MenuItem>
                                <MenuItem value={'part-time'}>Part-Time</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" onClick={this.handleClick}>Sumbit New Target</Button>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Email Address</TableCell>
                                        <TableCell>Year</TableCell>
                                        <TableCell>Target</TableCell>
                                        <TableCell>Start Date</TableCell>
                                        <TableCell>Schedule</TableCell> 
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.currentTargets.map(item => (
                                        <TableRow>
                                            <TableCell>{item.email}</TableCell>
                                            <TableCell>{item.year}</TableCell>
                                            <TableCell>{item.target}</TableCell>
                                            <TableCell>{item.start_date}</TableCell>
                                            <TableCell>{item.schedule}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}

export default SetYearlyTargetPage;