import React, { Component } from 'react';
import {
    TableRow,
    TableCell,
} from '@material-ui/core';
import moment from 'moment';

const findTotalTakenVacation = (timeData, year, end) => {
    let vacation = 0;
    for (let i = 0; i < timeData.length; i++) {
        let date = moment(timeData[i].spent_date).format('MM/DD/YYYY');
        if (timeData[i].task.name === 'Vacation' &&
            (date === moment(`01/01/${year}`) ||
                date === end ||
                moment(timeData[i].spent_date).isBetween(moment(`01/01/${year}`), moment(end)))) {
            let temp = timeData[i].hours / 7;
            vacation += temp;
        }
    }
    return vacation;
}

const findTotalTakenSick = (timeData, year, end) => {
    let sick = 0;
    for (let i = 0; i < timeData.length; i++) {
        let date = moment(timeData[i].spent_date).format('MM/DD/YYYY')
        if (timeData[i].task.name === 'Sick Day' &&
            (date === moment(`01/01/${year}`) ||
                date === end ||
                moment(timeData[i].spent_date).isBetween(moment(`01/01/${year}`), moment(end)))) {
            let temp = timeData[i].hours / 7;
            sick += temp;
        }
    }
    return sick;
}

const findQtrTakenVacation = (timeData, start, end) => {
    let vacation = 0;
    for (let i = 0; i < timeData.length; i++) {
        let date = moment(timeData[i].spent_date).format('MM/DD/YYYY');
        if (timeData[i].task.name === 'Vacation' &&
            (date === start ||
                date === end ||
                moment(timeData[i].spent_date).isBetween(moment(start), moment(end)))) {
            let temp = timeData[i].hours / 7;
            vacation += temp;
        }
    }
    return vacation;
}


const findQtrTakenSick = (timeData, start, end) => {
    let sick = 0;
    for (let i = 0; i < timeData.length; i++) {
        let date = moment(timeData[i].spent_date).format('MM/DD/YYYY');
        if (timeData[i].task.name === 'Sick Day' &&
            (date === start ||
                date === end ||
                moment(timeData[i].spent_date).isBetween(moment(start), moment(end)))) {
            let temp = timeData[i].hours / 7;
            sick += temp;
        }
    }
    return sick;
}

const findExpectedHours = (vacation, sick, start, end, dailyHours) => {
    let days = 0;

    for (let i = moment(start).dayOfYear(); i <= moment(end).dayOfYear(); i++) {
        if (moment().dayOfYear(i).day() !== 0 && moment().dayOfYear(i).day() !== 6) {
            days++;
        }
    }
    let hours = (days - vacation - sick) * dailyHours
    return hours;
}

const findActualBillableHours = (timeData, start, end) => {
    let hours = 0;
    for (let i = 0; i < timeData.length; i++) {
        let date = moment(timeData[i].spent_date).format('MM/DD/YYYY')
        if (timeData[i].billable && (
            date === start ||
            date === end ||
            moment(timeData[i].spent_date).isBetween(moment(start), moment(end)))) {
            hours += timeData[i].hours
        }
    }
    return hours;
}

class QuarterlyBreakdown extends Component {
    constructor(props) {
        super(props);
        let { qtr, timeData, year, workDays, targetHours } = this.props;
        this.state = {
            qtr,
            timeData,
            year,
            workDays,
            targetHours,
        }
    }

    render() {
        let start, end;
        if (this.state.qtr === 1) {
            start = moment(`01/01/${this.state.year}`).format('MM/DD/YYYY');
            end = moment(`03/31/${this.state.year}`).format('MM/DD/YYYY');
        } else if (this.state.qtr === 2) {
            start = moment(`04/01/${this.state.year}`).format('MM/DD/YYYY');
            end = moment(`06/30/${this.state.year}`).format('MM/DD/YYYY');
        } else if (this.state.qtr === 3) {
            start = moment(`07/01/${this.state.year}`).format('MM/DD/YYYY');
            end = moment(`09/30/${this.state.year}`).format('MM/DD/YYYY');
        } else if (this.state.qtr === 4) {
            start = moment(`10/01/${this.state.year}`).format('MM/DD/YYYY');
            end = moment(`12/31/${this.state.year}`).format('MM/DD/YYYY');
        }

        let qtrSick = findQtrTakenSick(this.state.timeData, start, end);
        let qtrVacation = findQtrTakenVacation(this.state.timeData, start, end);
        let totalSick = findTotalTakenSick(this.state.timeData, this.state.year, end);
        let totalVacation = findTotalTakenVacation(this.state.timeData, this.state.year, end);
        let actualHours = findActualBillableHours(this.state.timeData, start, end);
        let expectedHours = findExpectedHours(qtrVacation, qtrSick, start, end, this.state.workDays.dailyHours);

        return (
            <TableRow>
                <TableCell><strong>Q{this.state.qtr} Hours</strong></TableCell>
                <TableCell align="right">{actualHours.toFixed(2)}</TableCell>
                <TableCell align="right">{expectedHours.toFixed(2)}</TableCell>
                <TableCell align="right">{(actualHours - expectedHours > 0) ? ((actualHours - expectedHours).toFixed(2)) : (0)}</TableCell>
                <TableCell align="right">{((actualHours / expectedHours) * 100).toFixed(2)} %</TableCell>
                <TableCell align="right">{(qtrSick).toFixed(2)}</TableCell>
                <TableCell align="right">{(5 - totalSick).toFixed(2)}</TableCell>
                <TableCell align="right">{qtrVacation.toFixed(2)}</TableCell>
                <TableCell align="right">{(10 - totalVacation).toFixed(2)}</TableCell>
            </TableRow>
        )
    }
}

export default QuarterlyBreakdown;