import React, { Component } from 'react';
import axios from 'axios';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend, } from 'recharts';
import  {
  Button,
  Collapse,
  createMuiTheme,
  FormControl,
  InputLabel,
  Select,
  ThemeProvider,
  withStyles
} from '@material-ui/core';
import Header from '../components/Header';
import { classComponentStyles } from '../styles/styles';

const theme =  createMuiTheme({
  overrides: {
    MuiFormControl: {
          root: {
              marginTop: "1rem",
              minWidth: "15rem",
          },
      }
  }
})

class ProjectDomainKnowledgePage extends Component {
  constructor(){
    super();
    this.state = {
      expandChart: false,
      isLoading: true,
      projects: [],
      setOfProjects: new Set(),
      projectName: '',
      groupOfSelectedTasks: [],
      users: null,
    }
  }

  async componentDidMount(){
    try{
      const projectData = await axios({
        url: '/api/harvest/projects',
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        }
      })
      let setOfProjects = new Set();
      projectData.data.projects.forEach(async(project) => {
        const projectName = project.name;
        setOfProjects.add(projectName);
        let tasksAndHours = [];
        let taskAssignments = await axios({
          url: '/api/harvest/tasks',
          method: 'post',
          headers: {
              'Content-Type': 'application/json'
          },
          data: {
              id: project.id
          }
        })
        let tasks = new Set();
        let users = new Set();
        taskAssignments.data.task_assignments.forEach(taskAssignment => tasks.add(taskAssignment.task.name))

        let timeEntries = await axios({
          url: '/api/harvest/project_time_entries',
          method: 'post',
          headers: {
              'Content-Type': 'application/json'
          },
          data: {
              projectId: project.id
          }
        })
        tasks.forEach(task =>  {
          const filteredTasks = timeEntries.data.filter(timeEntry =>  {
            users.add(timeEntry.user.name);
            return timeEntry.task.name === task
          })
          let usersTotalHours = new Map();
          filteredTasks.forEach(filteredTask => {
            const userName = filteredTask.user.name;
            if(usersTotalHours.has(userName)){
              usersTotalHours.set(userName, Math.floor(usersTotalHours.get(userName) + filteredTask.rounded_hours));
            } else {
              usersTotalHours.set(userName, Math.floor(filteredTask.rounded_hours));
            }
          })
          const hoursSpentOnTask = Object.create({ task: '', users: null });
          hoursSpentOnTask.task = task;
          hoursSpentOnTask.users = users;
          users.forEach(user => {
            hoursSpentOnTask[user] = usersTotalHours.has(user) ? usersTotalHours.get(user) : 0;
          })
          tasksAndHours.push(hoursSpentOnTask);
        })
        let projects = Object.create({});
        projects[projectName] = tasksAndHours;
        this.setState({ projects: [...this.state.projects, projects] });
      });
      this.setState({ isLoading: false, setOfProjects });
    } catch(e){
      console.log(e.toString());
    }
  }

  handleProjectSelection = (event) => {
    const projectName = event.target.value;
    const { projects } = this.state;
    if (!projectName || projectName.trim().length === 0){
      this.setState({ expandChart: false, projectName: '', groupOfSelectedTasks: [], users: null});
    } else {
      const groupOfSelectedTasks = projects.find(project=> Object.keys(project)[0] === projectName)
      if(groupOfSelectedTasks && groupOfSelectedTasks[projectName]){
        groupOfSelectedTasks[projectName].some(name => {
          if(name.users.size > 0){
            return this.setState({users: name.users});
          }
          return this.setState({users: null})
        })
      }
      this.setState({ projectName, groupOfSelectedTasks })
    }
  };

  handleExpandChart = () => {
    this.setState({ expandChart: !this.state.expandChart });
  };

  render(){
    const {
      expandChart,
      isLoading,
      projects,
      projectName,
      setOfProjects,
      groupOfSelectedTasks,
      users
    } = this.state;
    const { classes } = this.props;

    const projectSize = projects.length;
    const setOfProjectsSize = setOfProjects.size;
    const projectLoaded = projectSize > 0 && setOfProjectsSize > 0 && projectSize === setOfProjectsSize;

    if(!isLoading && projectLoaded){
      return (
        <div class={classes.chartMainContainer}>
          <Header showLogoutButton title="Radar Chart Showing Domain Knowledge of Team Members Based on Project"/>
          <ThemeProvider theme={theme}>
            <FormControl>
              <InputLabel htmlFor="project-selector">Select a project</InputLabel>
              <Select
                native
                value={projectName}
                onChange={this.handleProjectSelection}
                inputProps={{
                  id: 'project-selector',
                }}
              >
                <option aria-label="None" value="" />
                {new Set([...setOfProjects].map(item => (
                  <option value={item}>{item}</option>
                )))}
              </Select>
            </FormControl>
          </ThemeProvider>
          <hr />
          {projectName.length > 0 && <Button onClick={this.handleExpandChart}>{expandChart ? 'Hide Chart':'View chart'}</Button>}
          <Collapse in={expandChart} timeout="auto" unmountOnExit>
          { users ?
              <div class={classes.radarChartContainer}>
                <RadarChart
                  cx={'50%'}
                  cy={'50%'}
                  data={groupOfSelectedTasks[projectName]}
                  height={600}
                  outerRadius={'80%'}
                  width={800}
                >
                  <PolarGrid />
                  <PolarAngleAxis dataKey="task" />
                  <PolarRadiusAxis angle={90} domain={[0, 600]}/>
                  {
                    new Set([...users].map(user => {
                      const colorCode = '#'+ Math.floor(Math.random()*16777215).toString(16);
                      return <Radar name={user} dataKey={user} stroke={colorCode} fill={colorCode} fillOpacity={0.6} />
                    }))
                  }
                  <Legend />
                </RadarChart>
              </div>
              :
              <div>No information available</div>
          }
          </Collapse>
        </div>
      )
    }
    return(
      <div>Loading...</div>
    )
  }
}

export default withStyles(classComponentStyles)(ProjectDomainKnowledgePage);